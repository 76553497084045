@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
  ========================================================================== */
$border-width: 0.2rem;
$font-weight: 400;
$min-height: 12.5rem;
$min-width: 13.2rem;

/* ITEM AVAILABILITY
  ========================================================================== */
.atom__item-availability-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  padding: $s-md;

  min-height: $min-height;
  min-width: $min-width;

  border: $border-width solid $c-base-black;

  /* TEXT
  ========================================================================== */
  .item-availability-title {
    color: $c-base-black;
    font-style: normal;
    font-weight: $font-weight;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  /* ITEM UNAVAILABLE
  ========================================================================== */
  &[data-is-available='false'] {
    border-color: $c-gray-4;

    .item-availability-title {
      color: $c-gray-4;
    }
  }
}
