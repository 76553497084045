@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$info-button-min-width: 13.4rem;
$service-button-min-width: 23.3rem;
$service-title-max-width: 80px;
$max-width-notice: 31.1rem;
$width-image-apps: 13rem;
$max-height-card: 60rem;
$info-padding-appointment: 8.8rem;
$media-gap: 1.9rem;

/* INFO DEALER CONTAINER
========================================================================== */
.molecule__info-dealer-container {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: $s-lg;
  gap: $s-md;
  background: $c-base-white;

  @include gt-phone {
    max-height: $max-height-card;
    overflow-y: auto;
  }

  /* INFO DEALER
  ========================================================================== */
  .info-dealer {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 0 1rem;
    gap: $s-s;
  }

  .info-dealer-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .btn-favorite {
      margin-right: 1rem;
      border: 0;
      color: $c-primary;
    }
  }

  .blocked {
    gap: $s-md;
  }

  .info-dealer-title-blocked,
  .info-dealer-address-blocked {
    color: $c-gray-4;
  }

  .info-dealer-address,
  .info-dealer-address-blocked {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .info-dealer-notice-blocked {
    max-width: $max-width-notice;
    gap: $s-md;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    img {
      width: $width-image-apps;
    }
  }

  .info-dealer-message-error {
    color: $c-primary;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .info-dealer-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    gap: $s-md;

    @include gt-phone {
      position: relative;

      flex-direction: row;
    }
  }

  /* ADDRESS
  ========================================================================== */
  .info-dealer-container__address {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    gap: $s-s;

    font-size: $text-size-default;
    line-height: $text-line-height-small;

    @include gt-phone {
      gap: $s-zz;
    }
  }

  .email-address-container {
    display: flex;
    line-height: normal;
  }

  .info-dealer-container__address__email {
    overflow: hidden;

    text-overflow: ellipsis;
    transition: opacity ease $basic-transition;

    &:hover,
    &:focus {
      opacity: 0.6;
      transition: opacity ease $basic-transition;
    }
  }

  .info-dealer-container__address__website {
    display: flex;
    align-items: center;
    margin-bottom: $s-tiny;
    gap: $s-zs;

    text-decoration: underline;
    transition: opacity ease $basic-transition;

    svg {
      width: $s-s;
      height: $s-s;
    }

    &:hover,
    &:focus {
      opacity: 0.6;
      transition: opacity ease $basic-transition;
    }
  }

  .info-dealer-container__address__phones {
    display: grid;
    gap: $s-sm;
  }

  .info-dealer-container__address__cell-whatsap {
    display: flex;
    align-items: center;
    gap: $s-zs;

    svg {
      width: $s-md;
      height: $s-md;
      transition: opacity ease $basic-transition;

      &:hover,
      &:focus {
        opacity: 0.6;
        transition: opacity ease $basic-transition;
      }
    }
  }

  /* SOCIAL MEDIA
  ========================================================================== */
  .info-dealer-container__media {
    display: flex;
    margin-top: $s-zs;
    gap: $s-zs;

    @include gt-phone {
      margin-top: $media-gap;
    }
  }

  /* BUTTON
  ========================================================================== */
  .info-dealer-container__button {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    min-width: $info-button-min-width;

    @include gt-phone {
      position: absolute;
      right: 0;

      bottom: 0;
    }
  }

  .button {
    gap: $s-zs;
    font-weight: 500;

    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  /* INFO SERVICES
  ========================================================================== */
  .info-services {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 300px;
    gap: $s-md;
  }

  .services-slider-container {
    display: block;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow: auto;
    gap: 1rem;

    .info-services__items-services {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      min-width: 20.5%;
      padding: 2rem 0;
      text-align: center;
    }
  }

  /* LIST
  ========================================================================== */
  .info-services__container-list {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    gap: $s-s;
  }

  .info-services__container-list__title {
    color: $c-base-black;
    font-weight: 700;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  .info-services__list-services {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $s-zs;
  }
  .info-services__items-services {
    display: flex;
    align-items: center;
    gap: $s-zs;
    color: $c-gray-5;

    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }
  .info-services__items-services__icon {
    img {
      width: $s-md;
      max-width: $s-md;
      height: $s-md;
      max-height: $s-md;
    }
  }
  .info-services__items-services__content {
    max-width: $service-title-max-width;
    font-weight: 400;

    font-size: 14px;
    line-height: 16px;

    white-space: normal;
  }

  /* BUTTON
  ========================================================================== */
  .info-services__button-services {
    display: flex;
    justify-content: flex-start;

    min-width: $service-button-min-width;
    height: 100%;
    gap: $s-zs;

    @include gt-phone {
      justify-content: flex-end;
    }
  }

  /* TYPE INFO
  ========================================================================== */
  &[data-type-info='appointment'] {
    padding: $s-lg;

    .info-dealer {
      gap: $s-md;
    }

    @include gt-phone {
      padding: $s-lg $info-padding-appointment;
    }
  }
}
