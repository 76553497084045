@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$top-bullet: -0.823rem;
$right-bullet: -1rem;
$width-img-desktop: 11rem;
$height-img-mobile: 8rem;
$width-input-counter: 10rem;
$max-height-list: 48.8rem;
$width-item: 50rem;

.molecule__car-cart-summary-container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: $s-s;

  @include gt-phone {
    width: auto;
  }

  .cart-count {
    display: flex;
    position: absolute;
    top: $top-bullet;
    right: $right-bullet;
    align-items: center;
    justify-content: center;
    width: $text-size-small;
    height: $text-size-small;
    border-radius: 50%;
    background-color: $c-state-danger;
    color: $c-base-white;
    font-weight: 500;
    font-size: $text-size-micro;
    line-height: $text-size-xtiny;
  }

  .container-open {
    display: flex;
    flex-direction: column;
    padding: $s-md;
    gap: $s-s;
    border: $border-width-small solid $c-gray-3;
    background-color: $c-base-white;
    box-shadow: $s-zero $s-zs $s-md $s-zero rgba(0, 0, 0, 0.16);
  }

  .cart-header {
    display: flex;
    justify-content: space-between;
  }

  .cart-header-text {
    color: $c-gray-6;
    font-weight: 500;
    font-size: $text-size-xsmall;
    line-height: $text-line-height-xxmedium;
  }

  .icon-close {
    path {
      stroke: currentColor;
      transition: stroke 0.3s ease;
    }
    &:hover {
      path {
        stroke: $c-gray-6;
      }
    }
  }

  .cart-list {
    display: grid;
    max-height: $max-height-list;
    padding-right: $s-s;
    overflow-y: auto;
    gap: $s-s;
    scrollbar-color: $c-primary-dark transparent;
    scrollbar-width: thin;
    @include gt-phone {
      gap: $s-md;
    }

    &::-webkit-scrollbar {
      width: $s-zs;
      padding-right: $text-size-large;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: $s-zs;
      background-color: $c-primary-dark;
    }

    &::-webkit-scrollbar-track {
      margin: $s-md;
      border: $s-tiny solid $c-gray-1;
      background-color: $c-gray-1;
    }

    &::-webkit-scrollbar-thumb {
      border: $s-tiny solid $c-primary-dark;
      background-color: $c-primary-dark;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $c-primary-dark;
    }
  }

  .cart-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $s-s;
    gap: $s-md;

    @include gt-phone {
      flex-direction: row;
      align-items: center;
      width: $width-item;
    }
  }

  .cart-item-image {
    width: 100%;

    object-fit: contain;

    @include gt-phone {
      width: $width-img-desktop;
      height: auto;
    }
  }

  .cart-item-details {
    display: grid;
    width: 100%;
    gap: $s-s;
  }

  .cart-item-details-info {
    display: grid;
    gap: $s-zzz;
  }

  .details-info-model,
  .details-info-color {
    font-size: $text-size-default;
    line-height: $text-line-height-small;
    letter-spacing: 0.5%;
  }

  .details-info-color {
    color: $c-gray-5;
  }

  .details-info-price {
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  .details-input-remove {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .divider {
    border: $border-width-small solid $c-gray-3;
  }

  .total-price {
    display: flex;
    justify-content: space-between;
  }

  .cart-footer {
    display: flex;
    flex-direction: column;
    padding-right: $s-s;
    gap: $s-md;
  }
}
