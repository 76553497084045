@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$video-container-width: 67.2rem;

.organism__about-toyota-cycle-container {
  position: relative;

  .video-container {

    @include gt-tablet {
      margin: auto;

      width: $video-container-width;
    }

  }

}