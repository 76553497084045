@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__service-offers-container {
  position: relative;

  /* HEADER
========================================================================== */
  .header {
    margin-bottom: $s-lg;

    .header__select {
      display: flex;
      flex-direction: column;
      gap: $s-zs;

      @include gt-phone {
        display: flex;
        flex-direction: row;
        gap: $s-lg;
        align-items: center;
      }

      >div>select,
      label {
        flex-grow: 1;

        margin: 0;
      }

      label {
        font-size: $text-size-default;
        line-height: $text-line-height-medium;
      }

      >div>select {
        min-width: 34rem;

        background-color: $c-base-white;
      }

      >div::after {
        top: 1.8rem;
      }
    }
  }

  /* CONTENT
========================================================================== */
  .wrapper {
    display: flex;
    flex-direction: column;

    padding: $s-s;

    background-color: $c-base-white;

    @include gt-phone {
      padding: $s-xl $s-md;
    }

    .wrapper__content {
      display: flex;
      flex-direction: column;
      gap: $s-md;

      margin-bottom: $s-md;

      @include gt-phone {
        margin: 0 $s-xxl;
        margin-bottom: $s-lg;
      }

      .wrapper__image {
        display: flex;
        justify-content: center;
      }

      .wrapper__title {
        display: none;

        @include gt-phone {
          display: block;
          font-weight: 400;
        }
      }

      .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $s-s $s-xxl;

        @include gt-phone {
          row-gap: $s-lg;
        }

        @include gt-tablet {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }

  .card {
    position: relative;

    display: flex;
    flex-direction: column;

    padding-top: 1.2rem;

    .card__title {
      font-weight: 600;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;

      @include gt-phone {
        font-weight: 700;
        font-size: $text-size-small;
        line-height: $text-line-height-medium;
      }
    }

    .card__subtitle {
      color: $c-gray-6;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
    }

    .card__text {
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;

      @include gt-phone {
        font-size: $text-size-default;
        line-height: $text-line-height-medium;
      }
    }
  }

  .card::before {
    position: absolute;

    top: 0;
    left: 0;
    width: 6.8rem;
    height: .4rem;

    background-color: $c-primary;
    content: '';
  }

  /* FOOTER
========================================================================== */
  .footer {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    @include gt-phone {
      gap: $s-lg;
    }

    .footer__highlight {
      display: grid;
      place-items: center;

      padding: $s-s;

      height: auto;

      background-color: $c-gray-6;
      border-color: $c-gray-6;
      color: $c-base-white;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;

      @include gt-phone {
        font-size: $text-size-small;
        line-height: $text-line-height-medium;
      }
    }

    .footer__info {
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;

      @include gt-phone {
        color: $c-gray-6;
      }
    }
  }
}
