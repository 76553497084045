@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/*VARIABLES
========================================================================== */
$warning-container-min-width: 12rem;
$document-icon-file-width: 3.3rem;
$document-icon-file-height: 4.4rem;

.service-recall-container {
  display: flex;
  flex-direction: column;

  padding: $s-s;
  gap: $s-md;

  border: 1px solid $c-gray-3;

  @include gt-phone {
    flex-direction: row;
    justify-content: space-between;
  }

  /*CONTENT LIST
  ========================================================================== */
  .content-list,
  .content-list_ar {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-phone {
      display: grid;
      width: 100%;

      gap: $s-xl;

      &[data-is-card-variation='true'] {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .content-list__item,
    .content-list__item__recall {
      display: flex;
      flex-direction: column;
      gap: $s-zs;

      .content-list__label {
        height: 28px;
        color: $c-gray-6;
        font-size: $text-size-tiny;
        line-height: $text-line-height-default;
      }

      .content-list__value {
        color: $c-base;
        font-weight: 400;
        font-size: $text-size-default;
        line-height: $text-line-height-medium;

        &[data-status='PENDING'] {
          color: $c-primary;
        }

        &[data-status='DONE'] {
          color: $c-state-success;
        }

        @include gt-phone {
          font-weight: 400;
        }
      }
    }
  }

  .content-list {
    @include gt-phone {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .content-list_ar {
    @include gt-phone {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
    }
  }

  .content-list__item__button {
    border: 1px solid $c-secondary;
    background-color: $c-base-lightest;
    color: $c-secondary;
  }

  /*LINKS CONTAINER
  ========================================================================== */
  .links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: $s-zs;

    /*WARNING CONTAINER
    ========================================================================== */
    .warning-container {
      display: flex;
      align-items: center;

      gap: $s-s;

      border: none;
      text-align: left;

      text-decoration: none;

      cursor: pointer;

      transition: opacity ease $basic-transition;

      @include gt-phone {
        min-width: $warning-container-min-width;
      }

      &:hover,
      &:focus {
        opacity: 0.6;
        transition: opacity ease $basic-transition;
      }

      /*WARNING CONTAINER TEXT
      ========================================================================== */
      .warning-container__text {
        color: $c-primary;
        font-weight: 500;
        font-size: $text-size-tiny;
        line-height: $text-line-height-default;
      }

      /*WARNING CONTAINER ICON
      ========================================================================== */
      .warning-container_icon {
        width: $document-icon-file-width;
        height: $document-icon-file-height;
      }
    }
  }
}
