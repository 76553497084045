@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__viwer-360-container {
  position: relative;

  .spinner {
    display: block;
    scale: 0.97;
    cursor: grab;
    cursor: -webkit-grab;
    transition: all 0.2s;

    &[screen='mobile'] {
      width: 100%;
    }

    &[screen='desk'] {
      width: 586px;
      height: 316px;
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $s-s;
    padding: $s-zs;
    gap: $s-lg;

    @include gt-phone {
      padding: 0;
    }
  }
  .info_content {
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }
}

.dragging {
  cursor: grabbing !important;
  cursor: -webkit-grabbing !important;

  .spinner {
    scale: 1;
    cursor: grabbing !important;
    cursor: -webkit-grabbing !important;
  }
}
