@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__service-single-grid-container {
  .section-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
    }

    @include tablet {
      flex-direction: column;
    }
  }

  /* HEADER
  ========================================================================== */
  .services-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: initial;
    gap: $s-lg;

    @include gt-tablet {
      max-width: fit-content;
    }

    @include gt-phone {
      max-width: fit-content;
    }

    @include tablet {
      max-width: initial;
    }
  }

  .dropdown-card {
    display: none;

    @include gt-phone {
      display: flex;
    }

    @include tablet {
      max-width: initial;
    }
  }

  /* CONTENT
  ========================================================================== */
  .services-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include gt-tablet {
      gap: $s-md;
    }
  }
}
