@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

$border-car-details-action-card-container: 0.1rem;
$width-car-image-mobile: 13rem;
$width-car-image-desktop: 19.2rem;

.molecule__car-details-action-card-container {
  display: flex;
  flex-direction: column;
  padding: $s-s;
  gap: $s-md;
  border: $border-car-details-action-card-container $c-gray-3 solid;

  @include gt-phone {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $s-s;
    padding: $s-md;
  }

  .car-image-information {
    width: $width-car-image-mobile;
    margin: 0 auto;

    @include gt-phone {
      width: $width-car-image-desktop;
    }
  }

  .image-info-container {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    @include gt-phone {
      flex-direction: row;
      align-items: center;
    }
  }

  .info-container {
    display: flex;
    flex-direction: column;
    gap: $s-zzz;

    dt,
    dd {
      font-weight: 400;
      line-height: $text-line-height-small;
    }

    dt {
      color: $c-gray-5;
      font-size: $text-size-tiny;
    }

    dd {
      color: $c-base-black;
      font-size: $text-size-default;
    }
  }

  .price {
    display: none;
    font-weight: 400;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  .disclaimer-buttons-price {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include gt-phone {
      gap: $s-md;
    }

    .disclaimer {
      display: none;
      color: $c-gray-5;
      font-weight: 500;
      font-size: $text-size-default;
      line-height: $text-size-medium;

      @include gt-phone {
        display: block;
      }
    }

    .button-delete {
      border: none;
    }
  }

  &[show-price='true'] {
    .price {
      display: block;
    }
    .disclaimer-buttons-price {
      display: none;
    }
  }
}
