@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/*VARIABLES
========================================================================== */
$border-width: 1px;
$category-container-width: 10rem;
$category-container-height: 4.8rem;
$thumb-hover-container-height: 24.2rem;
$gap-seal: 0.7rem;
$gap-seal-desk: 0.4rem;
$size-img-seal: 4.6rem;
$size-img-seal-desk: 5rem;

/*CAR DETAILS
========================================================================== */
.offer-details-container {
  display: flex;
  position: relative;
  flex-direction: column;

  background-color: $c-base-white;

  @include phone {
    &:before,
    &:after {
      position: absolute;
      left: -$grid-padding;

      width: 100vw;
      height: $border-width;

      background-color: $c-gray-3;
      content: '';
    }

    &:after {
      bottom: 0;
    }
  }

  @include gt-tablet {
    border: $border-width solid $c-gray-3;
  }

  &[background-color='transparent'] {
    background-color: transparent;
  }

  /*THUMB CONTAINER
  ========================================================================== */
  .thumb-hover-container {
    position: relative;

    height: $thumb-hover-container-height;

    border-bottom: $border-width solid $c-gray-3;
  }

  .category-container {
    display: flex;
    position: absolute;
    top: $s-s;
    left: 0;
    align-items: center;
    justify-content: center;

    width: $category-container-width;
    min-height: $category-container-height;

    padding: $s-zs;
    color: $c-base-white;
    font-weight: 500;

    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
    text-align: center;
    text-transform: uppercase;

    @include gt-tablet {
      top: 0;
    }
  }

  .thumb-hover {
    max-width: 90%;
  }

  .seal-container {
    display: flex;
    position: absolute;
    top: $s-s;
    right: $s-s;
    flex-direction: column;
    gap: $gap-seal;

    @include gt-tablet {
      gap: $gap-seal-desk;
    }
  }

  .seal-list-img {
    position: relative;
    left: 2.2rem;
    width: $size-img-seal;
    height: $size-img-seal;

    @include gt-tablet {
      left: 0;
      width: $size-img-seal-desk;
      height: $size-img-seal-desk;
    }
  }

  /*CONTENT CONTAINER
  ========================================================================== */
  .offer-details-content {
    display: flex;
    position: relative;
    flex: 1 0 auto;
    flex-direction: column;

    padding-block: $s-md;
    gap: $s-md;

    @include gt-tablet {
      padding: $s-md;
    }
  }

  .offer-details-content__footer {
    margin-top: auto;
  }

  /*HEADER LIST
  ========================================================================== */
  .header-list {
    display: flex;
    flex-direction: column;

    list-style: disc;
  }

  .header-list__item {
    margin-left: $s-md;
    color: $c-secondary;

    font-size: $text-size-default;
    line-height: $text-line-height-medium;

    &::marker {
      color: $c-primary;
      font-size: $text-size-xtiny;
    }
  }

  /*INFO LIST
  ========================================================================== */
  .info-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // row-gap: $s-s;
    gap: $s-lg;

    // min-height: 140px;

    /*CARD TYPES
    ========================================================================== */
    &[data-card-type='2'] {
      .info-list__item {
        position: relative;

        &:first-child::after {
          position: absolute;
          top: 0;
          left: calc(100% - 24px);
          content: '+';

          color: $c-primary;
          font-size: $text-size-large;
        }

        &:nth-child(3) {
          grid-column: 2;

          padding-inline: $s-zs;
        }
      }
    }
  }

  .organism_offer-year {
    color: $c-gray-6;
    font-weight: 400;
  }

  .info-list__item {
    display: flex;
    flex-direction: column;

    min-width: 0;

    // &:nth-child(even) {
    //   padding-inline: $s-zs;
    // }
  }

  .info-list__label {
    color: $c-base-black;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .info-list__value {
    color: $c-secondary;

    font-weight: 400;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
    overflow-wrap: break-word;
  }

  .info-list__footnote {
    color: $c-gray-6;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  .info-list__value-installment {
    color: $c-secondary;
    font-weight: 600;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  .info-list__no-installments {
    color: $c-gray-6;
  }

  .info-list__extra {
    color: $c-gray-6;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  .info-list-residual {
    display: flex;
    flex-direction: column;
  }

  /*EXTRA INFO
  ========================================================================== */
  .extra-info-list {
    display: flex;
    flex-direction: column;

    padding-top: $s-md;

    border-top: $border-width solid $c-gray-3;
  }

  .extra-info-list__item {
    display: flex;
    flex-direction: column;
  }

  .extra-info-list__label {
    color: $c-gray-6;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .extra-info-list__value {
    color: $c-secondary;
    font-weight: 400;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  .extra-info-list__extra {
    color: $c-gray-6;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  /*EXTRA INFO
  ========================================================================== */
  .footer-extra-info {
    margin-bottom: $s-s;
    color: $c-secondary;

    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  /*BUTTONS CONTAINER
  ========================================================================== */
  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  .buttons-container__item {
    display: flex;
    flex-direction: column;
  }

  /*LINKS LIST CONTAINER
  ========================================================================== */
  .links-list-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    margin-top: $s-md;
    padding-top: $s-s;
    gap: $s-s;

    border-top: $border-width solid $c-gray-3;
  }

  .links-list-container__item {
    &:nth-child(even) {
      justify-self: flex-end;
    }
  }

  /*CONDITIONS MODAL
  ========================================================================== */

  .atom__anchor {
    cursor: pointer;
  }
}

.conditions-modal {
  @include gt-tablet {
    max-width: 65vw;
  }
}
