@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$input-width: 37rem;
$position-span: -2rem;
$agreement-margin-top: 3.2rem;
$agreement-margin-bottom: 3.2rem;

/* INTEREST-FORM-MANAGER
========================================================================== */
.organism__sale-used-form-container {
  position: relative;

  .container_sucess_submit {
    width: 100%;
    grid-column: 1/-1;
  }
  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__section {
    display: grid;
    padding: 0;
    gap: $s-lg;

    margin-bottom: $s-xl;

    @include gt-phone {
      margin-bottom: $s-xxl;
    }
  }

  /* TITLE
  ========================================================================== */
  .form-container__title_first {
    font-size: $text-size-default;
    font-weight: 600;
    margin-bottom: $s-lg;

    @include gt-phone {
      grid-column: 1/-1;
      font-size: $text-size-small;
      font-weight: 400;
    }
  }

  .form-container__title {
    font-size: $text-size-default;
    font-weight: 600;

    @include gt-phone {
      grid-column: 1/-1;
      font-size: $text-size-small;
      font-weight: 400;
    }
  }

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__list-input,
  .form-container__list-input_more {
    display: flex;
    flex-direction: column;

    @include gt-phone {
      flex-direction: row;
    }
    @include tablet {
      flex-direction: column;
    }
  }

  .form-container__list-input_more {
    flex-wrap: wrap;
  }

  /* INPUT
  ========================================================================== */
  .form-container__input {
    select {
      margin-bottom: 0;
    }
    input {
      margin-bottom: 0;
    }

    @include gt-phone {
      width: $input-width;

      select {
        width: $input-width;
      }
    }
  }

  .form-container__input_address {
    display: flex;
    flex-direction: column;
    gap: $s-zs;
    width: $input-width;

    .form-container__input {
      select:disabled {
        border: 1px solid $c-gray-3;
      }
    }
  }

  .form-container__adress {
    display: grid;
    gap: $s-zs;
  }

  .form-container__label_address {
    display: flex;
    justify-content: flex-end;
    text-transform: capitalize;
    line-height: $s-s;
    font-size: $s-sm;

    @media (max-width: 1250px) {
      justify-content: flex-start;
    }
  }

  .form-container__checkbox-agreement {
    @include gt-phone {
      margin-top: $agreement-margin-top;
      margin-bottom: $agreement-margin-bottom;
    }

    > label {
      color: #A5A5A5;
      margin-bottom: 0;
    }        
    
    > span {
        bottom: -1.8rem;
      }
  }

  .section-header {
   > div {
    > div {
      margin-bottom: 0;
    }
      margin-bottom: 0;
    }
  }
}
