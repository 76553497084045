@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$width-input: 37rem;
$max-width: 30rem;

/* KINTO-INTEREST-FORM
========================================================================== */
.molecule__kinto-interest-form-container {
  position: relative;

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__section {
    display: grid;
    padding: 0;
    gap: $s-lg;

    margin-bottom: $s-xl;

    @include gt-phone {
      margin-bottom: $s-xxl;
    }
  }

  /* TITLE
  ========================================================================== */
  .form-container__title {
    font-size: $text-size-default;
    font-weight: 600;

    @include gt-phone {
      grid-column: 1/-1;
      font-size: $text-size-small;
      font-weight: 400;
    }
  }

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__section__button {
    display: grid;
    gap: $s-lg;
  }

  .form-container__list-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $s-lg;
    grid-column: 1/-1;
    width: 100%;

    @include gt-phone {
      flex-direction: row;
    }
  }

  /* INPUT
  ========================================================================== */
  .form-container__input {
    select {
      margin-bottom: 0;
    }
    input {
      margin-bottom: 0;
    }
    span {
      bottom: -2rem;
    }

    @include gt-phone {
      width: $width-input;
    }
  }

  .form-container__input_commentary {
    select {
      margin-bottom: 0;
    }
    input {
      margin-bottom: 0;
    }

    @include gt-phone {
      width: $width-input;
    }
  }

  /* DISCLAIMER
  ========================================================================== */
  .disclaimer {
    margin-bottom: $s-lg;

    &[disabled] {
      color: $c-gray-4;
      pointer-events: none;

      .link {
        pointer-events: visible;
      }
    }
  }
}
