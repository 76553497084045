@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';


/* MODAL
========================================================================== */
@include gt-tablet {

  .modal {
    margin: auto;

    width: 65vw;
    height: 60vh;
  }

}