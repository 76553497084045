@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
==========================================================================*/
$list-item-min-width: 18.4rem;
$list-item-max-width: 30rem;
$icon-size: 1.6rem;
$sticky-top: 7.5rem;
$container-large: 1100px;

.molecule__secondary-navigation-container {
  z-index: 4;
  position: sticky;
  top: $sticky-top;
  border-bottom: solid 1px $c-gray-3;
  background-color: $c-gray-1;
  opacity: 1;
  transition: all 0.3s ease, height 0.3s linear 0.4s;

  .container-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: $s-md;
    padding-bottom: $s-tiny;

    @include gt-phone {
      padding-top: $s-lg;
      padding-bottom: $s-lg;
      text-align: initial;
    }
  }

  .container-nav-title {
    display: block;
    width: inherit;
    text-align: center;
  }

  .title {
    font-weight: 500;
    font-size: $text-size-default;
    line-height: $s-md;
  }

  .container-nav-list {
    display: flex;
    width: 100%;
    padding-top: $s-md;
    padding-bottom: $s-tiny;
    overflow: auto hidden;
    gap: $s-tiny;

    @include gt-phone {
      justify-content: center;
    }
  }

  .large {
    max-width: $container-large;
  }

  .list-item {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: $s-tiny;
    gap: $s-zs;
    background: $c-gray-3;
    opacity: 1;
    pointer-events: auto;
    transition: opacity ease 0.3s;

    &:hover,
    &:focus {
      -webkit-tap-highlight-color: transparent;
      outline: none !important;
      text-decoration: none;
      opacity: 0.6;
      transition: opacity ease 0.3s;
    }

    @include gt-phone {
      align-items: center;
      min-width: $list-item-min-width;
      max-width: $list-item-max-width;
      height: $s-xl;
      margin: auto 0;
      padding: $s-s $s-md;
    }
  }

  .link-label-active,
  .link-label {
    text-wrap: nowrap;
    position: relative;
    overflow: hidden;
    font-size: $text-size-tiny;
    line-height: $text-line-height-medium;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .link-label {
    display: block;
  }

  .link-label-active {
    @include gt-phone {
      display: block;
    }
  }

  .list-item-image {
    width: auto;
    max-height: $icon-size;
    object-fit: cover;
  }
}
