@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$map-mobile-height: 36rem;
$map-desktop-height: 68.4rem;
$carousel-mobile-height: 30rem;
$info-max-width: 56rem;
$info-position-left: 2rem;
$info-position-top: 6rem;
$info-margin-left: 1rem;
$info-width-appointment: 48.7rem;

/* MAP INFORMATION CONTAINER
========================================================================== */
.molecule__map-information-container {
  position: relative;
  overflow: hidden;

  /* CONTAINER GOOGLE MAP
  ========================================================================== */
  .container-google {
    display: flex;
    position: relative;

    width: 100%;
    height: $map-mobile-height;

    @include gt-phone {
      height: $map-desktop-height;
    }
  }

  /* CONTAINER INFO
  ========================================================================== */
  .carousel-info {
    position: relative;
    width: 100%;

    border: 1px solid $c-gray-1;

    @include gt-phone {
      position: absolute;
      border: none;
      top: $info-position-top;
      left: $info-position-left;
      max-width: $info-max-width;

      &[data-type-info='appointment'] {
        max-width: $info-width-appointment;
      }
    }
  }

  /* ARROWS
========================================================================== */

  [class^='slick-arrow'] {
    top: 50%;
    z-index: $z-index-jump;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(45deg);
    border: none;
    color: transparent;
  }

  [class*='slick-prev'] {
    left: $s-s;
    border-bottom: solid 0.15rem $c-base-black;
    border-left: solid 0.15rem $c-base-black;

    transition: border-left ease $basic-transition;
    transition: border-bottom ease $basic-transition;

    &:hover,
    &:focus {
      border-bottom: solid 0.15rem $c-gray-5;
      border-left: solid 0.15rem $c-gray-5;
      transition: border-left ease $basic-transition;
      transition: border-bottom ease $basic-transition;
    }
  }

  [class*='slick-next'] {
    right: $s-s;
    border-top: solid 0.15rem $c-base-black;
    border-right: solid 0.15rem $c-base-black;

    transition: border-right ease $basic-transition;
    transition: border-top ease $basic-transition;

    &:hover,
    &:focus {
      border-top: solid 0.15rem $c-gray-5;
      border-right: solid 0.15rem $c-gray-5;
      transition: border-right ease $basic-transition;
      transition: border-top ease $basic-transition;
    }
  }

  [class*='slick-disabled'] {
    cursor: default;
    opacity: 0.2;
  }
}
