@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$button-min-width: 7.8rem;
$button-max-width: 11.5rem;
$button-height-desktop: 9.2rem;
$button-height-mobile: 8rem;

.molecule__card-my-vehicle-details-container {
  display: grid;
  position: relative;

  padding: $s-s;
  gap: $s-zs;
  outline: 2px solid $c-gray-1;

  @include gt-phone {
    grid-template-columns: repeat(2, 1fr);
    gap: $s-lg;
  }

  @include tablet {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }

  .container-vehicle {
    display: grid;
    gap: $s-s;
  }

  .container-vehicle-title {
    margin-right: $s-md;
  }

  .container-vehicle-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .container-info {
    display: grid;
    gap: $s-zs;

    @include gt-phone {
      margin-block: $s-zs;
    }
  }

  .container-info-list {
    display: grid;
    padding: $s-zs;
    gap: $s-zs;
  }

  .container-info-list-item-label {
    font-size: $text-size-tiny;
    line-height: $text-line-height-medium;
  }

  .container-info-list-item-value {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .container-info-services {
    display: grid;
    padding: $s-zs;
    gap: $s-s;
  }

  .container-info-services-title {
    font-weight: 400;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  .container-info-services-list {
    display: flex;
    flex-direction: row;
    gap: $s-s;
  }

  .container-info-services-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: $button-height-mobile;
    min-width: $button-min-width;
    max-width: $button-max-width;
    padding: $s-zs;
    padding-bottom: $s-s;
    gap: $s-zs;
    font-size: $text-size-micro;
    line-height: $text-line-height-default;
    font-weight: 500;
    border: 1px solid $c-base-black;
    transition: background-color ease $basic-transition, color ease $basic-transition;

    @include gt-phone {
      height: $button-height-desktop;
      padding: $s-tiny $s-md;
      gap: $s-tiny;
    }

    &:hover,
    &:focus {
      background-color: $c-base-black;
      color: $c-base-white;
      transition: background-color ease $basic-transition, color ease $basic-transition;
    }
  }

  .container-info-services-button-name {
    height: $s-md;

    @include gt-phone {
      display: flex;
      height: $s-lg;
      align-items: center;
    }

  }

  .container-info-services-button {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .button-delete {
    position: absolute;
    top: $s-s;
    right: $s-s;

    border: none;
    background: none;
    transition: opacity ease $basic-transition;

    &:hover,
    &:focus {
      opacity: 0.8;
      border: none;
      background: none;
      transition: opacity ease $basic-transition;
    }
  }
}
