@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

$background-dark: rgba(0, 0, 0, 0.9);
$background-light: rgba(0, 0, 0, 0.7);
$drop-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
$arrow-bg: rgba(0, 0, 0, 0.2);
$arrow-bg-hover: rgba(0, 0, 0, 0.5);
$font-size-number-image: 2rem;
$width-image-modal-desktop: 96.6rem;
$height-image-modal-desktop: 54.1rem;
$width-image-modal-tablet: 55rem;
$height-image-modal-tablet: 45rem;

.molecule__gallery-modal-container {
  display: flex;
  z-index: $z-index-sky;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: $s-md;

  &[background-modal='light'] {
    background-color: $background-light;
  }

  &[background-modal='dark'] {
    background-color: $background-dark;
  }

  /* WRAPPER
========================================================================== */
  .gallery-wrapper {
    display: grid;
    position: relative;
    place-items: center;

    width: 100%;
    height: 100%;
  }

  /* MODAL IMAGE CONTAINER
========================================================================== */
  .modal-image-container {
    display: inline-block;
  }

  /* NUMBER IMAGE
========================================================================== */
  .number-image {
    display: flex;
    justify-content: flex-end;
    padding: $s-zs $s-md;
    gap: $s-s;
    background-color: rgba($color: #000000, $alpha: 0.7);
    color: $c-base-white;
    line-height: $s-md;

    @include gt-tablet {
      width: 100%;
      margin: 0 auto;
    }

    @include tablet {
      width: $width-image-modal-tablet;
      margin: 0 auto;
    }
  }

  /* IMAGE
========================================================================== */
  .image-over {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: cover;
    box-shadow: $drop-shadow;

    @include gt-tablet {
      width: $width-image-modal-desktop;
      height: $height-image-modal-desktop;
    }

    @include tablet {
      width: $width-image-modal-tablet;
      height: $height-image-modal-tablet;
    }
  }

  /* Download links
========================================================================== */
  .download-links {
    display: flex;
    flex-direction: column;
    padding: $s-s $s-md;
    gap: $s-md;
    background-color: rgba($color: #000000, $alpha: 0.7);
    color: $c-base-white;
    font-size: $s-tiny;
    line-height: $s-s;
    text-align: center;

    a {
      color: $c-base-white;
      text-decoration: underline;
    }
    .spacer {
      margin: 0 $s-s;

      @include tablet {
        display: none;
      }
    }
    @include gt-tablet {
      flex-direction: row;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      gap: $s-lg;
    }

    @include gt-phone {
      flex-direction: row;
      justify-content: center;
      margin: 0 auto;
    }

    @include tablet {
      width: $width-image-modal-tablet;
      margin: 0 auto;
    }
  }

  .download-current-image-at {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-phone {
      flex-direction: row;
    }
  }

  .download-all-images-at {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-phone {
      flex-direction: row;
    }
  }

  /* ARROWS
========================================================================== */
  .arrowButtonPrev {
    left: 0;
  }

  .arrowButtonNext {
    right: 0;
  }

  .arrowButtonPrev,
  .arrowButtonNext {
    display: grid;
    position: absolute;
    top: 0;
    bottom: 0;
    place-items: center;
    justify-content: space-between;

    width: 2.8rem;
    height: 7rem;

    margin: auto 0;

    border: none;
    background: $arrow-bg;
    color: transparent;

    &:hover {
      background: $arrow-bg-hover;
    }

    @include gt-tablet {
      width: 5rem;
      height: 5rem;
    }
  }
  .arrow-left,
  .arrow-right {
    position: absolute;
    width: 1.5rem;

    height: 1.5rem;

    border: none;
    border-bottom: solid 0.15rem $c-base-white;
    border-left: solid 0.15rem $c-base-white;
    color: transparent;
  }

  .arrow-left {
    right: 0.3rem;

    transform: rotate(45deg);

    @include gt-tablet {
      right: 1.3rem;
    }
  }

  .arrow-right {
    left: 0.3rem;

    transform: rotate(225deg);

    @include gt-tablet {
      left: 1.3rem;
    }
  }

  /* CLOSE
========================================================================== */
  .close-button {
    z-index: 999;
    position: absolute;
    top: 0;
    right: 0;

    > svg {
      pointer-events: none;
    }
  }
}
