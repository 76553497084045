@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$extra-info-max-width: 40.4rem;
$color-withoud: #090a09;
$gap-payment-container: 2.8rem;

/* MAINTENANCE PLAN CONTAINER
========================================================================== */
.maintenance-plan-container {
  display: flex;
  position: relative;
  flex-direction: column;

  padding: $s-md;
  gap: $s-lg;

  background-color: $c-base-white;

  .module-title {
    font-weight: 400;
  }

  /* SELECTS IMAGE CONTAINER
  ========================================================================== */
  .select-image-container {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .select-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: $s-lg;

      @include gt-phone {
        max-width: 37rem;
      }

      .selects-with-image-container {
        display: flex;
        flex-direction: column;
        width: 1000px;
      }

      .model-image-container {
        width: 100%;

        .model-image-container__image {
          width: 100%;
        }
      }
    }
    .model-image-container {
      width: 100%;
      overflow: auto;

      .model-image-container__image {
        width: 100%;
      }
    }
  }

  /* SELECT CONTAINER
  ========================================================================== */
  .select-container {
    padding-top: $s-lg;

    border-top: 1px solid $c-gray-1;
  }

  /* PAYMET INFO LIST CONTAINER
  ========================================================================== */
  .payment-info-list-container {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    .payment-info-list-container__item,
    .payment-parcel-info-list-container__item {
      display: flex;
      flex-direction: column;
      gap: $s-zs;

      .payment-info-container {
        display: flex;
        flex-direction: column;
        gap: $s-zs;
      }

      .item__label {
        font-size: $text-size-small;
        line-height: $text-line-height-medium;
      }

      .item__value {
        font-weight: 500;
        font-size: $text-size-large;
        line-height: $text-line-height-large;

        .payment-completion {
          font-weight: 400;
          font-size: $text-size-medium;
          line-height: $text-line-height-medium;
        }
      }

      .without-tax-container {
        display: flex;
        flex-direction: column;

        .without-tax__label {
          color: $color-withoud;
          font-weight: 400;
          font-size: $text-size-tiny;
          line-height: $text-line-height-medium;
        }

        .without-price {
          font-weight: 500;
          font-size: $text-size-default;
          line-height: $text-line-height-small;
        }
      }

      .item__extra-info {
        max-width: $extra-info-max-width;

        font-size: $text-size-default;
        line-height: $text-line-height-medium;
      }
    }

    .payment-info-list-container__item {
      @include gt-phone {
        gap: $gap-payment-container;
      }
    }

    .payment-parcel-info-list-container__item {
      gap: $s-zs;

      @include gt-phone {
        gap: $s-s;
      }
    }
  }

  /* PARAGRAPH CONTAINER
  ========================================================================== */
  .paragraph-container {
    display: flex;
    flex-direction: column;

    padding-top: $s-lg;
    gap: $s-md;

    border-top: 1px solid $c-gray-1;

    .paragraph-container__text {
      font-weight: 400;
    }

    .paragraph-container__text {
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }
  }

  &[data-text-transform='default'] {
    // defines the height for the small banner on desktop resolutions
    .paragraph-container__text {
      text-transform: none;
    }
  }
  &[data-text-transform='capitalize'] {
    // defines the height for the small banner on desktop resolutions
    .paragraph-container__text {
      text-transform: capitalize;
    }
  }
  &[data-text-transform='uppercase'] {
    // defines the height for the small banner on desktop resolutions
    .paragraph-container__text {
      text-transform: uppercase;
    }
  }
}
