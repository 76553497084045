@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$margin-list-marker: 1rem;

.organism__search-result-list-container {
  display: flex;
  position: relative;
  flex-direction: column;

  .error-list-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: $s-md;
    margin-top: $s-lg;
  }

  .error-list {
    margin-left: $margin-list-marker;
    p {
      margin-bottom: 0;
    }
    section {
      padding: 0;
    }
  }

  .error-list-item {
    display: flex;
    align-content: center;
  }

  .error-list-item-span,
  .error-list-item-marker {
    display: inline-block;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .error-list-item-marker {
    margin-right: $margin-list-marker;
    content: '•';
    color: $c-base-black;
  }

  .intro-content {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    margin-top: $s-lg;

    .intro-content__highlight {
      color: $c-primary;
    }
  }

  .divider,
  .divider-list {
    margin-top: $s-md;
    margin-bottom: $s-xl;
    border-top-width: 2px;

    @include gt-phone {
      margin-top: $s-lg;
      margin-bottom: $s-lg;
    }
  }

  .data-list-container {
    @include gt-phone {
      display: grid;
    }
  }

  .data-list {
    display: flex;
    row-gap: $s-xl;
    flex-direction: column;

    margin-bottom: $s-xxl;

    @include gt-phone {
      row-gap: $s-xxl;

      margin-bottom: $s-xxxl;
    }
  }

  .data-list-card:last-child {
    a {
      text-decoration: none;
      transition: color ease $basic-transition;
      &:hover,
      &:focus {
        transition: color ease $basic-transition;
        h3 {
          color: $c-state-danger-dark;
          text-decoration: none;
        }
      }
    }
  }
}
