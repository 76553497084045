@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__progress-stepper-container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: $s-s;

  /* DETAILS
  ======================================================================== */
  .details-container {
    display: flex;
    flex-direction: column;
    gap: $s-zs;
  }

  .details-container__title {
    font-weight: 500;
    font-size: $text-size-default;
    line-height: $text-line-height-small;
  }

  .details-info {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  .info-content {
    display: flex;
    flex-direction: column;
    gap: $s-s;
    line-height: $text-line-height-default;

    @include gt-phone {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .info-content__description {
    font-size: $text-size-tiny;
  }

  .info-content__description-next-step {
    color: $c-gray-4;
    font-size: $text-size-xtiny;
  }

  .progress-wrapper {
    height: $s-zzz;
    overflow: hidden;
    background-color: $c-gray-3;
  }

  .progress-bar {
    height: $s-zzz;
    background-color: $c-primary;
  }

  /* BUTTONS
  ========================================================================== */
  .buttons-container {
    display: flex;
    justify-content: space-between;
  }
}
