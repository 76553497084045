@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.offer-conditions-card-container {
  position: relative;

  @include gt-tablet {
    padding-right: $s-s;
  }

  .offer-conditions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: $s-lg;
    padding-top: $s-xxl;
    padding-bottom: $s-zs;

    @include gt-tablet {
      // grid-column: 2/12;
    }

  }

  .offer-conditions-card-content {
    margin-bottom: $s-md;

    font-size: $text-size-default;
    line-height: $text-line-height-medium;

    p+p {
      margin-top: $s-md;
    }

  }

  .offer-conditions-logos {
    display: flex;
    flex-direction: row;
    column-gap: $s-md;
  }

  .offer-conditions-logos__logo {
    width: auto;
  }

  .offer-conditions-card-footer {
    margin-block: $s-lg;
  }

}

/* PRINT STYLES
========================================================================== */
@media print {

  .offer-conditions-card-footer {
    display: none;
  }

}

@page {
  size: auto;
  margin: 15mm;
}