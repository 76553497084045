@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$car-color-button-size: 3.6rem;
$max-width-modal: 84.2rem;
$width-first-button: 19.6rem;

.molecule-car-detail-container {
  position: relative;

  row-gap: $s-md;

  [data-variant='vertical'] & {
    display: flex;
    row-gap: $s-lg;
    flex-direction: column;
    flex-wrap: wrap;
  }

  @include gt-phone {
    row-gap: $s-md;
    grid-column: 1/12;
  }

  /*TITLE
  ========================================================================== */
  .section-title-container {
    @include gt-phone {
      grid-column: 1/8;
    }

    .bold {
      font-weight: 500;
    }
  }

  /*View 360 CONTAINER
  ========================================================================== */
  .image-container {
    display: flex;
    flex-direction: column;
    gap: $s-xl;

    @include gt-phone {
      grid-row: 1/7;
      // grid-column: 1/9;
      grid-column: 1/8;
      align-items: center;
      justify-content: center;
      gap: $s-lg;
    }
  }

  /* COLOR BUTTONS
  ========================================================================== */
  .container-color-list {
    display: flex;
    flex-direction: column;
    place-items: center;
    gap: $s-s;

    [data-variant='vertical'] & {
      gap: $s-md;
    }

    .select-color {
      margin-right: $s-zs;
      gap: $s-zs;

      color: $c-base-black;
      white-space: nowrap;

      [data-variant='vertical'] & {
        font-size: $text-size-tiny;
      }
    }
  }

  .container-color-list_select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    gap: $s-s;

    @include gt-phone {
      flex-direction: row;
      gap: $s-zs;

      [data-variant='vertical'] & {
        flex-direction: column;
        gap: $s-lg;
      }
    }
  }

  .color-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    gap: $s-md;

    [data-variant='vertical'] & {
      flex-wrap: wrap;
    }
  }

  .color-listcolor {
    width: $s-md;
    height: $s-md;

    border: none;
    border-radius: 50%;

    transition: box-shadow ease $basic-transition;

    [data-variant='vertical'] & {
      width: $car-color-button-size;
      height: $car-color-button-size;
    }

    &[data-is-selected='true'] {
      border: 2px solid $c-base-white;
      box-shadow: 0 0 0 2px $c-base-black;

      transition: box-shadow ease $basic-transition;
    }
  }

  .color-listitem {
    [data-variant='vertical'] & {
      margin-inline: unset !important;
    }
  }

  .container-color-active {
    display: flex;

    @include gt-phone {
      justify-content: flex-end;
    }
  }

  .name-color-active {
    position: relative;
    align-items: center;
    color: $c-gray-5;

    font-size: $text-size-xtiny;
    text-align: center;

    [data-variant='vertical'] & {
      color: $c-base-black;
      font-size: $text-size-small;
      line-height: $text-line-height-default;
    }
  }

  /*TITLE
  ========================================================================== */
  .title-container {
    @include gt-phone {
      grid-row: 3;
      grid-column: 8/13;
    }
    /*EMPHASIS
    ========================================================================== */
    .emphasis {
      color: $c-primary;
      font-weight: 500;
    }
  }

  /*DETAILS LIST CONTAINER
  ========================================================================== */
  .datails-list-container {
    display: flex;
    flex-direction: column;

    @include gt-phone {
      grid-row: 4;
      grid-column: 8/13;
    }

    /*DETAILS LIST CONTAINER ITEM
    ========================================================================== */
    .datails-list-container__item {
      display: flex;
      position: relative;
      flex-direction: column;

      padding-left: $s-lg;

      @include gt-phone {
        grid-row: 5;
        grid-column: 8/13;
      }

      &:nth-child(n + 2) {
        padding-top: $s-s;
      }

      &::before {
        position: absolute;
        left: 0;
        content: '+';

        color: $c-primary;
        font-weight: 600;
        font-size: $text-size-small;
      }

      .datails-list-container__label {
        color: $c-gray-6;
        font-size: $text-size-default;
        line-height: $text-line-height-medium;
      }

      .datails-list-container__value {
        color: $c-secondary;
        font-size: $text-size-small;
        line-height: $text-line-height-medium;
      }

      .datails-list-container__extra {
        color: $c-gray-6;
        font-size: $text-size-default;
        line-height: $text-line-height-medium;
      }
    }
  }

  /*LINKS LIST CONTAINER
  ========================================================================== */
  .links-list-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    .links-list-container__item {
      &:nth-child(even) {
        justify-self: end;
      }
    }

    @include gt-phone {
      display: flex;
      grid-row: 7;
      grid-column: 11/13;
      flex-direction: column;
    }
  }

  /*EXTRA INFO CONTAINER
  ========================================================================== */
  .extra-info-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-phone {
      grid-row: 5;
      grid-column: 8/12;
    }

    .info-container {
      display: flex;
      flex-direction: column;
    }

    .extra-info__label {
      color: $c-gray-6;
      font-weight: 400;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }

    .extra-info__label_without-tax {
      color: $c-gray-6;
      font-weight: 400;
      font-size: $text-size-tiny;
      line-height: $text-line-height-medium;
      white-space: nowrap;
    }

    .extra-info__value_without-tax {
      font-weight: 500;
      font-size: $text-size-default;
      line-height: $text-line-height-small;
    }

    .extra-info__value {
      color: $c-secondary;
      font-weight: 500;
      font-size: $text-size-medium;
      line-height: $text-line-height-xmedium;

      @include gt-tablet {
        font-size: $text-size-large;
        line-height: $text-line-height-large;
      }
    }

    .extra-info__extra {
      color: $c-gray-6;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
    }
  }

  .first-button-container-width {
    width: $width-first-button;
  }
  /*BUTTON CONTAINER
  ========================================================================== */
  .first-button-container,
  .second-button-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include gt-phone {
      grid-column: 8/11;
    }
  }

  .first-button-container {
    @include gt-phone {
      grid-row: 7;
    }
  }

  .second-button-container {
    @include gt-phone {
      grid-row: 8;
    }
  }
  .container-display-ar {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    @include gt-phone {
      padding-top: $s-lg;
      gap: $s-lg;
    }

    &[data-remove-pricing-options='true'] {
      @include phone {
        display: none;
      }

      @include gt-tablet {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .container-display-ar__list-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  .modal-container-legal {
    width: auto;
    max-width: $max-width-modal;
    height: fit-content;
  }

  .modal-container-content-legal {
    font-weight: 400;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }
}
