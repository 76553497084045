@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$item-margin-left: 2rem;
$thumb-hover-height: 12rem;

/* CAR UPGRADE CARD CONTAINER
========================================================================== */
.car-upgrade-card-container {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: $s-md;

  padding: $s-md;

  background-color: $c-base-lightest;

  /* TITLE CONTAINER
  ========================================================================== */
  .title-container {
    display: flex;
    flex-direction: column;
    gap: $s-zs;

    .title-container__text {
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
    }

  }

  /* INFO CONTAINER
  ========================================================================== */
  .info-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    /* ADDITION CONTAINER
    ========================================================================== */
    .addition-container {
      display: flex;
      flex-direction: column;

      .addition-container__label {
        font-size: $text-size-tiny;
        line-height: $text-line-height-default;
      }

      .addition-container__value {
        font-size: $text-size-small;
        line-height: $text-line-height-default;
      }
    }

    /*INFO LIST
    ========================================================================== */
    .info-list {
      list-style: disc;

      .info-list__item {
        margin-left: $item-margin-left;

        font-size: $text-size-tiny;
        line-height: $text-line-height-default;

        &::marker {
          font-size: $text-size-xtiny;
        }
      }
    }
  }

  .car-image-container {

    @include gt-tablet {
      height: $thumb-hover-height;
    }

  }

  /*BOTTOM CONTAINER
  ========================================================================== */
  .bottom-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }
}
