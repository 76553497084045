@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__service-appointment-container {
  position: relative;

  .section-container {
    display: flex;

    flex-direction: column;
    width: 100%;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
    }

    @include gt-tablet {
      flex-direction: row;
    }
  }

  /* Navigation
  ========================================================================== */
  .navigation {
    display: none;

    @include gt-tablet {
      display: flex;
      flex-direction: column;
      gap: $s-zs;
    }
  }

  .navigation__item {
    padding-bottom: $s-zs;
    border-bottom: 1px solid $c-gray-2;

    [data-is-selected='true'] {
      color: $c-primary;
    }
  }

  /* CONTENT
  ========================================================================== */
  .content-container {
    width: 100%;
  }

  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
