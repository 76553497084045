@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.tabs-list-buttons {

  @include gt-tablet {
    display: flex;
    flex-direction: row;
    gap: $s-lg;

    [data-list-alignment="left"] & {
      float: left;
    }

  }

}