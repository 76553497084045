@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$card-width: 16.9rem;
$card-height: 8rem;

.molecule__events-form-container {
  position: relative;

  /* FIELDSET
  ========================================================================== */
  .form-container_fieldset {
    display: grid;
    border: none;
  }
  .form-container_fieldset_header {
    margin-bottom: $s-lg;
  }

  .form-container_fieldset_horizontal {
    display: grid;

    @include gt-phone {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $s-lg;
    }

    @include tablet {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  /* INPUTS
  ========================================================================== */
  .form-container_fieldset_horizontal_field {
    width: 100%;
  }

  .form-container_radio_group_events {
    display: flex;
    flex-direction: row;
    width: 100%;

    div {
      width: 100%;
      column-gap: $s-lg;
    }

    @include gt-phone {
      div {
        width: auto;
      }
    }
  }

  .form-container_radio_group_events_locale {
    margin-bottom: $s-lg;
  }


  /* INPUTS TEST DRIVE
  ========================================================================== */
  .form-container_group_test_drive {
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
      display: flex;
      flex-direction: column;
    }

    @include gt-phone {
      flex-direction: row;
      flex-wrap: wrap;
      gap: $s-lg;

    }
  }

  .form-container_group_test_drive_time {
    div {
      display: flex;
      flex-direction: column;
      margin-bottom: $s-s;
    }

    @include gt-phone {

      div {
        margin-bottom: 0;
      }

      label {
       margin-bottom: 0;
      }

    }
  }

  .form-container_group_test_drive_thumbHouver {

    @include gt-phone {
      width:  $card-width;
      height: $card-height;
    }
  }

  .form-container_group_test_drive_content_title {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    font-weight: 700;

    @include gt-phone {
      font-weight: 400;
    }
  }

  .form-container_group_test_drive_content_not-available {
    font-size: $text-size-xtiny;
    line-height: $text-line-height-medium;
    color : $c-primary-dark;
  }

  .form-container_group_test_drive_content_subtitle {
    font-size: $text-size-tiny;
    line-height: $text-line-height-medium;
    color: $c-gray-4;
  }
  .form-container_group_test_drive_content_subtitle--selected {
    font-size: $text-size-tiny;
    line-height: $text-line-height-medium;
    color: $c-base-black;
  }

  .form-container_group_test_drive_radio_button_disabled:hover::after {
    content: "Horário não disponível";
    position: absolute;
    color: $c-primary-dark;
    font-size: $text-size-micro;
    line-height: $text-size-micro;
    margin-block-start: $text-size-small;
    margin-left: $s-md;
  }

  .form-container_group_test_drive_info_time {
    font-size: $text-size-xtiny;
    line-height: $text-line-height-medium;
  }

  .form-container_message_error {
    color: $c-primary;
    font-size: $text-size-tiny;
    line-height: $text-line-height-medium;
    margin-block-start: $s-s;
  }

  .form-container_fieldset_agreement {
    margin-block-start: $s-lg;
  }
}
