@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$gap-custom: 3.8rem;

.molecule__my-vehicle-container {
  display: grid;
  position: relative;
  gap: $gap-custom;

  .title-my-vehicle,
  .title-new-vehicle {
    font-weight: 500;
    line-height: $text-line-height-xmedium;
  }

  .title-new-vehicle {
    margin-block-end: $s-s;
  }

  .my-vehicle-container-details {
    display: grid;
    gap: $s-lg;
  }

  .message {
    z-index: 9999;
    position: fixed;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
    width: 100%;
    max-width: 1200px;
    padding: 10px;
    background-color: green;
    color: #fff;
    text-align: center;
  }

  .container-form {
    display: grid;
  }

  .container-content-info {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: $s-zs;
  }

  .container-content-info-text {
    line-height: $text-line-height-medium;
  }

  .container-content-info-title {
    font-weight: 400;
    font-size: $text-size-small;
    line-height: $text-line-height-large;
  }

  .container-form-fields {
    display: grid;
    gap: $s-lg;

    @include gt-phone {
      grid-template-columns: repeat(2, 1fr);
      column-gap: $s-lg;
      margin-block-end: 0;
    }
  }

  .form-input {
    input {
      margin-bottom: 0;
    }
  }

  .container-form-button-modal {
    width: fit-content;
    height: fit-content;
    margin-block-start: $s-lg;
    margin-block-end: $s-lg;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    color: $c-base-black;
    transition: color ease $basic-transition;

    label {
      border-bottom: solid 1px $c-base-black;
    }

    &:hover,
    &:focus {
      outline: none;
      background-color: none;
      color: $c-gray-5;
      text-decoration: none;
      transition: color ease $basic-transition;
      label {
        border-bottom: solid 1px $c-gray-5;
      }
    }
  }

  .container-form-button-submit {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-phone {
      flex-direction: row;
    }
  }

  .button-submit {
    @include gt-phone {
      width: fit-content;
    }
  }

  .container-notification {
    @include gt-phone {
      section {
        padding: 0;
      }
    }
  }
}
