@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$fiedls-width: 25.8rem;
$fiedls-height: 5.6rem;

$modal-width: 61.2rem;
$modal-image-height: 25.1rem;


/* RECALL SEARCH
========================================================================== */
.molecule__recall-search-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $s-lg;
  padding: $s-md;
  background-color: $c-base-white;

  @include gt-tablet {
    padding: $s-lg;
  }

  .loader-container {
    padding: $s-lg;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .search-container {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    @include gt-phone {
      gap: $s-md;
    }
    
    .form-container {
      display: flex;
      flex-direction: column;
      gap: $s-md;

      @include gt-phone {
        gap: $s-lg;
      }

      .fields-container {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: $s-xl;
        column-gap: $s-s;

        @include gt-phone {
          grid-template-columns: repeat(auto-fit, minmax($fiedls-width, 1fr)); 
        }

        input {
          margin-bottom: 0;
        }

        .modal-activate-button {
          text-decoration: underline;
          font-size: $text-size-xtiny;
          font-weight: 400;
          line-height: $text-line-height-default;
          border: none;
          text-align: left;
        }
      }

      .search-button {
        min-width: $fiedls-width;
        height: $fiedls-height;
        padding: $s-s $s-md;
        background-color: $c-primary;
        border: none;
        font-size: $text-size-small;
        line-height: $text-line-height-medium;
        font-weight: 500;
        color: $c-base-white;

        @include gt-phone {
          margin-left: auto;
        }

        &[disabled] {
          background-color: $c-gray-3;
          pointer-events: none;
        }
      }
      .vin-container {
        display: flex;
        align-items: flex-end;
      }

      .vin-input {
        width: 100%;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  /*  MODAL
  ========================================================================== */
  .modal-container {
    padding: $s-s;
    gap: $s-lg;
    max-width: 100%;

    @include gt-phone {
      padding: $s-md;
      width: $modal-width;
    }

    img {
      @include gt-phone{
      min-height: $modal-image-height;
    }
    }
  }
}
