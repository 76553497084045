@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$modal-mobile-width: 31.2rem;
$modal-mobile-height: 40rem;
$modal-desktop-width: 63.6rem;
$modal-desktop-height: 31.2rem;
$max-width-modal: 85.3rem;

.molecule__my-shifts-container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: $s-lg;

  .my-shifts__container,
  .my-shifts__container-initialize {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    .no-shifts {
      display: flex;
      flex-direction: column;
      gap: $s-s;

      .p-no-shifts {
        font-weight: 500;
        font-size: $text-size-small;
        line-height: $text-line-height-medium;
      }

      .p-scheduled-shifts {
        font-weight: 300;
        font-size: $text-size-default;
        line-height: $text-line-height-medium;
      }
    }
  }

  .container-info_others {
    display: grid;
    gap: $s-s;
    color: $c-base-black;
    line-height: $text-line-height-medium;
  }

  .container-info_others_title {
    font-weight: 500;
    font-size: $text-size-small;
  }

  .container-info_others_content {
    font-size: $text-size-default;
  }

  .buttons {
    display: grid;

    @include gt-tablet {
      display: grid;
      width: fit-content;
    }
  }

  .schedule-record {
    display: grid;
    gap: $s-md;
  }

  .schedule {
    display: grid;
    gap: $s-lg;
  }

  .list-item {
    display: grid;
    gap: $s-s;
  }

  .details-body {
    display: grid;
    gap: $s-md;
  }

  .appointments {
    display: grid;
    padding: $s-s;
    gap: $s-md;
    border: 2px solid $c-gray-1;
  }

  .title-appointments {
    width: 100%;
    font-weight: 600;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  .title-p {
    color: $c-gray-6;
    font-weight: 400;
    font-size: $text-size-tiny;
    line-height: $text-line-height-small;
  }

  .appointments-body-1,
  .appointments-body-2 {
    display: flex;
    width: 100%;
    width: fit-content;
    gap: $s-lg;

    @include phone {
      display: grid;
      gap: $s-md;
    }
  }

  .details-body-1,
  .details-body-2 {
    display: flex;
    width: 100%;
    width: fit-content;
    gap: $s-lg;

    @include phone {
      display: grid;
      gap: $s-md;
    }
  }

  .details {
    display: grid;
    width: 100%;
    margin-top: $s-md;
  }

  .jobs {
    display: grid;
    gap: $s-zs;
  }

  .jobs-body-1 {
    display: grid;
    margin-bottom: $s-md;
    gap: $s-zs;
  }

  .card-aditional-services {
    gap: $s-zs;
  }

  .list-aditional-services {
    padding-left: $s-s;
    color: $c-gray-6;
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
    list-style: disc;
  }

  .price {
    font-weight: 600;
    font-size: $text-size-tiny;
    line-height: $text-line-height-small;
  }

  .price-body {
    display: grid;
    margin-bottom: $s-md;
    gap: $s-s;
  }

  .details-record {
    display: grid;
    margin-top: $s-s;
    gap: $s-s;
  }
  /* CONTAINER SUCCESS COMPLETED
  ========================================================================== */
  .success-completed-container {
    display: flex;
    flex-direction: column;
    gap: $s-xxl;
  }

  .success-completed__header {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  .success-completed__header__content {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    font-weight: 400;
  }

  .success-completed__header_additional_info {
    display: grid;
    gap: $s-md;
  }

  .success-completed__content_additional_info {
    display: grid;
    flex-direction: column;
  }

  .success-completed__important__content {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    font-weight: 600;
  }

  .success-completed__buttons {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-phone {
      flex-direction: row;
      flex-wrap: wrap;
      gap: $s-lg;
    }

    .success-completed_button {
      font-weight: 500;
    }
  }

  .steps-result__container {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    .confirm_anchor {
      font-weight: 400;
      font-size: $text-size-xtiny;
      line-height: $text-line-height-default;
    }

    .cancel_button {
      text-align: left;

      @include phone {
        text-align: center;
      }
    }
  }

  /* CONTAINER STEPS
  ========================================================================== */
  .content,
  .content-vehicle {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .content-vehicle {
    font-weight: 500;
  }

  .confirm_anchor {
    display: grid;
    align-items: center;

    @include gt-phone {
      align-items: flex-start;
      width: fit-content;
    }
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  color: $c-base-text;

  .title_modal {
    font-weight: 500;
  }

  .modal-container-content {
    display: flex;
    gap: $s-lg;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;

    @include gt-phone {
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }
  }

  .modal-container-buttons {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
    }
  }
}

.legal {
  font-weight: 400;
  font-size: $text-size-xtiny;
  line-height: $text-line-height-default;
}

.modal-container-legal {
  width: auto;
  max-width: $max-width-modal;
  height: fit-content;
}

.modal-container-content-legal {
  font-weight: 400;
  font-size: $text-size-default;
  line-height: $text-line-height-medium;

  a {
    text-decoration: underline;
  }
}

.no-vehicles-p {
  font-weight: 500;
  font-size: $text-size-default;
  line-height: $text-line-height-medium;
}

.buttons-add {
  display: grid;
  margin-top: $s-s;
  @include gt-tablet {
    display: grid;
    width: fit-content;
  }
}

.expanded-content {
  > div > button,
  :hover:focus {
    opacity: 1 !important;
    transition: none !important;
  }
  > div > button > svg > path {
    stroke: black;
  }
}
