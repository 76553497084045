@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';


/* VARIABLES
========================================================================== */
$bottom-message: -0.5rem;
$bottom-long-message: 2.8rem;

/* FIELDS CONTAINER
========================================================================== */
.ff-container {
  display: flex;
  position: relative;
  flex-direction: column;
}

/* FORM FIELDS
========================================================================== */
.ff-box,
.ff-box-clean {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  margin-bottom: $s-lg;
  padding: 0 $s-s;
  border: $c-gray-3 solid 0.1rem;
  outline: none;
  background: transparent;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;

  &:focus {
    border: $c-gray-6 solid 0.1rem;
    outline: none;
  }

  &:disabled {
    border: none;
    outline: none;
    background-color: $c-gray-2;
    cursor: not-allowed;
  }
}

.absent {
  visibility: hidden;
  position: absolute;
}

.ff-box-error {
  border: $c-state-danger-dark solid 0.1rem;
}

/* ERROR MESSAGE
========================================================================== */
.ff-error-message, .ff-error-message-checkbox, .ff-error-message-long {
  left: 0;
  color: $c-state-danger-dark;
  font-weight: 400;
  font-size: $text-size-tiny;
  line-height: $text-line-height-default;
}

.ff-error-message {
  position: relative;
  bottom: $bottom-message;
}

/* ERROR MESSAGE LONG
========================================================================== */
.ff-error-message-long {
  position: relative;
  bottom: $bottom-long-message;
}

/* ERROR MESSAGE LONG
========================================================================== */
.ff-error-message-checkbox {
  position: absolute;
  bottom: $s-zs;
}

/* LABEL
========================================================================== */
.ff-label {
  margin-bottom: $s-s;
  margin-bottom: $s-s;
  color: $c-base-black;
  font-size: 14px;
  line-height: 16px;
}

.ff-disabled {
  color: $c-gray-4;
}
