@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$max-width-card: 42rem;
$min-height: 36rem;

.molecule__car-info-card-container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  border: $border-width-small solid $c-gray-3;

  @include gt-phone {
    max-width: $max-width-card;
  }

  .header-title {
    padding: $s-md;
    font-weight: 600;
    font-size: $text-size-default;
    line-height: $text-line-height-small;

    @include gt-phone {
      font-weight: 400;
      font-size: $text-size-small;
      line-height: $text-line-height-medium;
    }
  }

  .container-image {
    display: flex;
    width: 100%;
    padding: $s-zs $s-s;
    canvas,
    img {
      width: 100%;
      max-width: 100%;
      height: auto !important;
      object-fit: contain;
    }
  }

  .container-selected {
    padding: $s-md $s-s;
  }

  .container-prices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    padding: $s-md;
    gap: $s-md;
    border-top: $border-width-small solid $c-gray-3;
  }

  .info-prices {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    @include gt-phone {
      gap: $s-s;
    }
  }

  .info-prices-offers {
    display: flex;
    justify-content: center;
    margin: $s-zero $s-md;
    gap: $s-md;
  }

  .info-offers-before,
  .info-offers-after {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $s-zs;
  }

  .info-offers-before-text,
  .info-offers-after-text {
    gap: $s-md;
    color: $c-gray-6;
  }

  .info-offers-border {
    border-right: $border-width-small solid $c-gray-3;
  }

  .info-offers-before-price {
    color: $c-gray-6;
    text-decoration: line-through;
  }

  .info-offers-after-price {
    color: $c-base;
    font-weight: bold;
  }

  .info-price {
    color: $c-base-black;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  .info-no-price {
    display: flex;
    color: $c-gray-5;
    font-weight: 500;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .info-card-border {
    border-bottom: $border-width-small solid $c-gray-3;
  }

  .container-button-quantity {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    gap: $s-md;
  }

  .button-add-want {
    width: 100%;

    &[type='true'] {
      width: 35%;
      @include gt-phone {
        width: 60%;
      }
    }
  }

  .input-number {
    width: 65%;
    @include gt-phone {
      width: 40%;
    }
  }

  .disclaimer {
    color: $c-gray-6;
    font-weight: 400;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }
}
