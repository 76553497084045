@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$slide-content-width: 77.2rem;

/* CONTENT SLIDER CONTAINER
========================================================================== */
.content-slider-container {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: $s-lg;

  /* CAROUSEL CONTAINER
  ========================================================================== */
  .carousel-container {
    [class^="slick-dots"] {
      width: 100%;
    }
  }

  /* CONTENT TITLE
  ========================================================================== */
  .content-title {
    @include gt-phone {
      padding: 0;
    }
  }

  /* CONTENT CONTAINER
  ========================================================================== */
  .content-container {
    display: flex!important;
    align-items: center;
    justify-content: center;

    .content-container__info {
      max-width: $slide-content-width;

      /* CONTENT IMAGE CONTAINER
      ========================================================================== */
      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-bottom: $s-lg;

        .image-container__image {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    /* CONTET INFO CONTAINER
    ========================================================================== */
    .info-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $s-s;

      margin-bottom: $s-xl;

      @include gt-phone {
        text-align: center;
      }

      .info-container__lede {
        font-weight: 400;
        font-size: $text-size-small;
        line-height: $text-line-height-medium;
      }

      .info-container__text {
        font-size: $text-size-default;
        line-height: $text-line-height-medium;
        color: $c-gray-6;
      }
    }
  }
}
