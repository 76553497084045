@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__direct-sales-models-step-container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: $s-lg;

  .cart-summary {
    z-index: 2;
    position: absolute;
    right: 0;

    @include gt-phone {
      top: -$s-lg;
    }
  }

  .header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: $s-lg;
    margin-top: $s-zs;

    @include gt-phone {
      padding-bottom: 0;
    }
  }

  .header-button-continue {
    display: none;

    @include gt-phone {
      display: block;
    }
  }

  .cart-summary {
    display: flex;
    z-index: 2;
  }

  .container-vehicle-filter {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
    }
  }

  .vehicles-pagination {
    display: flex;
    flex-direction: column;
    gap: $s-lg;
  }

  .pagination {
    padding: 0;
  }

  .vehicles-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    gap: $s-lg;
  }

  .button-continue {
    margin-top: $s-md;
  }
}
