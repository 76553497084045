@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$arrow-size: 1.6rem;
$padding-vertical: 4px;
$padding-horizontal: 11px;
$bottom-p: 0.5rem;
$right-p: 0.3rem;

.molecule__pagination-container {
  display: flex;
  position: relative;
  justify-content: center;
  padding: $s-md 0;
  list-style-type: none;

  .pagination-container {
    display: flex;

    .pagination-item {
      display: flex;
      align-items: center;
      width: $s-lg;
      height: $s-lg;
      padding: $padding-vertical $padding-horizontal;
      border: none;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;

      > p {
        position: relative;
        right: $right-p;
        bottom: $bottom-p;
      }

      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }

      &:hover,
      &:focus {
        border: none;
        background-color: $c-gray-3;
        cursor: pointer;
      }

      &.selected {
        background-color: black;
        color: white;
      }
    }
    /* ARROW ITEM
  ========================================================================== */
    .arrow-container__item {
      display: flex;
      justify-content: flex-end;
      width: $s-lg;
      height: $s-lg;
      padding: $s-zs;
      border: none;

      &:hover,
      &:focus {
        border: none;
        background-color: $c-gray-3;
        cursor: pointer;
      }

      &.disabled {
        pointer-events: none;

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
}
