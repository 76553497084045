@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$max-width-container: 27rem;
$max-height-categories: 36rem;
$top-bullet: -0.823rem;
$right-bullet: -1rem;

.molecule__filter-group-panel-container {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  gap: $s-md;

  @include gt-phone {
    max-width: $max-width-container;
    padding: $s-md;
    background: $c-gray-1;
  }

  .mobile-filter-button {
    display: flex;
    width: 100%;
    gap: $s-zs;
    font-weight: 500 !important;

    &:focus {
      border-color: $c-base-black;
      background-color: transparent;
      color: $c-base-black;
    }

    &.show {
      display: none;
    }

    @include gt-phone {
      display: none;
    }
  }

  .icon-filter {
    path {
      stroke: currentColor;
      fill: currentColor;
    }
  }

  .container-filters {
    display: none;
    flex-direction: column;
    gap: $s-md;

    &.show {
      display: flex;
    }

    @include gt-phone {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .header-filters {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .title-filters {
    font-weight: 500;
    font-size: $text-size-medium;
    line-height: $text-line-height-xmedium;

    @include gt-phone {
      font-weight: 400;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }
  }

  .clear-button-filters {
    font-size: $text-size-tiny;
    line-height: $text-line-height-small;
  }

  .container-categories {
    display: grid;
    max-height: $max-height-categories;
    overflow-y: auto;
    gap: $s-s;
    scrollbar-color: $c-primary-dark transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: $s-zzz;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: $s-zs;
      background-color: $c-primary-dark;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    @include gt-phone {
      height: auto;

      max-height: none;
    }
  }

  .category-item {
    display: grid;
    gap: $s-zs;
    font-weight: 500;
    font-size: $text-size-tiny;
    line-height: $text-line-height-small;
  }

  .category-item-title {
    color: $c-gray-5;
    font-weight: 500;
  }

  .vehicle-item {
    display: flex;
    align-items: center;
    gap: $s-zs;
    color: $c-gray-6;
    cursor: pointer;
  }

  input[type='checkbox'] {
    width: $s-s;
    height: $s-s;
    accent-color: $c-state-danger-dark;
  }

  .apply-button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .bullet {
    display: flex;
    position: absolute;
    top: $top-bullet;
    right: $right-bullet;
    align-items: center;
    justify-content: center;
    width: $text-size-small;
    height: $text-size-small;
    border-radius: 50%;
    background-color: $c-state-danger;
    color: $c-base-white;
    font-weight: 500;
    font-size: $text-size-micro;
    line-height: $text-size-xtiny;

    @include gt-phone {
      display: none;
    }

    @include gt-tablet {
      display: none;
    }
  }
}
