@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$card-height: 16.8rem;
$card-item-width:19.8rem;

$icon-download-size: 1.6rem;
$icon-more-size: 2.4rem;

$button-width: 16.2rem;
$button-height: 5.6rem;

$select-width: 36rem;


/* RECALL LIST
========================================================================== */
.molecule__recall-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $s-md;
  padding: $s-md;
  background-color: $c-base-white;

  @include gt-phone {
    padding: $s-lg;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-phone {
      flex-direction: row;
      align-items: flex-end;
    }
  }
  
  .filter {
    @include gt-phone{
      align-items: flex-start;
    }

    select {
      margin-bottom: 0;

      @include gt-phone {
        min-width: $select-width;
      }
    }
  }

  .card-list-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    .card {
      display: flex;
      flex-direction: column;
      gap: $s-md;
      width: 100%;
      min-height: $card-height;
      border: 1px solid $c-gray-3;
      padding: $s-s;

      @include gt-phone {
        padding: $s-md;
        gap: $s-lg;
      }

      .card-items {
        display: flex;
        flex-direction: column;
        gap: $s-s;

        @include gt-phone {
          flex-direction: row;
          gap: $s-xl;
        }

        .card-item {
          display: flex;
          flex-direction: column;
          gap: $s-zs;
          width: 100%;

          @include gt-phone {
            width: $card-item-width;
          }

          > h2 {
            font-size: $text-size-tiny;
            line-height: $text-line-height-default;
            font-weight: 600;
            color: $c-gray-6;
          }

          > p {
            font-size: $text-size-default;
            line-height: $text-line-height-medium;
          }       
        }
      }

      .card-link {
        margin-top: 0;

        &:hover,
        &:focus {
          opacity: 0.6;
          text-decoration: none;
          transition: opacity ease $basic-transition;
        }

        @include gt-phone {
          margin-left: auto;
        }

        > a {
          color: $c-state-danger-dark;
          font-size: $text-size-xtiny;
          line-height: $text-size-default;
          margin-right: $s-zs;
          font-weight: 600;
          text-decoration: inherit;

          @include gt-phone {
            font-size: $text-size-default;
            line-height: $text-line-height-medium;
            margin-right: $s-zs;
            font-weight: 400;
          }
        } 

        > img {
          width: $icon-download-size;
          height: $icon-download-size;
          font-weight: bold;
        }
      }
    }
  }

  .button { 
    align-self: center;
    @include gt-phone {
      margin: 0 auto;
    }

  }
}
