@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.expanded-content-container {

  &[data-is-expanded="true"] {
      
    .toggle-button__arrow {
      transform: rotate(180deg);
      transition: transform ease .3s;
    }

    .expanded-content {      
      visibility: visible;
      transition: height ease 1s, visibility ease 1s;
    }
    
  }

  /* TOGGLE CONTAINER
  ========================================================================== */
  .toggle-container {
    display: flex;
  }

  .toggle-container__action {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: initial;
    
    border: none;

    transition: opacity ease $basic-transition;    

    &:hover, &:focus {
      opacity: .7;
      transition: opacity ease $basic-transition;
    }    
    
    /* ARROW POSITION VARIANT
    ========================================================================== */
    &[data-invert-arrow="true"] {
      justify-content: flex-start;
      gap: $s-s;

      .toggle-button__arrow {
        order: 1;

        width: 1.2rem;
        height: .8rem;
      }

      .toggle-button__label {
        order: 2;
      }

    }
    
  }  

  /* LABEL SIZES
  ========================================================================== */    
  .toggle-button__label {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    color: $c-base-black;
    max-width: 90%;

    &[data-label-size="large"] {
      font-size: $text-size-small;
      line-height: $text-line-height-medium; 
    }

  }  

  .toggle-button__arrow {
    transition: transform ease .3s;
  }

  /* CONTENT
  ========================================================================== */
  .expanded-content {
    overflow: hidden;    
    visibility: hidden;
    
    height: 0;
    
  }

}