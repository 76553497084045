@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$modal-desktop-width: 63.3rem;

.organism__offers-panel-container {
  position: relative;
}

/* SELECTORS
========================================================================== */
.selectors-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @include gt-tablet {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $grid-gutter;
  }

}

/* PLACEHOLDER
========================================================================== */
.offer-placeholder-container {
  margin-block: $s-xl;
}

.offer-placeholder-container__message {
  font-size: $text-size-small;
}

/* LOADER
========================================================================== */
.loader-container {
  margin: auto;
}