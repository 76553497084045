@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__result-submit-form-container {
  position: relative;

  /* CONTAINER SUCCESS
  ========================================================================== */
  .result-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
  }

  .result-container__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .result-text {
    font-weight: 500;
    font-size: $text-size-medium;
    line-height: $text-line-height-xmedium;
  }

  .result-container__buttons {
    display: flex;
    flex-direction: column;
    gap: $s-s;
    
  }
  
  .button {

    @include gt-tablet {
      width: fit-content;
    }
  }
}
