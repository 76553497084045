@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

$border-input-number-container: 0.1rem;

.atom__input-number-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $s-zzz $s-s;
  gap: $s-s;
  border: $border-input-number-container solid $c-gray-3;
  background-color: $c-gray-1;

  @include gt-phone {
    padding: $s-zzz $s-s;
    gap: $s-s;
  }

  .input-number-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $s-md;
    border: none;
    background: none;
    cursor: pointer;

    .input-number-icon {
      color: $c-gray-6;

      @include gt-phone {
        width: $s-s;
      }
    }

    &--disabled {
      cursor: not-allowed;

      .input-number-icon {
        color: $c-gray-4;
      }
    }
  }

  .value-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .input-number-value {
      flex: 1;
      color: $c-base-black;
      font-weight: 400;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
      text-align: center;
    }

    @include gt-phone {
      width: $s-md;
      height: $s-md;
    }
  }
}
