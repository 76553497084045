@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.atom-submit-search-button-container {
  position: relative;
  
  display: flex;

  border: none;

  &:hover, &:focus {
    opacity: 0.8;
  }

  .icon {
    width: 4.8rem;
    height: 4.8rem;

    background: $c-primary;

    object-fit: cover;
  }
}
