@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.atom__loader-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;

  .loader {
    width: 100%;
    height: 100%;
    border: solid 2px transparent;
    border-left-color: currentColor;
    border-right-color: currentColor;
    border-radius: 50%;

    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {}
  100% {
    transform: rotate(360deg);
  }
}
