@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.atom__thumb-hover-container {
  position: relative;
  display: grid;
  place-items: center;
  // width: 31.2rem;
  // height: 16.7rem;
  width: 100%;
  height: 100%;
  
  .car-image-hover {
    display: none;
  }

  /******************************************
   *      RESPONSIVITY (media queries)      *
   ******************************************/
  @include gt-phone {
    // width: 30rem;
    // height: 16rem;

/* CARD IMAGE DEFAULT
========================================================================== */
    .hover-grow {
      transform: scale(.9);
      transition: $basic-transition;
    }

    &:hover, &:focus-within {
      .hover-grow {
        transform: scale(1);
      }
    }

    /* CARD IMAGE TWO POSITIONS
========================================================================== */
    .car-image-base, .car-image-hover {
      position: absolute;
      transition: opacity $basic-transition ease-in-out;
      transform: scale(.9);
    }
    .car-image-base {
      opacity: 1;
    }
    .car-image-hover {
      display: block;
      opacity: 0;
    }

    &:hover, &:focus-within {
      .car-image-base {
        opacity: 0;
      }
      .car-image-hover {
        opacity: 1;
      }
    }



  }
}
