@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$download-doc-width: 28.4rem;
$download-doc-height: 8rem;

/* DOWNLOAD-DOC-LIST 
========================================================================== */
.molecule__download-doc-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $s-md;
  
  background: $c-gray-6;

    /* TITLE
  ========================================================================== */
  .title {
    color: $c-base-white;
  }

  /* DOC-LIST 
  ========================================================================== */
  .doc-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: $s-lg;

    @include gt-tablet {
      flex-direction: row;
      gap: 0;
    }
  }

  .doc-list__doc {
    background: $c-gray-6;
    width: $download-doc-width;
  }

  .doc-list__item {
    display: flex;
  }
}
