@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$status-container-height: 3.4rem;
$status-small-image-size: 1.6rem;
$status-medium-image-size: 3.5rem;

/* TAG
========================================================================== */

.atom__status-tag-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  gap: $s-zzz;

  height: $status-container-height;
  border-radius: 999px;
  background-color: transparent;

  font-size: $text-size-tiny;
  line-height: $text-line-height-small;

  img {
    width: $status-medium-image-size;
    height: $status-medium-image-size;
  }
}

.attention,.success {
  padding: $s-zs $s-s;
  background-color: $c-base-white;
  
  img {
    width: $status-small-image-size;
    height: $status-small-image-size;
  }
}

.attention {
  border: 1px solid $c-primary;
}

.success {
  border: 1px solid $c-state-success-dark;
}