@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
======================================================================== */
$max-width-loader-container: 33rem;
$letter-spacing-text: 0.005em;

.molecule__loader-overlay-container {
  display: flex;
  z-index: $z-index-sky;
  position: fixed;
  top: $s-zero;
  left: $s-zero;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: $max-width-loader-container;
    height: auto;
    margin: $s-md;
    padding: $s-md;
    gap: $s-s;
    border: $border-width-small solid $c-gray-3;
    background-color: $c-base-white;
  }

  .loader-spinner {
    display: flex;
    svg {
      animation: spin 1s linear infinite;

      path {
        fill: $c-primary;
      }
    }
  }

  .loader-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $text-size-default;
    line-height: $text-line-height-small;
    letter-spacing: $letter-spacing-text;
    text-align: center;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @include gt-phone {
    .loader-container {
      width: 100%;
    }
  }
}
