@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__vehiclegallery-container {
  position: relative;
  .gallery-list {
    gap: $s-lg;

    @include gt-tablet {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
