@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$drag-container-padding: 5rem;
$form-field-padding: 1.2rem;
$arrow-container-size: 2.4rem;
$radio-field-size: 2.4rem;
$car-360-negative-margin: -9rem;

/* BUILD YOUR CAR
========================================================================== */
.molecule__build-your-car-container {
  .title {

    @include gt-phone {
      grid-column: 1/5;
      grid-row-start: 1;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: $s-lg;
  }

  /* DRAG
  ========================================================================== */
  .drag-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: $s-s;

    margin-bottom: $s-lg;

    @include gt-phone {
      grid-column: 4/13;

      margin-top: $car-360-negative-margin;
      margin-bottom: 0;
    }
  }

  .action-container {
    display: flex;
    gap: $s-lg;
  }

  .action-container__info {
    font-weight: 400;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  .arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: $arrow-container-size;
    height: $arrow-container-size;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  /* FORM
  ========================================================================== */
  .form {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    @include gt-phone {
      grid-column: 1/4;
      grid-row-start: 2;
      gap: $s-md;
    }
  }

  .form__select-field,
  .form__radio-field {
    > label {
      font-weight: 400;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
    }
  }

  .form__select-field {
    > div > select {
      padding: $form-field-padding;
      margin-bottom: 0;

      font-weight: 400;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }
  }

  .form__radio-field {

    > div {
      column-gap: $s-md;

      margin-top: $s-s;
    }

    > div > div > label {
      padding-left: $s-md;

      > span {
        width: $radio-field-size;
        height: $radio-field-size;
      }
    }
  }

  /* COLOR BUTTONS
  ========================================================================== */
  .color-input-label {
    display: block;

    margin-bottom: $s-s;

    font-weight: 400;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  .color-list {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .color-list__item {

  }

  .color-list__color {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid $c-gray-4;

    &[data-is-selected="true"] {
      border-color: #383838;
    }

  }

}
