@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule-recall-banner-form-container {

  align-items: center;
  
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  @include gt-phone {
    min-height: 24rem;
  }
  
  /* HIGHTLIGHTED IMAGE
  ================================================================================= */

  .banner-highlight-image {
    
    @include phone {
      display: none;
    }

    @include gt-tablet {
      position: absolute;

      left: 0;
      bottom: 0;
  
      /* SIDE VARIATION
      ================================================================================= */
  
      &[data-variant='true'] {
        
        grid-column: 8/11;
      }
    }
  }
  
  /* FORM WRAPPER
  ================================================================================= */
  
  .banner-form-wrapper {

    display: flex;
    flex-direction: column;

    width: 100%;
    
    gap: $s-s;

    padding: $s-xl 0;
    
    @include gt-phone {

      grid-column: 7/12;

      /* SIDE VARIATION
      ================================================================================= */

      &[data-variant='true'] {
        grid-column: 2/7;
      }
    }
    
    .banner-title,.banner-description {
      max-width: 30rem;

      color: $c-base-white;
    }
    
    .banner-title {
      font-size: $text-size-small;
      line-height: $text-line-height-medium;
      font-weight: 500;
    }

    .banner-description {
      font-size: $text-size-xtiny;
      line-height: $text-line-height-default;
    }
    
    .banner-input-wrapper {
      display: flex;
    }
  }
}
