@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$radio-padding: 0.5rem;

/* FORMS CONTAINER
========================================================================== */
$components-gap: 2rem;

.recall-contact-modal-container {

  /* FORMS MODAL CONTACT CONTAINER
  ========================================================================== */
  .form-modal-contact-container {
    display: flex;
    flex-direction: column;
    
    gap: $components-gap;
  }

  /* IS HORIZONTAL
  ========================================================================== */
  .is-horizontal {
    width: 100%;

    display: flex;
    gap: $components-gap;

    padding: $radio-padding;
  }

  /* RADIO BUTTONS CONTAINER
  ========================================================================== */
  .radio-buttons-container {
    display: flex;

    gap: $s-zs;
  }

  /* LABEL
  ========================================================================== */
  .ff-label {
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  /* RADIO BUTTON CONTAINER
  ========================================================================== */
  .radio-button-container {
    display: flex;
    align-items: center;

    gap: $s-zs;
  }

  /* RADIO BUTTON
  ========================================================================== */
  .radio-button {
    width: 1.5rem;
    height: 1.5rem;

    border-radius: 50%;
    border-color: $c-gray-4;
    
    &[data-is-selected="true"] {
      border-color: $c-primary;
    }
  }

  /* MODAL SELECT CONTAINER
  ========================================================================== */
  .modal-select-container {
    width: 100%;
  }
}
