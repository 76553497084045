@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__build-your-car-section-container {
  position: relative;

  &[data-is-loading="true"] {

    &:before {
      content: '';
      position: fixed;
      z-index: $z-index-moon;
      top: 0;
      left: 0;
      right: 0;

      width: 100%;
      height: 100%;

      background: url('/images/ajax-loader-ciclo.gif') no-repeat center center rgba(0,0,0, .98);
    }

  }


  /* CONTACT FORM
  ========================================================================== */
  .page__model-contact-container {

    .contact-form {

    }

    .contact-form__info {
      @include gt-tablet {
        grid-column: 1/8;
      }
    }

    .contact-form__form {
      @include gt-tablet {
        grid-column: 8/-1;
      }
    }

  }

  .payment-banner-title {
    grid-column: 1/-1;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
    font-weight: 500;
  }

  /* SPECS
  ========================================================================== */
  .specs__title {
    margin-bottom: $s-lg;
    padding-bottom: $s-lg;

    border-bottom: 4px solid $c-gray-3;
  }

  .specs-list {
    flex-direction: column;
    display: flex;
    gap: $s-md;
  }

  .specs-list__item {

  }

  .specs-list__title {
    display: block;

    margin-bottom: $s-zs;

    font-weight: 400;
    text-transform: capitalize;
  }

  .specs-list__description {
    color: $c-gray-5;
    font-size: $text-size-tiny;
    line-height: $text-line-height-small;
  }

}