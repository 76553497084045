@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__delete-confirmation-modal-container {
  display: grid;
  position: relative;
  gap: $s-lg;

  .container-info {
    display: grid;
    gap: $s-md;
    max-width: 57rem;

    @include gt-phone {
      gap: $s-lg;
    }
  }

  .container-info-title {
    font-weight: 600;
    font-size: $text-size-default;
    line-height: $text-line-height-xmedium;

    @include gt-phone {
      font-size: $text-size-medium;
      line-height: $text-line-height-large;
    }
  }

  .container-info-content {
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;

    @include gt-phone {
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }
  }

  .container-buttons {
    display: flex;
    flex-direction: column;
    gap: $s-lg;
    @include gt-phone {
      flex-direction: row;
    }
  }
}
