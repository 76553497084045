@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$border-width: 1px;
$font-weight: 400;

/* ACESSORY CARD
========================================================================== */
.molecule__acessory-card-container {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;

  &[data-is-link='true']:hover,
  &[data-is-link='true']:focus {
    text-decoration: none;
    opacity: 0.8;
  }

  .image {
    display: flex;
    flex-direction: column;
    width: 100%;
    object-fit: cover;

    img {
      height: 100%;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    padding: $s-md;
    gap: $s-s;

    background-color: $c-base-white;
  }

  .content-container_content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    gap: $s-s;
  }

  /* PILLS
  ========================================================================== */
  .pills {
    display: flex;
    gap: $s-zs;
  }

  /* INFO LIST
  ========================================================================== */
  .info-list {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  .info-list__item-title {
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  .info-list__item-description {
    font-weight: $font-weight;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  .info-final-price,
  .info-without-tax {
    display: grid;
    gap: $s-zzz;
  }

  .info-final-price {
    h4 {
      color: $c-gray-6;
      font-weight: 400;
      font-size: $text-size-small;
      line-height: $text-line-height-medium;
    }
    p {
      font-weight: 500;
      font-size: $text-size-medium;
      line-height: $text-line-height-xxmedium;
    }
  }

  .info-without-tax {
    h4 {
      color: $c-gray-6;
      font-weight: 400;
      font-size: $text-size-xtiny;
      line-height: $text-line-height-default;
    }
    p {
      font-weight: 500;
      font-size: $text-size-default;
      line-height: $text-line-height-small;
    }
  }

  /* EXTRA INFO
  ========================================================================== */
  .extra-info {
    color: $c-gray-5;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  /* NOTICE
  ========================================================================== */
  .notice {
    padding-top: $s-s;

    border-top: $border-width solid $c-gray-3;

    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }
}
