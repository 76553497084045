@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__toyota-cycle-panel-container {
  position: relative;
  background-color: $c-base-light;
  margin-top: $s-lg;

  .section {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: $s-xxl;
  }

  .title {
    padding-top: $s-xxl;
    padding-bottom: $s-lg;
    font-size: $text-size-medium;
    font-weight: 500;
    margin-left: $s-md;
    margin-right: $s-md;
  }

  .subtitle {
    font-weight: 400;
    margin-left: $s-md;
    margin-right: $s-md;
  }

  .vehicle-tabs-panel {
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: $s-lg;
    margin-bottom: $s-lg;
    padding-left: $s-md;
    padding-right: $s-md;
  }

  .vehicle-tab {
    white-space: nowrap;
    margin-right: $s-md;
  }

  .vehicle-main-version {
    margin-left: $s-md;
    margin-right: $s-md;
    margin-bottom: $s-xxl;
  }

  .vehicle-main-version-panel {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .vehicle-main-version-data {
    width: 100%;
  }

  .vehicle-main-version-title {
    font-size: $text-size-small;
    font-weight: 400;
    margin-bottom: $s-md;
  }

  .vehicle-main-version-title strong {
    font-weight: 600;
  }

  .vehicle-main-version-logo {
    width: 100%;
  }

  .vehicle-main-version-installments {
    font-size: $text-size-medium;
    font-weight: 500;
    margin-bottom: $s-s;
  }

  .vehicle-main-version-installments strong {
    font-weight: 600;
    color: $c-primary;
  }

  .vehicle-main-version-payment li {
    padding-left: $s-s;
    padding-bottom: $s-s;
    font-size: $text-size-default;
    font-weight: 500;
    color: $c-gray-5;
    line-height: $s-md;
  }

  .vehicle-main-version-payment li::before {
    position: absolute;
    content: '+';
    font-size: $text-size-small;
    font-weight: 700;
    color: $c-primary;
    margin-left: -$s-s;
  }

  .vehicle-main-version-payment li strong {
    display: block;

    font-size: $text-size-small;
    font-weight: 400;
    color: $c-base-black;
  }

  .vehicle-main-version-payment li span {
    display: block;

    font-size: $text-size-default;
    font-weight: 400;
    color: $c-base-black;
  }

  .vehicle-main-version-actions button {
    width: 100%;
  }

  .vehicle-main-version-cash {
    margin-top: $s-s;
    margin-bottom: $s-md;
    color: $c-gray-5;
  }

  .vehicle-main-version-cash strong {
    display: block;
    font-size: $text-size-medium;
    font-weight: 500;
    color: $c-base-black;
    padding-top: $s-zs;
  }

  .vehicle-main-version-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    font-size: $text-size-xtiny;
  }

  .vehicle-main-version-links-manual {
    margin-left: auto;
  }

  .vehicle-version-panel {
    background-color: $c-base-white;
    width: 100%;
    border: 1px solid $c-gray-3;
    padding-left: $s-md;
    padding-right: $s-md;
    padding-bottom: $s-xxl;
  }

  .vehicle-version-seal {
    position: absolute;
    display: inline;
    right: $s-md;
    top: $s-md;
  }

  .vehicle-version-logo {
    padding-bottom: $s-md;
  }

  .vehicle-version-title {
    font-size: $text-size-small;
    font-weight: 400;
    padding-bottom: $s-md;
  }

  .vehicle-version-data {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .vehicle-version-prices {
    white-space: nowrap;
    margin-bottom: $s-md;
  }

  .vehicle-version-prices li {
    margin-bottom: $s-zs;
    white-space: normal;
  }

  .vehicle-version-prices strong {
    display: block;
  }

  .vehicle-version-plus {
    flex-grow: 1;
    font-size: $text-size-large;
    color: $c-primary;
    margin-left: $s-md;
    margin-right: $s-md;
  }

  .vehicle-version-cash {
    width: 100%;
  }

  .vehicle-version-cash strong {
    font-size: $text-size-small;
    font-weight: 500;
    display: block;
    margin-top: $s-zs;
  }

  .vehicle-version-cash small {
    display: block;
    margin-top: $s-zs;
    margin-bottom: $s-zs;
  }

  .vehicle-version-cash button {
    display: block;
    margin-top: $s-md;
    width: 100%;
  }

  .vehicle-version-links {
    width: 100%;
    margin-top: $s-md;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    font-size: $text-size-xtiny;
  }

  .vehicle-version-links-manual {
    margin-left: auto;
  }

  /******************************************
   *      RESPONSIVITY (media queries)      *
   ******************************************/
  @include gt-phone {
    .title {
      font-size: $text-size-large;
      font-weight: 500;
    }

    .vehicle-tab {
      white-space: pre;
    }

    .vehicle-main-version-panel {
      flex-direction: row;
    }

    .vehicle-main-version-title {
      font-size: $text-size-large;
    }

    .vehicle-main-version-title strong {
      font-weight: 500;
    }

    .vehicle-main-version-logo {
      min-width: 672px;
    }

    .vehicle-main-version-actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }

    .vehicle-main-version-actions button {
      width: 268px;
      display: block;
      font-size: $text-size-small;
      font-weight: 500;
    }

    .vehicle-main-version-cash strong {
      font-size: $text-size-large;
    }

    .vehicle-main-version-links {
      flex-direction: column;
      margin-left: $s-lg;
    }

    .vehicle-main-version-links-manual {
      margin-left: 0;
      margin-top: $s-zs;
    }

    .vehicle-versions {
      width: 100%;
    }

    .vehicle-version-panel {
      max-width: 370px;
    }

  }
}
