@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$input-size: 32.2rem;
$select-size: 37rem;
$close-left: 29.5rem;
$close-right: 6rem;
$size-close-icon: 15px;

.organism__news-search-container {
  margin-block-end: $s-lg;

  .search-form {
    display: flex;
    justify-content: space-between;

    @include phone {
      display: grid;
      justify-content: normal;
      gap: $s-lg;
    }
  }
  .search {
    display: flex;
    @include phone {
      width: 100%;
    }
  }

  .search-form__input {
    width: $input-size;
    > input {
      margin-bottom: $s-zero;
    }

    @include phone {
      width: 100%;
    }
  }

  .search-form__select {
    width: $select-size;

    @include phone {
      width: 100%;
    }
  }

  .form-container {
    display: grid;
  }

  /* LOADER CONTAINER
    ========================================================================== */
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: $s-xxl $s-xxxl;
  }

  .close-icon,
  .search-form__input {
    position: relative;
  }

  .close-icon {
    display: inline-block;
    border: $s-zero solid transparent;
    outline: $s-zero;
    background-color: transparent;
    vertical-align: middle;
  }
  .close-icon:after {
    display: block;
    z-index: 1;
    position: absolute;
    top: $size-close-icon;
    left: $close-left;
    width: $size-close-icon;
    height: $size-close-icon;
    border: none;
    content: url("data:image/svg+xml;utf8,<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.666992 11.3332L11.3337 0.666565M11.3337 11.3332L0.666992 0.666565' stroke='black'/></svg>");

    @include phone {
      right: $close-right;
      left: auto;
    }
  }

  .close-icon-empty {
    display: none;
  }

  .section-list-container {
    gap: $s-lg;
    section {
      padding: $s-zero;
    }
    hr,
    p {
      margin: $s-zero;
    }
    div {
      margin-top: $s-zero;
    }
  }
}
