@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/*VARIABLES
========================================================================== */
$border-width: 1px;
$form-container-padding: 6.4rem 0 10rem 10rem;
$category-container-width: 10rem;
$gap-seal: 0.7rem;
$size-seal-img-mob: 5.6rem;
$size-seal-img-desk: 7.2rem;

.organism__offer-panel-container {
  position: relative;
  overflow: hidden;

  .info-list__value small {
    display: block;

    margin-left: 0;
    color: $c-base-darker;

    font-size: $text-size-tiny;
  }
}

/*INFO LIST
========================================================================== */
.info-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: $s-s;

  padding-top: $s-md;

  border-top: $border-width solid $c-gray-3;

  @include gt-tablet {
    align-items: center;
  }
}

.info-list__item {
  display: flex;
  flex-direction: column;
}

.info-list__label {
  color: $c-base-darkest;
  font-size: $text-size-default;
  line-height: $text-line-height-medium;
}

.info-list__value {
  color: $c-secondary;
  font-weight: 400;
  font-size: $text-size-small;
  line-height: $text-line-height-medium;
}

.info-list__value--tax {
  @include gt-tablet {
    display: flex;
    flex-direction: row;
    gap: $s-zs;
  }
}

.info-list-residual {
  display: flex;
  flex-direction: column;

  padding-top: $s-md;
  gap: $s-md;

  border-top: $border-width solid $c-gray-3;
}

.info-list-residual .info-list__label {
  font-size: $text-size-tiny;
  line-height: $text-line-height-small;
}

/*EXTRA INFO
========================================================================== */
.extra-info-list {
  display: flex;
  flex-direction: column;

  padding-top: $s-md;

  border-top: $border-width solid $c-gray-3;
}

.extra-info-list__item {
  display: flex;
  flex-direction: row;
}

.extra-info-list__label {
  padding-right: $s-zs;
  color: $c-base-darkest;
  font-size: $text-size-default;
  line-height: $text-line-height-medium;
}

.extra-info-list__value {
  color: $c-secondary;
  font-weight: 400;
  font-size: $text-size-small;
  line-height: $text-line-height-medium;
}

.extra-info-list__extra {
  color: $c-gray-6;
  font-size: $text-size-tiny;
  line-height: $text-line-height-default;
}

/*EXTRA INFO
========================================================================== */
.extra-info {
  color: $c-secondary;
  font-size: $text-size-default;
  line-height: $text-line-height-medium;
}

/*LINKS LIST CONTAINER
========================================================================== */
.links-list-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  padding-top: $s-md;
  gap: $s-s;

  border-top: $border-width solid $c-gray-3;
}

.links-list-container__item {
  &:nth-child(even) {
    text-align: right;
  }
}

/* VEHICLE INFO
========================================================================== */
.vehicle-info-container {
  padding-top: $s-lg;

  @include phone {
    padding: $s-lg $s-md;
  }

  @include gt-tablet {
    grid-column: 1/6;

    padding-block: $s-xxl;
  }
}

.vehicle-info {
  display: flex;
  flex-direction: column;
  gap: $s-md;
}

.offer-deadline {
  color: $c-gray-6;
  font-weight: 400;
  font-size: $text-size-default;
  line-height: $text-line-height-default;
}

.vehicle-360-container {
  margin-top: -$s-lg;

  @include gt-tablet {
    margin-bottom: $s-md;
  }

  [data-is-360='true'] {
    width: 100% !important;
    height: 100% !important;
  }
}

/* DESCRIPTION LIST
========================================================================== */
.description-list {
  display: flex;
  flex-direction: column;

  margin-left: $s-zs;
  gap: $s-zs;

  list-style: disc inside;
}

.description-list__item {
  color: $c-base-black;
  font-size: $text-size-small;
  line-height: $text-line-height-medium;

  &::marker {
    color: $c-primary;
    font-size: $text-size-xtiny;
  }
}

/* CATEGORY
========================================================================== */
.category-container {
  width: $category-container-width;
  padding: $s-zs;
  color: $c-base-white;
  font-weight: 500;

  font-size: $text-size-xtiny;
  line-height: $text-line-height-default;
  text-align: center;
  text-transform: uppercase;
}

/* SEALS
========================================================================== */
.seals-list {
  display: flex;
  flex-direction: row;
  gap: $gap-seal;
}

.seal-list-img {
  width: $size-seal-img-mob;
  height: $size-seal-img-mob;

  @include gt-tablet {
    width: $size-seal-img-desk;
    height: $size-seal-img-desk;
  }
}

.extra-info-container {
  display: flex;
  flex-direction: column;
  gap: $s-md;
}

/* FORM CONTAINER
========================================================================== */
.vehicle-form-container {
  position: relative;

  padding-block: $s-xl;

  background-color: $c-gray-1;

  @include phone {
    padding: $s-lg $s-md;
  }

  @include gt-tablet {
    grid-column: 7/-1;

    padding: $s-xxl 0 $s-xxl $grid-padding-desktop;
  }

  &:before {
    position: absolute;
    top: 0;
    left: -100%;

    width: 200vw;
    height: 100%;
    background-color: $c-gray-1;
    content: '';
    pointer-events: none;

    @include gt-tablet {
      right: -100%;
      left: initial;

      width: 100%;
    }
  }
}
