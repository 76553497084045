@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__accessory-single-container {
  position: relative;

  .tasa-legal-text {

    p {
      margin-bottom: 0;
      color: $c-gray-6;

      +p {
        margin-top: $s-s;
      }

    }

  }

}