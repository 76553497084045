@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__lgpd-form-container {
  position: relative;

  max-width: 100%;

  margin: auto;

  @include gt-phone {
    max-width: $grid-width-desktop;
  }

  .form-container__section {
    display: grid;
  }

  .form-submit-container {
    display: flex;
    flex-direction: column;
    margin-top: $s-s;
  }
}
