@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$max-width-button-confirm: 19.8rem;
$max-width-input: 37rem;
$max-width-modal: 85.3rem;

.molecule__service-appointment-date-and-price-step-container {
  display: grid;
  position: relative;
  gap: $s-lg;

  .form-container-group-choice-date-time {
    display: none;
    display: flex;
    flex-direction: row;
    white-space: pre-line;
    @include tablet {
      > div {
        justify-content: center;
      }
    }
  }

  .group-radio-button-date {
    display: flex;
    width: 100%;
    padding: $s-s;
    gap: $s-s;
    background-color: $c-gray-1;

    label {
      margin-bottom: 0;
    }

    @include gt-phone {
      width: $max-width-input;
    }

    @include tablet {
      width: 100%;
    }
  }

  .group-radio-separator {
    display: grid;
    align-items: center;
    padding-block: 0.8rem;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    text-align: center;
  }

  .group-radio-button-label {
    display: flex;
    gap: $s-s;
  }

  .form-container-selected-date-time {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  .selected-date-time {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
    }
  }

  .input-selected {
    width: 100%;

    @include gt-phone {
      width: $max-width-input;
    }

    @include tablet {
      width: 100%;
    }
  }

  .return-dealers-container {
    display: flex;
    flex-direction: column;
    gap: $s-zs;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .return-dealers-container-link {
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
  }

  .group-radio-button-vehicle {
    label {
      margin-bottom: 0;
      padding-left: $s-md;
    }
  }

  .form-container-group-wait-for-vehicle {
    div {
      justify-content: flex-start;
    }
  }

  /* SECTION SERVICES
  ========================================================================== */
  .requested-services-and-price-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-phone {
      gap: $s-lg;
    }
  }

  .requested-services-container,
  .requested-price-container {
    display: grid;
    gap: $s-s;
  }

  .requested-services-info,
  .requested-price-info {
    display: grid;
    gap: $s-zs;
  }

  .requested-price-info {
    font-weight: 600;
    line-height: $text-line-height-medium;
  }

  .requested-services-title {
    font-size: $text-size-small;
  }

  .title-price-info {
    font-size: $text-size-default;
  }

  .price-info {
    font-weight: 500;
    font-size: $text-size-tiny;
    line-height: $text-line-height-small;
  }

  /* SECTION BUTTON CONFIRM
  ========================================================================== */
  .form-container-button {
    width: 100%;
    @include gt-phone {
      max-width: $max-width-button-confirm;
    }
  }

  .modal-container {
    width: auto;
    max-width: $max-width-modal;
    height: fit-content;
  }

  .modal-container-content {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }
}

.container-label {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.label-delivery {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label-delivery-disabled {
  color: $c-gray-6;
  font-size: $text-size-tiny;
  line-height: 1.6rem;
}

.label-delivery-disabled-display {
  display: none;
}
