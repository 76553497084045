@import 'utils/styles/_forms';

/* VARIABLES
========================================================================== */
$card-width: 16.9rem;
$card-height-mobile: 8rem;
$card-height: 10rem;

.atom__form-radio-button-container {
  /* CHECKBOX LABEL
  ========================================================================== */
  .ff-label {
    display: block;
    position: relative;
    padding-left: calc($s-s + $s-s);
    margin-bottom: $s-lg;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input:focus + .checkmark {
      outline-offset: 2px;
      outline: $c-primary-light auto 1px;
    }

    @include gt-phone {
      padding-left: calc($s-s + $s-zs);
    }
  }

  .ff-label.ff-label-clean {
    margin-bottom: 0;
  }

  /* HIDE DEFAULT CHECKBOX
  ========================================================================== */
  .ff-label input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;
  }

 /* CUSTOM CHECKBOX CHECKMARK
 ========================================================================== */
  .checkmark {
    position: absolute;
    margin: auto 0;
    top: 0;
    bottom: 0;
    left: 0;
    height: $s-s;
    width: $s-s;
    border: $c-gray-3 solid 0.1rem;
    border-radius: 50%;
  }

  .ff-label:hover input~.checkmark {
    background-color: $c-gray-2;
  }

  .ff-label input:checked ~ .checkmark {
    background-color: $c-primary;
    border-color: $c-primary;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .ff-label input:checked ~ .checkmark:after {
    display: block;
  }

  .ff-label .checkmark:after {
    width: 14px;
    height: 14px;
    border: solid $c-base-white 0.3rem;
    border-radius: 50%;
  }

  /* CHECKBOX DISABLED
  ========================================================================== */

  .ff-disabled {
    cursor: not-allowed;

    .checkmark {
      background-color: $c-gray-2;
    }
  }

  /* COLORS
  ========================================================================== */
  .checkmark[data-has-color],
  .ff-label .checkmark[data-has-color]:after {
    border: none;
  }

  .ff-label:hover input ~ .checkmark[data-has-color],
  .ff-label input:checked ~ .checkmark[data-has-color] {
    border: 0.2rem solid $c-gray-4;
  }

  /* VARIANT CARD
  ========================================================================== */
  &[data-variant='type-1'] {
    display: flex;
    width: 100%;
    height: $card-height-mobile;
    padding: 0px $s-zs;
    background-color: $c-gray-1;

    @include gt-phone{
      width: $card-width;
      height: $card-height;
    }

    /* CARD CHECKBOX LABEL
    ==========================*/
    .ff-label {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 0;

    }

    /* CARD CHECKBOX LABEL/DIV
    ===========================*/
    .ff-label > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      line-height: 24px;
    }

    /* CARD CHECKBOX CHECKMARK
    ===========================*/
    .ff-label input:checked ~ div {
      color: $c-base-black;
    }
  }

  /* VARIANT CARD_IMAGE
  ============================================================================== */
  &[data-variant='type-2'] {
    display: flex;
    flex-direction: column;
    padding-top: $s-zs;

    /* CARD CHECKBOX LABEL
    =========================*/
    .ff-label {
      margin-bottom: 0;
    }

    /* CARD CHECKBOX LABEL/DIV
    ==========================*/
    .ff-label > div {
      color: $c-gray-4;
      display: flex;
      flex-direction: column;
      width: 100%;
      line-height: 24px;
    }
  }
}
