@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$value-text-size: 4rem;

/* CAR CONTACT INFO CONTAINER
========================================================================== */
.car-contact-info-container {
  position: relative;

  .header-container {
    @include gt-tablet {
      max-width: 35rem;
    }
  }

  /* CAR IMAGE CONTAINER
  ========================================================================== */
  .car-image-container {
    margin-top: $s-md;

    max-width: 67.2rem;

    @include gt-tablet {
      height: 31.6rem;
    }

  }

  /* SUBTITLE CONTAINER
  ========================================================================== */
  .subtitle-container {
    margin-top: $s-lg;

    .is-thinner {
      font-weight: 400;
    }

    @include gt-phone {
      display: flex;
      gap: $s-zs;
    }
  }

  /* CAR PAYMENT BANNER CONTAINER
  ========================================================================== */
  .car-payment-banner-container {
    margin-top: $s-xxl;

    @include gt-phone {
      margin-top: $s-xl;
    }
  }

  /* PAYMENT INFO CONTAINER
  ========================================================================== */
  .payment-info-container {
    display: flex;
    flex-direction: column;

    margin-top: $s-lg;

    .payment-info-container__label {
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
    }

    .payment-info-container__value {
      font-size: $value-text-size;
      line-height: $text-line-height-large;
      font-weight: 600;

      @include gt-phone {
        font-size: $text-size-xlarge;
        line-height: $text-line-height-xlarge;
      }
    }

    .payment-info-container__extra-info {
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
      color: $c-gray-4;
    }
  }

  /* FEATURES LIST
  ========================================================================== */
  .features-list {
    display: grid;
    row-gap: $s-md;

    margin-top: $s-xxl;

    @include gt-phone {
      grid-template-columns: repeat(2,1fr);
      column-gap: $s-lg;
    }

    .features-list__item {
      display: flex;
      flex-direction: column;
      gap: $s-zs;

      .item-title-container {
        display: flex;
        align-items: flex-end;
        gap: $s-zs;

        padding-top: $s-s;

        border-top: 2px solid $c-gray-3;

        .icon-container {
          width: $s-s;
          height: $s-s;
        }

        .title {
          font-size: $text-size-default;
          font-weight: 500;
        }

        .color-container {
          width: $s-s;
          height: $s-s;

          border: 2px solid $c-gray-4;
          border-radius: 50%;
        }
      }

      .item-info {
        font-size: $text-size-tiny;
        line-height: $text-line-height-default;
        color: $c-gray-5;
      }
    }
  }

  /* LINK CONTAINER
  ========================================================================== */
  .link-container {
    margin-top: $s-xl;

    @include gt-phone {
      margin-top: $s-xxl;
    }

    .anchor-link {
      text-decoration: none;
      font-weight: 500;

      @include gt-phone {
        font-weight: 400;
      }
    }
  }
}
