@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$border-height: 1px;
$max-width-content: 57.2rem;
$width-video: 17rem;
$height-video: 5rem;

$separator-border-width: 90.7rem;

.molecule__interactive-video-content-container {
  position: relative;

  .divider {
    width: 100%;
    border-top: $border-height solid $c-gray-4;

    @include gt-tablet {
      width: $separator-border-width;
    }
  }

  .section-container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: $s-lg;
  }

  .content {
    max-width: $max-width-content;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
    text-align: center;
  }

  &[data-size-video='small'] {
    .video {
      width: $width-video;
      height: $height-video;

      video {
        object-fit: cover;
      }
    }
  }
}
