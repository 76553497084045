@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule-action-banner-with-form-container {
  grid-gap: 0;
  align-items: center;
  padding: 0;

  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  @include gt-phone {
    min-height: 24rem;
  }

  /* HIGHTLIGHTED IMAGE
  ================================================================================= */

  .banner-highlight-image {
    @include phone {
      display: none;
    }

    @include gt-tablet {
      position: absolute;
      bottom: 0;

      left: 0;

      /* SIDE VARIATION
      ================================================================================= */

      &[data-variant='true'] {
        grid-column: 8/11;
      }
    }
  }

  /* FORM WRAPPER
  ================================================================================= */

  .banner-form-wrapper {
    display: flex;
    flex-direction: column;

    width: 100%;

    padding: $s-lg $s-md;

    gap: $s-lg;

    @include gt-phone {
      grid-column: 6/12;

      /* SIDE VARIATION
      ================================================================================= */

      &[data-variant='true'] {
        grid-column: 2/7;
      }
    }

    @include tablet {
      grid-column: 1/12;
    }

    .banner-title,
    .banner-description {
      max-width: 30rem;

      color: $c-base-white;
    }

    .banner-title {
      max-width: 100%;
      font-weight: 500;
      font-size: $text-size-small;
      line-height: $text-line-height-medium;
    }

    .banner-description {
      font-size: $text-size-xtiny;
      line-height: $text-line-height-default;
    }

    .banner-input-wrapper {
      display: flex;
    }

    .form-container__list-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 0;
      gap: $s-lg;

      @include gt-phone {
        flex-direction: row;
      }
    }

    .form-container__input {
      width: 100%;

      label {
        color: $c-base-white;
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    .button-submit {
      width: 100%;

      @include gt-phone {
        width: auto;
      }
    }

    .button-container,
    .form-container__list-input,
    .banner-title,
    .banner-description {
      @include gt-tablet {
        margin-left: $s-s;
      }
    }
  }
}
