@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$car-color-button-size: 3.6rem;

.molecule-car-detail-simulation-container {
  display: flex;
  position: relative;
  row-gap: $s-xl;
  flex-direction: column;
  flex-wrap: wrap;

  /*TITLE
  ========================================================================== */
  .section-title-container {
    .bold {
      font-weight: 500;
    }
  }

  .vehicle-content {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  .conditions-content {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    &__divider {
      display: flex;
      width: 100%;
      height: 1px;
      margin: 0 $s-md;
      border: none;
      background-color: var(--gray-3);

      @include gt-tablet {
        width: 1px;
        height: unset;
      }
    }

    @include gt-tablet {
      flex-direction: row;
      margin-top: $s-lg;
    }
  }

  /* COLOR BUTTONS
  ========================================================================== */
  .color-selection {
    display: flex;
    flex-direction: column;
    place-items: center;
    gap: $s-s;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 100%;
      gap: $s-s;

      @include gt-tablet {
        flex-direction: column;
      }
    }

    &__title {
      margin-right: $s-zs;
      gap: $s-s;
      color: $c-base-black;
      font-size: $text-size-tiny;
      white-space: nowrap;
    }

    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      width: 100%;
      gap: $s-md;
    }

    &__item {
      margin-inline: unset !important;
    }

    &__circle {
      width: $car-color-button-size;
      height: $car-color-button-size;

      border: none;
      border-radius: 50%;

      transition: box-shadow ease $basic-transition;

      &[data-is-selected='true'] {
        border: 2px solid $c-base-white;
        box-shadow: 0 0 0 2px $c-base-black;

        transition: box-shadow ease $basic-transition;
      }
    }

    &__activated {
      font-size: $text-size-small;
      line-height: $text-line-height-default;
    }
  }

  /*FULL PRICE CONTAINER
  ========================================================================== */
  .full-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 !important;
    gap: $s-s;

    .price {
      display: flex;
      flex-direction: column;
    }

    &__label {
      line-height: $s-md;
      white-space: nowrap;
    }

    &__label__price {
      color: $c-gray-6;
      font-weight: 400;
      line-height: $text-line-height-medium;
      white-space: nowrap;
    }
    
    &__label__without__tax {
      color: $c-gray-6;
      font-weight: 400;
      line-height: $text-line-height-medium;
      font-size: $text-size-tiny;
      white-space: nowrap;
    }

    &__value {
      font-weight: 500;
      font-size: $text-size-medium;
      line-height: $text-line-height-xmedium;

      @include gt-tablet {
        font-size: $text-size-large;
        line-height: $text-line-height-large;
      }
    }

    &__value__without__tax {
      line-height: $text-line-height-small;
      font-size: $text-size-default;
      white-space: nowrap;
    }

    &__extra {
      font-size: $s-sm;
    }

    &__action {
      width: 100%;
    }
  }

  /*LOAN CONTAINER
  ========================================================================== */
  .loan-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $s-lg;

    @include gt-tablet {
      align-items: center;
    }

    * {
      margin: 0 !important;
    }

    &__details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $s-s;

      @include gt-tablet {
        flex-direction: row;
      }
    }

    &__logo {
      align-self: flex-start;
      width: $s-xl;
      height: $s-xl;
    }

    &__block {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $s-zs;

      &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 186px;
        line-height: $text-line-height-medium;
      }

      &-plus {
        font-weight: 700;
        font-size: $text-size-small;
      }
    }

    &__emphasis {
      font-size: $text-size-small;
    }

    &__used_as_entry {
      white-space: nowrap;
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 312px;
      gap: $s-md;
    }

    &__cta {
      width: 100%;
    }
  }

  .kinto-info {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 !important;
    gap: $s-md;

    &__cta {
      width: 100%;
    }
  }
}
