@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

$base-height: 152px;
$base-width: 270px;

/* CONTAINER
========================================================================== */
.molecule__search-result-card-container {
  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: $s-s;

  @include gt-phone {
    flex-direction: row;
    column-gap: $s-lg;
    align-items: center;

    max-height: $base-height;
  }

/* CARD CONTENT
========================================================================== */
  .anchor {
    cursor: pointer;
    // text-decoration: none !important;
  }

  .info {
    display: flex;
    flex-direction: column;
    row-gap: $s-s;

    width: 100%;

    @include gt-phone {
      row-gap: $s-zs;
    }

    .info__title {
      font-weight: 700;
      font-size: $text-size-small;
      line-height: $text-line-height-medium;
    }

    .info__content {
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
      color: $c-base;
    }

    &[data-is-highlighted="true"] {

      .info__title {
        color: $c-primary;
      }

      .info__content {
        color: $c-gray-5;
      }
    }
  }

/* CARD IMAGE
========================================================================== */
  .media {
    max-height: $base-height;

    @include gt-phone {
      width: $base-width;
    }

    img {
      max-height: $base-height;
    }
  }
}
