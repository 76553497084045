@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* BREADCRUMB LIST ITEM
========================================================================== */
.molecule__breadcrumb-list-container {
  display: none;

  @include gt-tablet {
    position: relative;

    display: flex;
    flex-direction: row;
    gap: $s-lg;
  }

  /* BREADCRUMB ITEM
  ========================================================================== */
  .breadcrumb-item {

    &:not(:last-child) {
      .breadcrumb-item__link {
        gap: $s-s;
        
        color: $c-gray-5;

        &:hover,
        &:focus {
          color: $c-primary;
        }
      }
    }

    &:last-child {
      svg {
        display: none;
      }
    }
  }
}

/* GO BACK LINK
  ========================================================================== */
.breadcrumb-item--mobile {
  flex-direction: row-reverse;
  gap: $s-s;

  svg {
    width: $s-lg;
    height: $s-lg;
  }

  @include gt-tablet {
    display: none;
  }
}
