@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$max-width-confirm: 19.8rem;
$max-width-modal: 63.6rem;
$width-favorite-card: 37rem;
$width-favorite-card-web-unavailable: 30.9rem;

.molecule__service-appointment-dealers-step-container {
  display: grid;
  position: relative;
  width: 100%;
  gap: $s-lg;

  .dealers-step-container {
    display: flex;
    flex-direction: column;
    gap: $s-lg;
  }

  .dealers-step-container_form,
  .dealers-step-container_favorite,
  .dealers-step-container_confirm {
    display: flex;
    flex-direction: column;
    gap: $s-md;
  }

  .title-section,
  .favorite-card-content-name {
    font-weight: 400;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  /* SECTION FORM
  ========================================================================== */
  .form_filtered {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  .form_filtered-input {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    @include gt-tablet {
      flex-direction: row;
      gap: $s-lg;
    }
  }

  .select-input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 0;

    @include gt-phone {
      max-width: 100%;
    }
  }

  .select-input {
    option {
      color: $c-base-black;
    }
  }

  .placeholder-selected,
  .loading-indicator {
    color: $c-gray-4;
  }

  .loading-indicator {
    font-size: $text-size-xtiny;
    &::after {
      content: '.';
      animation: loading 1s infinite linear;
    }
  }

  .icon-pin-location {
    &:hover,
    &:focus {
      fill: $c-primary;
      path {
        fill: $c-primary;
      }
    }
  }

  .anchor-location {
    &:hover,
    &:focus {
      color: $c-primary;
    }
  }

  /* SECTION FAVORITE
  ========================================================================== */
  .favorite-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $s-s;
    gap: $s-s;
    border: 1px solid $c-gray-1;

    @include gt-phone {
      width: $width-favorite-card;
    }
  }

  .web-unavailable {
    width: 100%;
    @include gt-phone {
      width: $width-favorite-card-web-unavailable;
    }
  }
  .favorite-card-content-address {
    font-size: $text-size-tiny;
    line-height: $text-line-height-medium;
    text-transform: capitalize;
  }

  .web-unavailable .favorite-card-content-name,
  .web-unavailable .favorite-card-content-address,
  .web-unavailable .favorite-card-content-icon {
    color: $c-gray-4;
    path {
      fill: $c-gray-4;
    }
  }

  .favorite-card-content {
    display: flex;
    flex-direction: column;
    gap: $s-zs;
  }

  .favorite-card-content-header {
    display: flex;
    gap: $s-s;
  }

  /* SECTION TOAST - LOCATION ERROR
  ========================================================================== */
  .toast {
    z-index: 9999;
    position: fixed;
    bottom: $s-md;
    left: 50%;
    padding: $s-md;
    transform: translateX(-50%);
    border-radius: $s-zz;
    background-color: rgba(255, 0, 0, 0.8);
    color: #fff;
    animation: slideIn 0.5s ease-in-out forwards, fadeOut 2s ease-in forwards 5s;
    opacity: 0;
  }

  /* SECTION CONFIRM
  ========================================================================== */
  .confirm_button {
    width: 100%;
    @include gt-phone {
      max-width: $max-width-confirm;
    }
  }

  /* ANIMATIONS
  ========================================================================== */
  @keyframes loading {
    0% {
      content: '.';
    }
    50% {
      content: '..';
    }
    100% {
      content: '...';
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateX(-50%) translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  max-width: $max-width-modal;
  color: $c-base-text;

  .title_modal {
    font-weight: 500;
  }

  .modal-container-content {
    display: flex;
    gap: $s-lg;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;

    @include gt-phone {
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }
  }

  .modal-container-buttons {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
    }
  }
}
