@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* CONTAINER
========================================================================== */
.molecule__card-container {
  display: flex;
  position: relative;
  flex-direction: column;

  &[data-is-horizontal='true'] {
    @include gt-phone {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      gap: $s-lg;
    }
  }

  &[data-bg-color='white'] {
    background-color: $c-base-white;
  }

  &[data-variant='simple-card'] {
    padding: $s-zs;
    border: 1px solid $c-gray-1;

    .button-view-more {
      display: none;
    }

    .card__content-container {
      display: grid;
      gap: $s-zs;
    }

    .card__title {
      margin: 0;
      color: $c-base-black;
      font-weight: 500;
      font-size: $text-size-tiny;
      line-height: $text-line-height-small;
    }

    .card__content {
      font-size: $text-size-xtiny;
      line-height: $text-line-height-default;
    }
  }
}

/* CARD HEADER
========================================================================== */
.card__media {
  margin-bottom: $s-md;

  [data-is-horizontal='true'] & {
    @include gt-phone {
      margin-bottom: 0;
    }
  }
}

.card__media-link {
  opacity: 1;
  transition: opacity ease $basic-transition;

  &:hover,
  &:focus {
    opacity: 0.7;
    transition: opacity ease $basic-transition;
  }
}

/* CARD CONTENT
========================================================================== */
.card__content-wrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  gap: $s-s;

  [data-is-horizontal='true'] & {
    @include gt-phone {
      position: relative;

      .card__title {
        text-transform: none;
      }
    }
  }

  [data-variant='type-2'] & {
    padding: 0 $s-md $s-md;
  }
}

.card__content-container {
  flex: 1 0 auto;

  + .card__anchor-link {
    margin-top: $s-md;

    [data-is-horizontal='true'] & {
      @include gt-phone {
        margin-top: 0;
      }
    }
  }
}

.card__date {
  margin-bottom: $s-s;
  color: $c-gray-6;
  font-weight: 500;
  font-size: $text-size-default;
  line-height: $text-line-height-medium;
}

.card__title {
  margin-bottom: $s-s;
  font-weight: 500;

  font-size: $text-size-small;
  line-height: $text-line-height-medium;

  [data-is-uppercase='true'] & {
    text-transform: uppercase;
  }

  [data-color='light'] & {
    font-size: $text-size-small;
    line-height: $text-size-medium;

    @include gt-phone {
      font-size: $text-size-large;
      line-height: $text-line-height-large;
    }
  }
}

.card__category {
  display: inline-block;
  height: 4rem;
  margin-bottom: $s-s;
  padding: 0.8rem;
  background-color: $c-gray-3;

  p {
    display: inline;
    color: $c-base-black;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    vertical-align: middle;
  }
}

.card__content,
.card__additional-content {
  color: $c-gray-6;
  font-size: $text-size-default;
  line-height: $text-line-height-medium;

  [data-is-horizontal='true'] & {
    @include gt-phone {
      color: $c-base-black;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
    }
  }

  [data-color='light'] & {
    color: $c-gray-6;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }
}

.card__content {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: clip;
  word-break: break-word;
  transition: -webkit-line-clamp $basic-transition ease-in-out;

  &[data-hidden='true'] {
    -webkit-line-clamp: inherit;
    line-clamp: inherit;
    text-overflow: initial;
    transition: -webkit-line-clamp $basic-transition ease-in-out;
  }
}
.button-view-more {
  margin-top: $s-s;
  font-size: $s-tiny;
  line-height: $s-s;
}

/* CARD FOOTER
========================================================================== */
.card__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
