@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* SECTION HEADER
========================================================================== */
.molecule__section-header-container {
  /* IMAGE
  ========================================================================== */
  .image-container {
    width: 100%;
    margin-bottom: $s-zs;
  }

  .image {
    max-width: 100%;
    object-fit: cover;
  }

  /* HEAD
  ========================================================================== */
  .head-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &[data-center-title='true'] {
      * {
        margin-inline: auto;
        text-align: center;
      }
    }
  }

  /* DESCRIPTION
  ========================================================================== */
  .description {
    margin-top: $s-md;
    color: $c-base-black;

    font-size: $text-size-default;
    line-height: $text-line-height-medium;

    @include gt-phone {
      margin-top: $s-s;
    }

    /* TEXT COLOR
    ========================================================================== */
    &[data-text-color='gray'] {
      color: $c-gray-1;
    }

    &[data-text-color='gray-2'] {
      color: $c-gray-2;
    }

    &[data-text-color='gray-3'] {
      color: $c-gray-3;
    }

    &[data-text-color='gray-4'] {
      color: $c-gray-4;
    }

    &[data-text-color='gray-5'] {
      color: $c-gray-5;
    }

    &[data-text-color='gray-6'] {
      color: $c-gray-6;
    }
  }

  /* SPACING
    ========================================================================== */
  &[data-spacing='1'] {
    margin-bottom: $s-zs;
  }

  &[data-spacing='2'] {
    margin-bottom: $s-s;
  }

  &[data-spacing='3'] {
    margin-bottom: $s-md;
  }

  &[data-spacing='4'] {
    margin-bottom: $s-md;

    @include gt-tablet {
      margin-bottom: $s-lg;
    }
  }

  &[data-spacing='5'] {
    margin-bottom: $s-lg;

    @include gt-tablet {
      margin-bottom: $s-xl;
    }
  }

  &[data-spacing='6'] {
    margin-bottom: $s-xl;

    @include gt-tablet {
      margin-bottom: $s-xxl;
    }
  }

  &[data-spacing='7'] {
    margin-bottom: $s-xxl;

    @include gt-tablet {
      margin-bottom: $s-xxxl;
    }
  }
}
