@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$modal-desktop-width: 63.3rem;


.molecule__region-finder-container {
  position: relative;

  height: $s-lg;

  margin-top: $s-s;
  margin-bottom: $s-md;
  width: 100%;
  // TODO: add default styles (usually focused on phones)

  .wait-geolocation-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }

  /******************************************
   *      RESPONSIVITY (media queries)      *
   ******************************************/
  @include gt-phone {

    // TODO: add specifications for devices that are greater than phones
    .wait-geolocation {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

/* REGION MODAL
========================================================================== */
.offers-region-modal {
  margin: auto;

  @include gt-tablet {
    width: $modal-desktop-width;
  }

}

.offers-region-modal__description {
  margin-bottom: $s-lg;

  font-size: $text-size-tiny;
  line-height: $text-line-height-default;
  color: $c-base-black;

  @include gt-tablet {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

}

.offers-region-form {
  display: flex;
  flex-direction: column;
  gap: $s-lg;
}

.offers-region-form__selects-container {
  display: flex;
  flex-direction: column;
  gap: $s-lg;

  @include gt-tablet {
    flex-direction: row;
  }

}

.offers-region-form__select {
  width: 100%;
}

.offers-region-form__action-container {
  display: flex;
  flex-direction: column;

  @include gt-tablet {
    flex-direction: initial;
    justify-content: center;
  }

}