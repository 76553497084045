@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';
@import 'utils/styles/_sizes';

.molecule__contact-form-container {
  .form-container {
    display: grid;
    gap: $s-xxl;
  }

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__section {
    display: grid;
    width: auto;
    gap: $s-lg;
  }

  .form-container__input {
    > input {
      margin-bottom: 0;
    }

    > textarea {
      margin-bottom: 0;
    }

    > div > select {
      margin-bottom: 0;
    }
  }

  .form-submit-container {
    display: flex;
    flex-direction: column;
  }
}
