@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__notification-container {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: $s-zs $s-md;
  gap: $s-zs;
  background-color: $c-gray-4;
  color: white;
  font-size: 16px;

  .container-notification-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $s-zs;
    animation: fade-in 0.8s forwards, slide-in 1s forwards;
  }
  .container-notification-header-title {
    font-weight: 600;
    font-size: $text-size-default;
    line-height: $text-line-height-small;
  }

  .container-notification-content {
    font-size: $text-size-tiny;
    line-height: $text-line-height-small;
    animation: fade-in 0.8s forwards, slide-in 1.5s forwards;
  }

  .closebtn {
    z-index: 1;
    position: absolute;
    top: $s-zs;
    right: $s-md;
    border: none;
    background: none;
    cursor: pointer;
    svg {
      width: $s-sm;
      height: $s-s;
      path {
        stroke: $c-base-white;
      }
    }
  }

  .notificationContent {
    animation: fade-in 0.5s ease-in-out, fade-out 0.5s ease-in-out;
  }

  .auto-close-timer {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: $s-zz;
    background-color: $c-gray-2;
  }

  .auto-close-timer-bar {
    height: 100%;
    background-color: $c-gray-4;
    transition: width 2s linear;
  }

  &[data-type='error'] {
    background-color: $c-state-danger-dark;
    .auto-close-timer-bar {
      background-color: $c-state-danger-dark;
    }
    .auto-close-timer {
      background-color: $c-primary-light;
    }
  }

  &[data-type='success'] {
    background-color: $c-state-success-dark;
    .auto-close-timer-bar {
      background-color: $c-state-success-dark;
    }
    .auto-close-timer {
      background-color: $c-state-success;
    }
  }

  &[data-open='true'] {
    transform: translateX(0);
    animation: slide-in 0.8s forwards;
    opacity: 1;
  }

  &[data-open='false'] {
    transform: translateX(0);
    animation: slide-out 0.8s forwards;
    opacity: 0;
  }

  @keyframes slide-in {
    from {
      transform: translatex(-100%);
      opacity: 0;
    }
    to {
      transform: translatex(0);
      opacity: 1;
    }
  }

  @keyframes slide-out {
    from {
      transform: translatex(0);
      opacity: 1;
    }
    to {
      height: 0;
      transform: translatex(-100%);
      opacity: 0;
    }
  }
}
