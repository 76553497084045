@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__dream-car-form-container {
  position: relative;

  .form-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-phone {
      gap: $s-lg;
    }
  }

  .space {
    margin-bottom: $s-lg;
  }

  .center-radio > div > div > label {
    @include gt-phone {
      margin-top: $s-s;
      margin-bottom: $s-s;
    }
  }

  /* FIELDSET
  ========================================================================== */
  .form-container_fieldset {
    display: grid;
    border: none;
  }

  .form-container_fieldset_radio {
    > div {
      flex-direction: column;

      @include gt-phone {
        flex-direction: row;
        gap: $s-lg;
      }
    }
  }

  .form-container_fieldset_search-container {
    display: flex;
    align-items: flex-start;
  }

  .form-container_fieldset_search-container_button {
    margin-top: $s-lg;
  }

  .form-container_fieldset_horizontal {
    display: grid;

    @include gt-phone {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $s-lg;
    }

    @include tablet {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  .form-container_fieldset_checkbox {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include gt-phone {
      column-gap: $s-lg;
      flex-direction: row;
    }
  }

  .form-container_fieldset_search {
    margin-bottom: $s-s;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  .disabled-field {
    label {
      color: $c-base-black;
    }
    input {
      background-color: $c-gray-2;
      color: $c-gray-4;
    }
  }

  .button_submit {
    width: 100%;

    &[disabled] {
      border-color: $c-gray-2;
      background-color: $c-gray-2;
      pointer-events: none;
    }
  }
}
