@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__offers-form-container {
  position: relative;

  .offers-form {
    display: flex;
    flex-direction: column;
  }

  .form-title {
    margin-bottom: $s-lg;
  }

  .radio-group-label > label {
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__section {
    display: flex;
    flex-direction: column;
    gap: $s-lg;
  }

  .form-container__checkbox {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    div {
      label {
        margin-bottom: 0;
      }
    }
  }

  .form-input {
    margin-bottom: $s-lg;
    > input,
    > div > select {
      margin-bottom: 0;
    }
  }

  .form-input-agreement {
    > label {

      > span {
        bottom: 0;
      }
  }
  > span{
    bottom: 1.5rem;
  }
}
}

.no-offer-container {
  margin-bottom: $s-md;
}
