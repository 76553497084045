@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__direct-sales-form-container {
  position: relative;

  .form-container__title {
    font-weight: 500;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    color: $c-gray-6;

    @include gt-tablet {
      font-size: $text-size-medium;
      line-height: $text-line-height-xmedium;
      color: $c-base-black;
    }
  }

  .form {
    grid-gap: 2.4rem;

    @include gt-tablet {
      grid-gap: 3.2rem;
    }
  }

  .form-container__subtitle {
    font-size: $text-size-default;
    font-weight: 400;
    line-height: $text-line-height-medium;
  }

  .submit_button {
    width: 100%;
  }

  .form-field {
    > input {
      margin-bottom: 0;
    }
    div > select {
      margin-bottom: 0;
      padding: $s-sm;
      line-height: $text-line-height-small;
    }
    > textarea {
      margin-bottom: 0;
      resize: vertical;
    }
  }

  .form-radio {
    > label {
      display: none;
    }
    > div {
      justify-content: left;
    }
   > div > div > label {
      padding-left: $s-md;
      margin-bottom: 0;
      color: $c-gray-4;
      font-size: $text-size-default;
      font-weight: 400;
      line-height: $text-line-height-medium;
    }
  }

  .form-container__section {
    display: grid;
    width: auto;
    gap: $s-md;
  }

  .form-container__body {
    display: grid;
    width: auto;
    gap: $s-md;

    @include gt-tablet {
      gap: $s-lg;
    }
  }

  .form-submit-container {

    @include gt-tablet {
      margin-top: $s-lg;
    }
  }

  .form-container__input {
    > input {
      margin-bottom: 0;
    }

    > textarea {
      margin-bottom: 0;
    }

    > div > select {
      margin-bottom: 0;
    }
  }

    .form-field__agreement {
  
      > label {
        margin-bottom: 0;
        font-size: $text-size-default;
        font-weight: 400;
        line-height: $text-line-height-medium;
      }        
      
      > span {
          bottom: -1.8rem;
        }
    }
}
