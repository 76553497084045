@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$icon-size: 1.6rem;

/* MANUALS EXPLORER
========================================================================== */
.manuals-explorer {
  position: relative;
  padding-bottom: $s-lg;
  background-color: $c-gray-1;

  .section-header {
    padding: 0;
    padding-bottom: $s-zs;
  }

  .filters-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: $s-lg;

    .title {
      margin-bottom: $s-lg;
    }

    .fields-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0;

      @include gt-phone {
        flex-direction: row;
        gap: $s-lg;
      }
    }

    .fields {
      width: 100%;
    }

    .button-container {
      width: 100%;
      @include gt-phone {
        width: 30%;
      }
    }

    .button {
      width: 100%;
      @include gt-phone {
      }
    }

    .icon-search {
      width: $icon-size;
      height: $icon-size;
    }
  }

  .cards-list {
    margin-top: $s-xxl;
    margin-bottom: $s-xxl;
  }

  /******************************************
   *      RESPONSIVITY (media queries)      *
   ******************************************/
  @include gt-phone {
  }
}
