@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$input-size: 32.2rem;

.organism__search-container {
  margin-block-end: $s-lg;

  .search-form {
    display: flex;
    flex-direction: row;

    @include phone {
      width: 100%;
    }
  }

  .search-form__input {
    width: $input-size;
    > input {
      margin-bottom: 0;
    }

    @include phone {
      width: 100%;
    }
  }

  /* LOADER CONTAINER
    ========================================================================== */
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: $s-xxl $s-xxxl;
  }

  .close-icon,
  .search-form__input {
    position: relative;
  }

  .close-icon {
    display: inline-block;
    border: 0px solid transparent;
    outline: 0;
    background-color: transparent;
    vertical-align: middle;
  }
  .close-icon:after {
    display: block;
    z-index: 1;
    position: absolute;
    top: 15px;
    right: 60px;
    width: 15px;
    height: 15px;
    border: none;
    content: url("data:image/svg+xml;utf8,<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.666992 11.3332L11.3337 0.666565M11.3337 11.3332L0.666992 0.666565' stroke='black'/></svg>");
  }

  .close-icon-empty {
    display: none;
  }

  .form {
    display: inline-flex;

    @include phone {
      width: 100%;
    }
  }
}
