@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$height: 36rem;
$max-width: 54.7rem;

.molecule__recall-form-custom-container {
  position: relative;

  .container-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $height;
  }

  .form-container {
    display: grid;
    gap: $s-xl;
  }

  .form-container_recall {
    display: grid;

    @include gt-phone {
      gap: $s-s;
    }

    @include tablet {
      gap: 0;
    }
  }

  .form-container_recall_horizontal {
    display: grid;

    @include gt-phone {
      display: flex;
      width: 100%;
      gap: $s-lg;
    }

    @include tablet {
      display: grid;
      gap: 0;
    }
  }

  .form-container_recall_horizontal_field,
  .form-container_recall_horizontal_button {
    width: 100%;

    @include gt-phone {
      max-width: $max-width;
    }

    @include tablet {
      max-width: 100%;
    }
  }

  .form-container_recall_horizontal_field_button {
    margin-bottom: 0;
    input {
      margin-bottom: $s-zs;
    }
  }

  .form-container_recall_horizontal_button {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .modal-activate-button {
    border: none;
    color: $c-base-black;
    font-weight: 400;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
    text-align: start;
    text-decoration: underline;
  }

  .button_submit {
    @include gt-phone {
      width: fit-content;
    }

    @include tablet {
      width: 100%;
    }
  }
}

/* TASA CHASSI MODAL
========================================================================== */
.tasa-chassi-modal {
  display: flex;

  align-items: center;
  justify-content: center;
  width: 100%;
}
