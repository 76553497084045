@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$image-width-limitation: 12.8rem;
$image-height-limitation: 12.4rem;

/* MEDIA BANNER
========================================================================== */
.molecule__media-banner-container {
  position: relative;

  gap: $s-lg;

  /* CONTAINER CONTENT
  ========================================================================== */
  .container-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $s-s;

    @include gt-phone {
      grid-column: 1/5;
    }
  }

  .container-content__content {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  /* CONTAINER MEDIA
  ========================================================================== */
  .container-media {
    @include gt-phone {
      grid-column: 6/-1;
    }
  }
}
