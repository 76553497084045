@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$border-width: 2px;
$border-width--small: 1px;
$image-container-detail-width: 6.4rem;
$min-container-width: 27rem;

/* CAR LIST MODELS
========================================================================== */
.molecule__car-list-models-container {
  padding: $s-lg $s-md;

  background-color: transparent;
  border: $border-width solid $c-base-white;

  @include gt-tablet {
    width: $min-container-width;
  }

  /* IMAGE CONTAINER 
  ========================================================================== */
  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-bottom: $s-md;

    &::before {
      content: '';
      align-self: flex-start;
      
      margin-bottom: $s-s;

      width: $image-container-detail-width;
      height: $border-width;

      background-color: $c-base-white;
    }
  }

  /* LINKS LIST
  ========================================================================== */
  .links-list {
    display: flex;
    flex-direction: column;
    gap: $s-zs;

    padding-top: $s-s;

    border-top: $border-width--small solid $c-base-white;

  }
  
  .links-list__link {
    display: flex;
    justify-content: space-between;
  }
}
