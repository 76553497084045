@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$list-padding: 4rem;
$list-margin: 2rem;

.molecule__direct-sales-faq-container {
  display: flex;
  flex-direction: column;
  gap: $s-md;

  @include gt-tablet {
    gap: $s-lg;
  }
}

.faq-list-header {
  display: flex;
  flex-direction: column;
  gap: $s-zs;
}

.faq-list-description {
  color: $c-gray-6;
}

.logo {
  width: $s-md;
  height: $s-md;
  object-fit: contain;
}

.faq-list-container {
  display: flex;
  flex-direction: column;
  gap: $s-zs;
}

.rich-text-preserve-styles {
  border-top: 1px solid $c-gray-3 !important;
  font-family: inherit !important;
  margin-top: $s-s !important;
  padding-top: $s-s!important;
  all: initial; 
  display: block;

  & > * {
    all: revert;
    display: revert;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold !important;
  }

  ul,
  ol {
    padding-left: 4rem !important;
  }
}

.faq-list__item {
  display: flex;
  flex-direction: column;
  background-color: $c-base-white;
  border: 1px solid $c-gray-3;
  text-align: left;
  padding: $s-md;
}

.faq-list__item-text-container {
  border-top: 1px solid $c-gray-3;
  margin-top: $s-s;
  padding-top: $s-s;
}

.expanded-content {
  align-items: baseline;

  > div > button,
  :hover:focus {
    opacity: 1 !important;
    transition: none !important;
  }
}

.expanded-content-label {
  display: flex;
  flex-direction: row;
  gap: $s-zs;

  p {
    font-weight: 500;
    line-height: $s-md;
  }
}

.rich-text {
  /* LISTA
  ========================================================================== */
  ul {
    display: flex;
    flex-direction: column;

    list-style: disc;

    li {
      position: relative;

      margin-left: $s-s;

      font-size: $text-size-default;
      line-height: $text-line-height-medium;      

      &::marker {
        font-size: 1rem;
      }
      
    }
  }

  ol {
    padding-left: $list-padding;
    
    list-style-type: decimal;

    li {
      margin-left: $s-s;
    }
  }
}
  
  

