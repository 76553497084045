@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$social-image__size: 2.4rem;

/* FAQ LIST
========================================================================== */
.faq-list-container {
  display: flex;
  flex-direction: column;
  gap: $s-lg;

  text-align: center;

  @include gt-tablet {
    gap: $s-xxl;
  }

  /* FAQ LIST CONTAINER
  ========================================================================== */
  .faq-list-container {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    padding-top: $s-lg;

    border-top: 1px solid $c-gray-4;

    .faq-list__item {
      display: flex;
      flex-direction: column;

      padding-bottom: $s-lg;

      border-bottom: 1px solid $c-gray-4;

      text-align: left;

      /* FAQ LIST ITEMS CONTAINER
      ========================================================================== */
      .faq-list__items-container {
        display: flex;
        flex-direction: column;
        gap: $s-s;

        padding-top: $s-lg;
      }

      .faq-list__item-container {
        display: flex;
        flex-direction: column;
        gap: $s-md;

        padding: $s-md;

        background: $c-gray-2;

        .faq-list__item-info,
        .faq-list__item-title {
          color: $c-base-black;
          font-size: $text-size-default;
          line-height: $text-line-height-medium;
        }

        .faq-list__item-title {
          font-weight: 600;

          @include gt-phone {
            font-weight: 500;
            font-size: $text-size-small;
          }
        }

        .faq-list__item-info {
          display: flex;
          align-items: center;

          gap: $s-zs;

          font-weight: 400;
        }

        .faq-list__item-more {
          margin-top: $s-s;

          font-weight: 400;
          color: $c-base-black;
          text-decoration: underline;
          font-size: $text-size-xtiny;
          line-height: $text-line-height-default;
        }

        .tiny-text {
          font-size: $text-size-xtiny;
          line-height: $text-line-height-default;
        }

        .icon-container {
          width: $s-s;
          height: $s-s;

          display: flex;
        }

        .faq-list__item-infos,
        .faq-list__item-medias {
          display: flex;
          gap: $s-zs;
        }

        .faq-list__item-medias {
          img {
            width: $social-image__size;
            height: $social-image__size;
          }
        }

        .faq-list__item-infos {
          flex-direction: column;
        }

        .faq-list__item-button {
          align-self: flex-start;
        }
      }

      /* FAQ LIST ITEM TEXT CONTAINER
      ========================================================================== */
      .faq-list__item-text-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: $s-s;

        padding-block: $s-zs;
        padding-inline: $s-lg;

        .faq-list__item-text {
          font-size: $text-size-default;
          line-height: $text-line-height-medium;
        }
      }
    }
  }

  /* FAQ LIST CONTAINER TYPE 2
  ========================================================================== */
  &[data-type='2'] {
    border: none;

    .faq-list-container {
      gap: 0;

      border: none;

      .faq-list__item {
        border: none;
      }
    }

    path {
      stroke: $c-base-darkest;
    }
  }
}