@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__news-header-container {
  display: grid;
  gap: $s-lg;

  .header {
    display: grid;
    gap: $s-s;
  }

  .header-1 {
    display: flex;
    justify-content: space-between;

    @include phone {
      display: grid;
      gap: $s-s;
    }
  }

  .header-2 {
    padding: $s-zs 0;
  }

  .card__category {
    display: inline-block;
    padding: 0.8rem;
    background-color: $c-gray-3;

    p {
      display: inline;
      color: black;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
      vertical-align: middle;
    }
  }
    p {
      display: inline;
      color: $c-gray-6;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }
}
