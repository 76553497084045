@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';
@import 'utils/styles/_forms';

/* VARIABLES
========================================================================== */
$field-padding: 1.2rem;
$flag-width: 2.1rem;
$flag-height: 1.2rem;
$dropdown-offset: -1.2rem;
$dropdown-offset-left: 1rem;

.atom__phone-input-container {
  display: grid;
  position: relative;

  .code-selection-box {
    position: relative;
  }

  .field-input-phone {
    height: $s-xl;
    margin-bottom: 0;
    padding: $field-padding;
    background-color: $c-base-white;
    cursor: text;
  }

  .selected-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    color: $c-gray-6;
  }

  .selected-option-flag,
  .dropdown-options-flag {
    width: $flag-width;
    height: $flag-height;
  }

  .selected-option-button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    height: $s-xl;
    padding: $s-tiny;
    gap: $s-zs;
    border: $border-width-small solid $c-gray-3;

    text-align: center;
    cursor: pointer;
  }

  .code-selection-box.active .dropdown-options {
    display: block;
  }

  .code-selection-box .dropdown-options {
    display: none;
    z-index: 999;
    position: absolute;
    top: 5rem;
    width: 100%;
    border: $border-width-small solid $c-gray-3;
    border-top: none;
    background-color: $c-base-white;
    transition: display 0.3s ease;
  }

  .code-selection-box .dropdown-options.active {
    display: block;
  }

  .code-selection-box .dropdown-options::before {
    position: absolute;
    top: $dropdown-offset;
    left: $dropdown-offset-left;
    width: $s-zero;
    height: $s-zero;
    content: '';
  }

  .dropdown-options-item {
    height: $s-xl;
  }

  .dropdown-options-item-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $s-tiny;
    gap: $s-zs;
    border: none;
    color: $c-gray-6;
    font-size: $text-size-tiny;
    line-height: $text-line-height-small;
    cursor: pointer;
  }

  .code-selection-box ul li:not(:last-child) {
    border-bottom: $border-width-small solid $c-gray-3;
  }
}
