@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$links-container-desktop-padding-left: 7.8rem;
$extra-info-max-width: 16.4rem;

/* CAR PAYMENT BANNER CONTAINER
========================================================================== */
.car-payment-banner-container {
  display: flex;
  flex-direction: column;

  padding: $s-md;
  gap: $s-xl;

  background-color: $c-base-lightest;

  @include gt-tablet {
    grid-column: 1/10;
    justify-content: space-between;
  }

  /* TOP CONTAINER
  ========================================================================== */
  .top-container {
    gap: $s-md;

    @include gt-phone {
      align-items: flex-start;
      gap: $s-lg;
    }

    /* INFO CONTAINER
    ========================================================================== */
    .info-container {
      gap: $s-md;

      @include gt-phone {
        align-items: flex-start;
        justify-content: space-between;
        gap: $s-xxl;

        &[data-has-icon='false'] {
          flex-direction: column;
          align-items: flex-end;
          gap: $s-zs;
        }
      }

      /* PAYMENT CONTAINER ITEM
      ========================================================================== */
      .payment-container__item {
        @include gt-phone {
          align-items: center;
        }

        &:first-child::after,
        &:nth-child(2)::after {
          top: 0;

          margin: $s-zs 0;
          content: '+';

          font-weight: 600;
          font-size: $text-size-small;
          line-height: $text-line-height-medium;

          @include gt-phone {
            margin: 0 $s-lg;
          }
        }

        .item {
          display: flex;
          flex-direction: column;

          .item__label {
            font-size: $text-size-default;
            line-height: $text-line-height-medium;

            @include gt-tablet {
              [data-variant='vertical'] & {
                margin-inline: unset !important;
                text-align: left;
              }
            }
          }

          .item__value {
            font-size: $text-size-small;
            line-height: $text-line-height-medium;

            &[data-bold='true'] {
              font-weight: 600;
            }
          }
        }
      }
    }

    /* EXTRA INFO CONTAINER
    ========================================================================== */
    .extra-info-container {
      max-width: $extra-info-max-width;

      @include gt-phone {
        &[data-has-icon='false'] {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      @include gt-tablet {
        [data-variant='vertical'] & {
          text-align: left;
        }
      }

      .extra-info {
        font-size: $text-size-tiny;
        line-height: $text-line-height-default;
      }

      .extra-info--form {
        @include gt-tablet {
          display: block;

          margin-left: 4rem;
        }
      }
    }
  }

  /* BOTTOM CONTAINER
  ========================================================================== */
  .bottom-container {
    gap: $s-xl;

    @include gt-phone {
      align-items: center;
      justify-content: space-between;

      [data-variant='vertical'] & {
        margin-inline: unset !important;
      }
    }

    /* LINKS CONTAINER
    ========================================================================== */
    .links-container {
      gap: $s-md;

      @include gt-phone {
        &[data-has-icon='true'] {
          padding-left: $links-container-desktop-padding-left;
        }

        [data-variant='vertical'] & {
          margin-inline: unset !important;
        }
      }
    }

    /* BUTTON CONTAINER
    ========================================================================== */
    .button-container {
      display: flex;
      flex-direction: column;
    }
  }

  /* CONTAINER POSITION
  ========================================================================== */
  .container-position {
    display: flex;
    flex-direction: column;

    @include gt-phone {
      flex-direction: row;
    }
  }
}

/* CAR PAYMENT CARD
  ========================================================================== */
.car-payment-banner {
  @include phone {
    margin-bottom: $s-lg;
  }

  @include gt-tablet {
    grid-column: 1/10;
  }
}

/* CAR PAYMENT CTA
  ========================================================================== */
.car-payment-cta-container {
  display: flex;
  flex-direction: column;

  padding: $s-md;
  gap: $s-lg;

  background-color: $c-base-white;
  color: $c-base-black;

  font-size: $text-size-tiny;
  line-height: $text-line-height-default;

  @include gt-tablet {
    grid-column: 10/-1;
  }
}

.car-payment-header {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.car-payment-header__highlight {
  font-size: $text-size-small;
  line-height: $text-line-height-medium;
}

.car-payment-header__extra-info {
  margin-top: $s-lg;
  color: $c-gray-4;
}
