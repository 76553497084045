@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$modal-gap: 2rem;
$no-bottom: 0;

.recall-tasa-modal-container {
  /* CHECKBOX CONTAINER
  ========================================================================== */
  .modal-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 10rem;

    gap: $modal-gap;

    text-align: center;
  }

  /* MODAL FORM CONTAINER
  ========================================================================== */
  .modal-form-container {
    display: flex;
    flex-direction: column;
    gap: $modal-gap;

    @include gt-phone {
      flex-direction: row;
    }
  }

  /* MODAL FORM COLUMN CONTAINER
  ========================================================================== */
  .modal-form-column-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: $modal-gap;
  }

  .remove-margin {
    select {
      height: $s-xl;
      margin-bottom: $s-s;
    }
    input {
      margin-bottom: $s-s;
    }
    span {
      bottom: $no-bottom;
    }
  }
}
