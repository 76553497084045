@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__iframe-section-container {
  position: relative;

  .iframe {
    width: 100%;
    height: 80vh;
    border: none;
  }

  /******************************************
   *      RESPONSIVITY (media queries)      *
   ******************************************/
  @include gt-phone {
    // example: .content { ... }
  }
}
