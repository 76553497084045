@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$document-font-size: 2rem;

$document-icon-file-width: 3.3rem;
$document-icon-file-height: 4.4rem;

$document-icon-download-width: 2.1rem;
$document-icon-download-height: 2.1rem;

$document-height--small: 3.2rem;

/* DOWNLOAD DOCUMENT ITEM
========================================================================== */
.atom__download-document-item-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $s-lg;

  font-size: $document-font-size;
  line-height: $s-md;
  text-align: center;

  background: $c-gray-6;

  /* CONTAINER
  ========================================================================== */
  .title {
    color: $c-base-white;
    flex: 1 0 auto;
  }

  /* CONTAINER
  ========================================================================== */
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $s-lg;

    padding:$s-s;

    outline: 1px solid $c-base-white;
    transition: opacity ease $basic-transition;
    background: $c-gray-6;

    &:hover, &:focus {
      text-decoration: none;
      opacity: 0.6;
      transition: opacity ease $basic-transition;
    }
  }

/* CONTAINER
  ========================================================================== */
  .container-file {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $s-md;

    color: $c-base-white;
  }

  /* CONTAINER CONTENT
  ========================================================================== */
  .container-content {
    display: flex;
    flex-direction: column;
    
    text-align: left;
  }
  
  .container-content__value{
    font-size: $s-s;
  }

  /* ICONS
  ========================================================================== */
  .container-content__file {
    height: $document-icon-file-height;
  }

  .download {
    width: $document-icon-download-width;
    height: $document-icon-download-height;
  }
}
