@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__cars-tab-with-category-container {
  position: relative;

  .list__title {
    margin-bottom: $s-xl;
  }

  .list {
    display: grid;

    gap: $s-xxl;
    text-align: center;

    @include gt-tablet {
      gap: 0;
      grid-template-columns: repeat(3, 1fr);
    }

  }

  .list__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .list__tabcar {
    width: auto;
    max-width: initial;
  }
}
