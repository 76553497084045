@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$dots-lower-gap: 0.4rem;

/* DOTS GAP VARIATION
========================================================================== */
.lower-dots-gap {
  [class^='slick-dots'] {
    gap: $dots-lower-gap;

    @include gt-phone {
      gap: $s-lg;
    }
  }
}

/* ACCESSORY LIST CAROUSEL
========================================================================== */
.molecule__accessory-list-carousel-container {
  display: grid;
  gap: $s-xl;

  /* DOTS UL
  ========================================================================== */
  [class^='slick-dots'] {
    overflow: hidden;
  }

  [class^='slick-slide'] {
    display: flex;
  }

  [class^='slick-track'] {
    display: flex;
  }
  
  [class^='slick-list'] {
    display: flex;
  }

  @include gt-phone {
    margin-left: -$grid-gutter;
  }

  .accessory-list-carousel__item {
    @include gt-phone {
      margin: $s-zs 0 $s-zs $grid-gutter;
    }
  }
}
