@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__gallery-download-container {
  $width-container: 270px;
  $margin-bottom-checkbox-select-image: -32px;

  /* TITLE
  ========================================================================== */
  .gallery-title {
    margin-bottom: $s-lg;

    @include gt-phone {
      margin-bottom: $s-xxl;
    }
  }
  /* BUTTONS CONTAINER
  ========================================================================== */
  .buttons-container {
    display: grid;
    margin-bottom: $s-lg;
    gap: $s-lg;

    @include gt-phone {
      display: flex;
      justify-content: space-between;
    }
  }

  /* CHECKBOX SELECT ALL
  ========================================================================== */
  .checkbox-select-all {
    align-items: center;
    padding: $s-tiny $s-s;
    background-color: $c-gray-1;
    font-size: $s-sm;
    line-height: $s-s;
    label {
      margin: 0;
    }

    @include gt-phone {
      align-items: normal;
      width: $width-container;
    }
  }

  /* BUTTON DOWNLOAD ALL
  ========================================================================== */
  .button-download-all {
    @include gt-phone {
      width: $width-container;
    }
  }

  /* GALLERY LIST CONTENT
  ========================================================================== */
  .gallery-list-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* GALLERY LIST
  ========================================================================== */
  .gallery-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $s-lg;
    gap: $s-lg;
    @include gt-phone {
    }
  }

  /* CHECKBOX SELECT IMAGE
  ========================================================================== */
  .checkbox-select-image {
    z-index: $z-index-ground;
    width: 100%;
    margin-bottom: $margin-bottom-checkbox-select-image;
    padding: $s-zs $s-s;

    background-color: rgba($color: #000000, $alpha: 0.6);
    label {
      margin: 0;
      color: $c-base-white;
    }
  }

  /* GALLERY LIST ITEM
  ========================================================================== */
  li {
    width: 100%;

    @include gt-tablet {
      width: auto;
    }
  }

  /* BUTTON DOWNLOAD SINGLE
  ========================================================================== */
  .button-download-single {
    width: 100%;
  }

  /* BUTTON SEE ALL IMAGES
  ========================================================================== */
  .button-see-all-images {
    width: 100%;
    margin: 0 auto;

    @include gt-phone {
      width: inherit;
    }
  }
}
