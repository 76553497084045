@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__fieldset-header-container {
  display: grid;
  position: relative;
  gap: $s-s;

  .legend {
    font-weight: 400;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  .description {
    color: $c-gray-4;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  &[data-variant='1'] {
    .legend {
      font-weight: 500;
      font-size: $text-size-medium;
      line-height: $text-line-height-xmedium;

      @include gt-tablet {
        font-size: $text-size-large;
        line-height: $text-line-height-large;
      }
    }
  }

  &[data-variant='2'], &[data-variant='3'] {
    .description {
      font-weight: 400;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }
  }

  &[data-variant='3'] {
    .legend {
      font-weight: 500;
    }
    
    .description {
      color: $c-base-black;
    }
  }
}
