@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__service-appointment-services-step-container {
  $marker-indentation-spacing: 4rem;

  display: grid;
  position: relative;
  gap: $s-lg;

  /* CONTAINER RECALL - capaignsList
  ========================================================================== */
  .recall {
    display: grid;
    padding: $s-s;
    gap: $s-lg;
    background-color: #f9d067;

    &.disabled {
      border: 2px solid $c-gray-1;
    }
  }

  .recall-body {
    display: grid;
    gap: $s-md;

    @include gt-tablet {
      gap: $s-lg;
    }
  }

  .item-title-container {
    display: flex;
    gap: $s-zs;
  }

  .campaigns {
    display: grid;
    gap: $s-s;
    color: $c-base-black;
  }

  .recall-radio-group {
    gap: $s-s;

    > label {
      margin-bottom: 0;
      font-weight: 400;
      font-size: $text-size-tiny;
      line-height: $text-line-height-medium;
    }

    > div {
      column-gap: $s-lg;

      justify-content: flex-start;

      > div {
        background-color: #f9d067;

        > label {
          margin-bottom: 0;
          padding-left: $s-md;
          > span {
            background-color: white;
          }
        }
      }
    }
  }

  .recall-radio {
    display: grid;
    gap: $s-zs;
  }

  .message-option-no {
    display: flex;
    width: 100%;
    gap: $s-zs;
    color: black;
    font-weight: 500;
    font-size: $text-size-tiny;
    line-height: $text-line-height-medium;

    svg {
      min-width: 16px;
      min-height: $text-line-height-medium;
    }

    @include gt-phone {
      align-items: center;
    }
  }

  .legal-text-recall-container {
    display: grid;
    gap: $s-s;
  }

  .legal-text-recall {
    color: $c-base-black;
    font-weight: 400;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .legal-text-message-revision {
    display: grid;
    gap: $s-zs;
  }

  .message-with-recall,
  .message-with-mantenimiento {
    color: $c-primary;
  }

  /* CONTAINER MAINTENANCE - listKm
  ========================================================================== */
  .maintenance {
    display: grid;
    padding: $s-s;
    gap: $s-lg;
    border: 2px solid $c-gray-1;

    .maintenance-title {
      display: grid;
      gap: $s-zs;
    }

    .description {
      font-weight: 400;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }

    .select {
      display: grid;
      gap: $s-zs;

      > div > div > select {
        margin-bottom: 0 !important;
      }
    }

    .eliminate {
      height: auto;
      padding: 0;
      border: none;
      background-color: transparent;
      color: $c-base-black;
      font-weight: 400;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
      text-decoration: underline;
    }
  }

  .maintenance-content {
    display: grid;
    gap: $s-s;
  }

  .others {
    display: grid;
    padding: $s-s;
    gap: $s-zs;
    border: 2px solid $c-gray-1;

    > div {
      gap: $s-lg;

      > label,
      > textarea {
        margin-bottom: 0;
      }
    }
  }

  .buttons {
    display: grid;
    gap: $s-s;
    text-align: center;

    @include gt-tablet {
      display: grid;
      width: fit-content;
    }

    .cancel {
      height: auto;
      padding: 0;
      border: none;
      background-color: transparent;
      color: $c-base-black;
      font-weight: 400;
      font-size: $text-size-xtiny;
      line-height: $text-line-height-default;
      text-decoration: underline;

      @include gt-tablet {
        justify-content: flex-start;
      }
    }
  }

  .services {
    display: grid;
    gap: $s-lg !important;
  }

  .included-services,
  .additional-services-container,
  .wash-container {
    display: grid;
    gap: $s-s;
  }

  .wash-container,
  .additional-services-container {
    padding: $s-s;
    border: 2px solid $c-gray-1;
  }

  .faq {
    > ul > li > div > div {
      > button {
        > span {
          font-size: $text-size-small;
        }
      }
      ul {
        list-style: disc;

        li {
          margin-left: $s-md;
          padding-left: $s-s;

          &::marker {
            color: $c-primary;
            font-size: 1rem;
          }
        }
      }

      ol {
        list-style-type: decimal;

        li {
          margin-left: $s-md;
          padding-left: $s-s;
        }
      }
    }
  }

  .message_services {
    color: $c-gray-6;
    font-weight: 400;
    font-size: $text-size-xtiny;
    line-height: $text-line-height-small;

    &.disabled {
      color: $c-gray-4;
    }
  }

  .checkbox {
    > label {
      margin-bottom: 0;
      padding-left: $s-md;
    }
  }

  .disabled {
    background-color: $c-base-white;
    color: $c-gray-4;
  }

  .others-required-message {
    display: flex;
    flex-direction: column;
    gap: $s-s !important;
  }

  .required {
    color: $c-primary;
  }

  .required-message-error {
    color: $c-primary;
    margin-top: $s-zs;
    font-size: 14px;
    line-height: 16px;
  }

  .others-required {
   > textarea {
      border: 1px solid $c-primary;
    }

  }
}
