@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__simplified-contact-form-container {
  position: relative;

  .subtitle {
    font-size: $text-size-small;
    font-weight: 400;
    line-height: $text-line-height-medium;
    margin-top: $s-xxl;
    margin-bottom: $s-lg;
  }

  .subtitle-contact-form {
    color: #585958;
  }

  .header-content {
    margin-bottom: 3.2rem;
  }

  .container-two {
    @include gt-phone {
      grid-column: 7/13;
    }
  }

  .submit_button {
    width: 100%;
  }

  .form-field {
    > input {
      margin-bottom: 0;
    }
    div > select {
      margin-bottom: 0;
      padding: $s-sm;
      line-height: $text-line-height-small;
    }
    > textarea {
      margin-bottom: 0;
      resize: vertical;
    }
  }

    .agreement {
  
      > label {
        margin-bottom: 0;
      }        
      
      > span {
          bottom: -1.8rem;
        }
    }

    .agreement-tasa {
        color: #A5A5A5;
        margin: $s-lg 0;
    }

    .header {
      > div {
        margin-bottom: $s-s;  
      }
    }
    
    .header_SAC {
      > div {
        > img {
          margin-bottom: $s-s;  
        }
      }
      margin-bottom: 0;
    }
    .divider {
      margin-top: $s-lg;
    }

    .agreement_SAC {
      margin-bottom: $s-lg;
    }
}
