@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__news-banner-container {
  position: relative;

  .section-container {
    display: flex;
    flex-direction: column;
    gap: $s-lg;
  }

  .card-highlight {
    h3 {
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      font-weight: 500;
      font-size: $s-lg;
      line-height: $text-line-height-large;
      text-overflow: ellipsis;
    }

    [class*='card__content'] {
      p {
        color: $c-gray-6;
        font-size: $s-s;
        line-height: $s-md;
      }
    }

    [class*='card__category'] {
      p {
        color: $c-base-black;
      }
    }
  }
}
