@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';
@import 'utils/styles/_forms';

/* VARIABLES
========================================================================== */
$form-gap: 8.4rem;
$width-min-max: 37rem;
$max-width: 30rem;

/* ARMORED-FORM
========================================================================== */

.molecule__armored-form-container {
  position: relative;

  margin: auto;

  max-width: 100%;

  @include phone {
    padding: 0 $grid-padding;
  }

  @include gt-phone {
    max-width: $grid-width-desktop;
  }

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__section {
    display: grid;
    padding: 0;
    gap: $s-lg;

    margin-bottom: $s-xl;

    @include gt-phone {
      margin-bottom: $s-xxl;
    }
  }

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__section__button {
    display: grid;
    gap: $s-lg;
  }

  .form-container__list-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $s-lg;
    grid-column: 1/-1;
    width: 100%;

    @include gt-phone {
      flex-direction: row;
    }
  }
  /* TITLE
  ========================================================================== */
  .form-container__title {
    font-size: $text-size-default;
    font-weight: 600;

    @include gt-phone {
      grid-column: 1/-1;
      font-size: $text-size-small;
      font-weight: 400;
    }
  }

  /* INPUT
  ========================================================================== */
  .form-container__input {
    select {
      margin-bottom: 0;
    }
    input {
      margin-bottom: 0;
    }
    span {
      bottom: -2rem;
    }

    @include gt-phone {
      min-width: $width-min-max;
      max-width: $width-min-max;
    }
  }

  .form-container__checkbox {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: $s-s;

    div {
      label {
        margin-bottom: 0;
      }
    }

    @include gt-phone {
      display: flex;
      flex-direction: row;
      gap: $form-gap;
    }

    @include tablet {
      display: flex;
      flex-direction: column;
      gap: $s-s;
    }
  }

  .form-container__checkbox-entry {
    display: flex;
    flex-direction: column;
    gap: $s-zs;
  }

  .form-container__container_content {
    display: flex;
    flex-direction: row;
    gap: $s-zs;

    max-width: $max-width;

    img {
      width: $s-s;
      height: $s-s;
    }
  }

  .form-container__content_entry_car {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $c-gray-5;
    font-size: $text-size-xtiny;
  }

  .form-container__button_entry_car {
    border: none;

    img {
      width: $s-s;
      height: $s-s;
    }
  }
}

.molecule__modal-container {
  top: 12rem;
  width: 100%;
  .container_modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
    padding: 0;

    width: 100%;

    @include gt-phone {
      flex-direction: row;
      justify-content: space-between;
      gap: $s-lg;
    }
  }

  .form-container__title {
    font-size: $text-size-default;
  }
  .form-container__select {
    display: grid;
    gap: $s-zs;
    width: 100%;

    @include gt-phone {
      gap: $s-s;
    }
  }


  .container_modal_date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $s-s;
    padding: 0;

    @include gt-phone {
      flex-direction: column;
    }
  }

  .container_modal_date__input_date {
    display: flex;
    flex-direction: column;
    gap: 0;
    @include gt-phone {
      flex-direction: row;
      gap: $s-lg;
    }
  }
  .form-container__button_ok {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

}

