@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.icon-pin-map-container {
  position: relative;

  /*DEFAULT SIZES
  ========================================================================== */
  width: 1.0rem;
  height: 1.3rem;

  /*VARIED SIZES
  ========================================================================== */
  &[data-size="medium"] {
    width: 1.4rem;
    height: 1.9rem;
  }

  &[data-size="large"] {
    width: 1.8rem;
    height: 2.5rem;
  }

  &[data-size="extra-large"] {
    width: 2.2rem;
    height: 3.1rem;
  }
}
