@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__service-appointment-attendee-step-container {
  display: grid;
  position: relative;
  gap: $s-lg;

  .form {
    display: grid;
    gap: $s-lg;
    columns: 2;

    > div > input {
      margin-bottom: 0;
    }

    .disabled {
      > input {
        background-color: $c-gray-2;
        color: $c-gray-4;
        pointer-events: none;
      }
    }

    @include gt-phone {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .radio-group {
    gap: $s-zs;

    > label {
      margin-bottom: 0;
      font-weight: 400;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
    }

    > div {
      column-gap: $s-lg;

      justify-content: flex-start;

      > div {
        > label {
          margin-bottom: 0;
          padding-left: $s-md;
          line-height: $text-line-height-medium;
          > span {
            background-color: white;
          }
        }
      }
    }
  }

  .buttons {
    display: grid;

    @include gt-tablet {
      display: grid;
      width: fit-content;
    }
  }

  .modal-container-buttons {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
    }
  }
}
