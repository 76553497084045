@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* REPENTANCE FORM
========================================================================== */
.molecule__repentance-form-container {
  display: flex;
  flex-direction: column;

  @include gt-phone {
    gap: $s-lg;
  }

  .title {
    margin-bottom: $s-md;

    @include gt-phone {
      margin-bottom: $s-lg;
    }
  }

  .disclaimer,
  .description {
    margin-bottom: $s-lg;
  }

  .fields-container {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: column;

    @include gt-phone {
      flex-direction: row;
      gap: $s-lg;
    }
  }

  .field {
    width: 100%;
  }
  .disclaimer {
    &[disabled] {
      color: $c-gray-4;
      pointer-events: none;
    }
  }
  .field_dni {
    width: 100%;

    /* Chrome e outros */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
