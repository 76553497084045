@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';
@import 'utils/styles/_sizes';

.molecule__tab-car-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 31.2rem;

  &[data-variant="car-list"] {
    .action-container {
      margin-top: $s-lg;

      @include gt-tablet {
        margin-top: $s-zs;
      }
    }
  }

  /* CARD IMAGE
  ========================================================================== */
  .car-container {
    width: 31.2rem;
    height: 16.7rem;
  }

  .action-container {

    display: flex;
    flex-direction: column;
    justify-content: center; align-items: center;
  }


  /* CARD CONTENT
  ========================================================================== */
  .car-label {
    text-transform: uppercase;
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
    margin-bottom: $s-s;
  }
  .price-label{
    margin-bottom: $s-s;
  }
  .explore-button {
    margin-bottom: $s-s;
  }
  .mos-link {
    // margin-bottom: $s-s;
  }

  /******************************************
   *      RESPONSIVITY (media queries)      *
   ******************************************/
  @include gt-phone {
    width: 30rem;
    height: 30rem;

  /* CARD IMAGE
  ========================================================================== */
    .car-container {
      width: 30rem;
      height: 16rem;
    }

  /* CARD CONTENT
  ========================================================================== */
    .explore-button, .mos-link {
      opacity: 0;
      transform: translateY(1rem);
      transition: all ease $basic-transition;
    }

    &:hover, &:focus-within {

      .explore-button, .mos-link {
        opacity: 1;
        transform: translateY(0);
      }

      .mos-link {
        transition-delay: $basic-transition;
      }

    }
  }

}
