@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$width-button-confirm: 19.8rem;
$width-image-vehicle: 20.4rem;
$height-image-vehicle: 11.8rem;
$height-image-with-appointment: 8.8rem;
$width-tooltip: 27rem;
$width-button-toggle: 12.5rem;
$width-card-with: 29.8rem;
$gap-custom: 4rem;

.molecule__service-appointment-vehicle-step-container {
  position: relative;

  .vehicle-step-appointments {
    display: grid;
    gap: $s-lg;
  }

  /* CONTAINER APPOINTMENTS
  ========================================================================== */
  .container-appointments {
    display: grid;
    gap: $gap-custom;
  }

  /* VEHICLES WITH APPOINTMENTS
  ========================================================================== */

  .container-with-appointments {
    display: grid;
    padding: $s-md;
    gap: $s-md;
    background: $c-gray-1;
  }

  .with-appointments-content {
    display: grid;
    gap: $s-zs;

    h3 {
      font-weight: 400;
      font-size: $text-size-small;
      line-height: $text-line-height-medium;
    }

    span {
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
    }
  }

  .with-appointments-vehicles {
    display: grid;
    grid-template-columns: 1fr;
    align-items: stretch;
    width: 100%;
    gap: $s-md;
    transition: grid-template-columns 0.3s ease;

    @include gt-phone {
      grid-template-columns: repeat(3, 1fr);
      gap: $s-s;
    }

    @include tablet {
      grid-template-columns: repeat(auto-fit, minmax($width-card-with, 1fr));
    }
  }

  .card-vehicle-with-appointment {
    display: flex;
    width: 100%;
    height: 100%;
    padding: $s-s;
    gap: $s-s;
    border: $border-width-small solid $c-gray-3;
    background: $c-base-white;
    opacity: 80%;

    &:hover,
    &:focus,
    &.selected {
      border: $border-width-small solid $c-primary;
    }

    @include gt-phone {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    @include tablet {
      flex-direction: row;
      align-items: left;
      width: 100%;
      text-align: left;
    }
  }

  .card-with-appointment-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .card-image-with-appointments {
    width: 50%;
    object-fit: contain;

    @include gt-phone {
      width: 100%;
      height: $height-image-with-appointment;
    }
    @include tablet {
      width: 50%;
    }
  }

  .info-vehicle-container {
    display: grid;
    justify-content: center;
    gap: $s-s;
  }

  .info-vehicle-content {
    display: grid;
    gap: $s-zzz;

    @include gt-phone {
      gap: $s-zs;
    }
  }

  .card-title-with-appointments {
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;

    @include gt-phone {
      font-size: $text-size-default;
    }
  }

  .info-vehicle-chassi {
    color: $c-gray-6;
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
  }

  .button_make_request {
    display: flex;
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;

    @include gt-phone {
      justify-content: center;
    }

    @include tablet {
      justify-content: center;
    }
  }

  .container-toggle-vehicles {
    display: flex;
    justify-content: center;
  }

  .toggle-button {
    width: 100%;

    &:focus {
      border-color: $c-base-black;
      background-color: transparent;
      color: $c-base-black;
    }
    @include gt-phone {
      max-width: $width-button-toggle;
    }

    @include tablet {
      max-width: 100%;
    }
  }

  /* VEHICLES WITHOUT APPOINTMENTS
  ========================================================================== */
  .container-without-appointments {
    display: grid;
    gap: $s-md;
  }

  .without-appointments-content {
    display: grid;
    gap: $s-s;

    h3 {
      font-weight: 400;
      font-size: $text-size-medium;
      line-height: $text-line-height-xxmedium;
    }

    span {
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }
  }

  .without-appointments-vehicles {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: $s-md;

    @include gt-phone {
      grid-template-columns: 1fr 1fr;
      gap: $s-lg;
    }

    @include tablet {
      grid-template-columns: 1fr;
    }
  }

  .card-vehicle-without-appointment {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $s-s;
    gap: $s-s;
    border: $border-width-small solid $c-gray-1;
  }

  .selected {
    border: $border-width-small solid $c-primary;
  }

  .card-image-content {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    @include gt-phone {
      flex-direction: row;
      gap: $s-s;
    }
  }

  .card-tooltip {
    [class*='children-container'] {
      max-width: $width-tooltip;
      padding: $s-tiny $s-s;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .image-vehicle {
    width: 100%;
    height: auto;
    object-fit: cover;

    @include gt-phone {
      width: $width-image-vehicle;
      height: $height-image-vehicle;
    }
  }

  .info-vehicle {
    display: flex;
    flex-direction: column;
    gap: $s-zs;
    color: $c-base-black;
  }

  .info-vehicle-title {
    font-weight: 400;
    font-size: $text-size-default;
    line-height: $text-line-height-small;
  }

  .info-vehicle-content {
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;

    .info-vehicle-content_label {
      color: $c-gray-6;
    }
  }

  .selected {
    .info-vehicle-title,
    .card-title-with-appointments {
      color: $c-primary;
      font-weight: 600;
    }
  }

  .card-button {
    width: 100%;
  }

  /* CONTAINER CONFIRM
  ========================================================================== */
  .container_confirm_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $s-s;

    @include gt-phone {
      align-items: flex-start;
    }
  }

  .confirm_button {
    width: 100%;

    @include gt-phone {
      width: $width-button-confirm;
    }
  }
}
