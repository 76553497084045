@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';
@import 'utils/styles/_forms';

/* VARIABLES
========================================================================== */
$select-width: 37rem;

/* INTEREST-FORM-MANAGER
========================================================================== */
.organism__interest-form-manager-container {
  position: relative;

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__section {
    display: grid;
    padding: 0;
    gap: $s-lg;

    margin-bottom: $s-xl;

    @include gt-phone {
      margin-bottom: $s-xxl;
    }
  }

  /* TITLE
  ========================================================================== */
  .form-container__title {
    font-size: $text-size-default;
    font-weight: 600;

    @include gt-phone {
      grid-column: 1/-1;
      font-size: $text-size-small;
      font-weight: 400;
    }
  }

  /* INPUT
  ========================================================================== */
  .form-container__input {
    select {
      margin-bottom: 0;

      line-height: $text-line-height-medium;
      font-size: $text-size-default;
    }

    @include gt-phone {
      width: $select-width;
    }
  }

  @include gt-tablet {
    padding-left: $s-md;
  }
}
