@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__action-search-form-header-container {
  position: relative;
  display: grid;

  /* CONTAINER HEADER
  ========================================================================== */
  .container-header {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  /* CONTAINER FORM
  ========================================================================== */
  .container-form {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  .icon-search {
    width: $s-xl;
    height: $s-xl;

    border: none;

    transition: opacity ease $basic-transition;

    &:hover,
    &:focus {
      transition: opacity ease $basic-transition;
      opacity: 0.8;
    }
  }

  .button-clear-filter {
    display: flex;
    gap: $s-zs;

    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
    color: $c-gray-4;
    text-decoration: underline;

    border: none;

    transition: opacity ease $basic-transition;

    &:hover,
    &:focus {
      opacity: 0.7;
      transition: opacity ease $basic-transition;
    }
  }

  &[data-flex-direction='row'] {
    display: flex;
    flex-direction: column;
    grid-gap: 0rem;

    @include gt-phone {
      display: grid;
      grid-gap: $s-lg;
    }

    @include tablet {
      display: flex;
      flex-direction: column;
      grid-gap: 0rem;
    }

    .container-header {
      display: grid;
      grid-column: 1/3;
    }

    .container-header__title {
      font-weight: 500;
    }
    .container-form {
      display: grid;
      grid-column: 5/-1;
    }
  }

  &[data-flex-direction='column'] {
    .container-header {
      grid-column: 1/5;
    }
    .container-form {
      grid-column: 1/4;
    }
  }
}
