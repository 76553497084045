@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__action-notice-apps-container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: $s-s;
  color: $c-primary;
  font-size: $text-size-xtiny;
  line-height: $text-line-height-small;

  .favorite-card-app {
    display: flex;
    gap: $s-s;
  }
}
