@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';
@import 'utils/styles/_forms';

/* VARIABLES
========================================================================== */
$form-gap: 8.4rem;
$width-input: 37rem;
$max-width: 30rem;

/* INTEREST-FORM
========================================================================== */
.molecule__interest-form-container {
  position: relative;

  max-width: 100%;

  margin: auto;

  @include gt-phone {
    max-width: $grid-width-desktop;
  }

  .result_submit {
    padding: 0 $grid-padding;
  }

  .form-container {
    grid-column: 1/-1;
  }

  .form-container_header {
    margin-block-end: $s-xl;
    @include gt-phone {
      margin-block-end: $s-xxl;
    }
  }

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__section {
    display: grid;

    margin-bottom: $s-xl;
    padding: 0;
    gap: $s-lg;

    @include gt-phone {
      margin-bottom: $s-xxl;
    }
  }

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__section__button {
    display: grid;
    grid-column: 1/-1;
  }

  .form-container__list-input {
    display: flex;
    flex-direction: column;

    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  /* TITLE
  ========================================================================== */
  .form-container__title {
    font-weight: 600;
    font-size: $text-size-default;

    @include gt-phone {
      grid-column: 1/-1;
      font-weight: 400;
      font-size: $text-size-small;
    }
  }

  /* INPUT
  ========================================================================== */
  .form-container__input {
    width: 100%;
    select {
      margin-bottom: 0;
    }
    input {
      margin-bottom: 0;
    }

    @include gt-phone {
      width: $width-input;
    }
  }

  .form-container__checkbox {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: $s-s;

    div {
      label {
        margin-bottom: 0;
      }
    }

    @include gt-phone {
      display: flex;
      flex-direction: row;
      gap: $form-gap;
    }

    @include tablet {
      display: flex;
      flex-direction: column;
      gap: $s-s;
    }
  }

  .form-container__checkbox-entry {
    display: flex;
    flex-direction: column;
    gap: $s-zs;
  }

  .form-container__container_content {
    display: flex;
    flex-direction: row;
    gap: $s-zs;

    img {
      width: $s-s;
      height: $s-s;
    }
  }

  .form-container__content_entry_car {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $c-gray-5;
    font-size: $text-size-xtiny;
  }

  .form-container__button_entry_car {
    border: none;

    img {
      width: $s-s;
      height: $s-s;
    }
  }
}

.molecule__modal-container {
  top: 12rem;
  width: 100%;
  .container_modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    padding: 0;
    gap: 0;

    @include gt-phone {
      flex-direction: row;
      justify-content: space-between;
      gap: $s-lg;
    }
  }

  .form-container__title {
    font-size: $text-size-default;
  }
  .form-container__select {
    display: grid;
    width: 100%;
    gap: $s-zs;

    @include gt-phone {
      gap: $s-s;
    }
  }

  .container_modal_date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    gap: $s-s;

    @include gt-phone {
      flex-direction: column;
    }
  }

  .container_modal_date__input_date {
    display: flex;
    flex-direction: column;
    gap: 0;
    @include gt-phone {
      flex-direction: row;
      gap: $s-lg;
    }
  }
  .form-container__button_ok {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
