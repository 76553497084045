@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$pill-height: 4.8rem;
$pill-height--small: 3.2rem;
$pill-border-radius: 24px;

/* BUTTON
========================================================================== */
.atom__pill-container {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding-inline: $s-s;

  width: fit-content;
  height: $pill-height;

  font-size: $text-size-tiny;
  line-height: $text-line-height-default;

  border: none;
  border-radius: $pill-border-radius;
  background-color: $c-gray-2;

  @at-root #{selector-append(button, &)} {
    opacity: 1;
    transition: background-color ease $basic-transition, color ease $basic-transition, opacity ease $basic-transition;
    will-change: background-color, color, opacity;

    &:hover {
      opacity: .8;
      transition: opacity ease .3s;
    }

  }

  /* SIZES
  ========================================================================== */
  &[data-size='small'] {
    height: $pill-height--small;
  }

  /* STATE
  ========================================================================== */
  &[data-active='true'],
  &[data-is-selected='true'] {
    background-color: $c-base-black;
    color: $c-base-white;
    transition: background-color ease $basic-transition, color ease $basic-transition, opacity ease $basic-transition;
    will-change: background-color, color, opacity;
  }

  &[disabled] {
    background-color: $c-gray-2;
    pointer-events: none;
    border-color: $c-gray-4;
  }

  /* CLOSE ICON
  ========================================================================== */
  .pill-container__icon {
    margin-left: 1.3rem;

    width: 1.1rem;
    height: 1.1rem;

    & > path {
      stroke: $c-base-black;
    }
  }
}
