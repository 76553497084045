@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__media-banner-action-container {
  position: relative;

  @include phone {
    gap: $s-lg;
    padding-block: $s-lg;
  }

  /* CONTAINER CONTENT
  ========================================================================== */
  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    @include gt-phone {
      display: grid;
      grid-column: 1/5;
    }

    @include tablet {
      grid-column: auto;
    }
  }

  .content-wrapper__image {
    display: flex;
    justify-content: center;

    @include gt-phone {
      justify-content: flex-start;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: $s-md;
    align-items: center;

    @include gt-phone {
      align-items: flex-start;
      gap: $s-s;

      text-align: left;
    }
  }

  .desk-only {
    @include phone {
      display: none;
    }
  }

  .content-container__content {
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
    color: $c-base-black;
  }

  /* ACTION BUTTON
  ========================================================================== */
  .action-container {
    display: flex;
    justify-content: center;
    width: 100%;

    @include gt-phone {
      justify-content: flex-start;
    }
  }

  /* ACTION LINK
  ========================================================================== */
  .action-container__link {
    display: flex;
    gap: $s-zs;

    text-decoration: underline;
    transition: opacity ease $basic-transition;

    svg {
      width: $s-s;
      height: $s-s;
    }

    &:hover,
    &:focus {
      opacity: 0.6;
      transition: opacity ease $basic-transition;
    }
  }

  /* IMAGE
  ========================================================================== */
  .media-container {
    @include gt-phone {
      grid-column: 6/-1;
    }
  }
}
