@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__success-submit-form-container {
  position: relative;

  /* CONTAINER SUCCESS
  ========================================================================== */
  .success-container {
    display: flex;
    flex-direction: column;
    gap: $s-xxl;
  }

  .success-container__header {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  .success-container__header__content {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .success-container__buttons {
    display: flex;
    flex-direction: column;
    gap: $s-s;
    

    @include gt-phone {
      flex-direction: row;
      flex-wrap: wrap;
      gap: $s-lg;
    }

    .anchor-link {
      align-self: center;

      @include gt-phone{
        align-self: initial;
      }
    }
  }
}
