@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule-action-banner-search-form-container {
  position: relative;

  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}
