@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';
/* VARIABLES
========================================================================== */
$image-size: 11.7rem;

/* ITEM COMPATIBILITY
========================================================================== */
.molecule__item-compatibility-container {
  display: flex;
  flex-direction: column;
  gap: $s-md;

  @include gt-phone {
    flex-direction: row;
    gap: $s-lg;
  }

  .item-compatibility__item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: $s-s;
  }

  .item-compatibility__item-image {
    max-width: $image-size;
  }

  .item-compatibility__item-text {
    font-weight: 400;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }
}
