@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__simple-banner-container {
  position: relative;
  width: 100%;
  background-color: $c-base-black;

  .banner-art {
    position: relative;
    height: 560px;

    &__picture {
      width: 100%;
      height: 100%;

      img,
      video {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    &__mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    padding-top: 32px;
    padding-bottom: 64px;
    gap: 16px;
    color: $c-base-white;
    text-align: center;

    &__logo {
      width: fit-content;
    }

    &__title {
      margin-bottom: 8px;
    }

    &__title,
    &__description {
      color: inherit;
    }
  }

  .banner-content__description {
    color: $c-base-white;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  @include phone {
    .banner-art {
      height: 300px;

      &__picture {
        img,
        video {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
