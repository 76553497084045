@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__news-abstract-container {
  position: relative;

  .abstract {
    margin: $s-lg 0rem;
    padding: $s-lg;
    background-color: $c-gray-2;
    display: flex;
    flex-direction: column;
    gap: $s-s;

    li {
      display: flex;
      gap: $s-zs;
      color: $c-gray-6;
      font-style: italic;
      font-weight: 400;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }

    span {
      color: $c-primary;
      font-weight: 500;
    }
  }
}
