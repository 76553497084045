@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$height-selected: 5.2rem;
$size-circle: 2rem;
$right-circle: 1rem;

.atom__custom-selector-color-container {
  position: relative;

  .custom-selector-color-selector {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: $height-selected;
    background-color: $c-base-lighter;

    .custom-selector-color-selected {
      display: flex;
      justify-content: space-between;
      padding: $s-s;
      font-weight: 400;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
      cursor: pointer;

      .custom-selector-color-selected-content {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .custom-selector-color-selected-content-color-name {
          display: flex;
          gap: $s-s;
        }

        .custom-selector-color-arrows {
          z-index: 11;
          position: absolute;
          right: $s-zero;
        }
      }
    }
  }

  .custom-selector-color-circle,
  .custom-selector-option-circle {
    display: inline-block;
    position: relative;
    right: $right-circle;
    width: $size-circle;
    height: $size-circle;
    border: $border-width-small solid $c-base;
    border-radius: 50%;
  }

  .custom-selector-option-circle {
    right: $s-md;
  }

  .custom-selector-color-color-options {
    z-index: 10;
    position: absolute;
    width: 100%;
    background-color: $c-base-lighter;
    font-weight: 400;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;

    .custom-selector-color-color-option {
      display: flex;
      justify-content: space-between;

      padding: $s-s;
      border: $border-width-small solid $c-gray-1;
      cursor: pointer;
    }
  }
}
