@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';
@import 'utils/styles/_sizes';

.atom__tab-container {
  position: relative;
  
  margin-bottom: .4rem;
  padding: .4rem;
  
  font-size: $s-s;  
  
  border: none;

  transition: opacity ease $basic-transition;

  &:hover, &:focus {
    text-decoration: none;
    
    opacity: .7;
    transition: opacity ease $basic-transition;
  }

}

.selected {
  border-bottom: .4rem solid $c-primary;
}