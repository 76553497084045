@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$input-width: 37rem;

/* INTEREST-FORM-MANAGER
========================================================================== */
.organism__special-sales-form-container {
  position: relative;

  .container-loader {
    display: flex;
    justify-content: center;
  }

    /* FORM CONTAINER
  ========================================================================== */
  .form-container {
    display: grid;
    gap: $s-lg;

    @include gt-phone {
      gap: $s-xxl;
    }

    @include tablet {
      gap: $s-lg;
    }

  }

  .form-container__header {
    margin: 0;
  }

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__section {
    display: grid;


    padding: 0;
    gap: $s-lg;

    @include gt-phone {

    }
  }

  /* TITLE
  ========================================================================== */
  .form-container__title {
    font-weight: 600;
    font-size: $text-size-default;

    @include gt-phone {
      grid-column: 1/-1;
      font-weight: 400;
      font-size: $text-size-small;
    }
  }

  /* FORM CONTAINER SECTION
  ========================================================================== */
  .form-container__list-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: $s-lg;

    input,
    select {
      margin-bottom: 0;
    }

    @include gt-phone {
      flex-direction: row;
    }
  }

  /* INPUT
  ========================================================================== */
  .form-container__input {
    select {
      margin-bottom: 0;
      line-height: $text-line-height-medium;
    }
    select,
    input {
      height: $s-xl;
    }

    textarea {
      margin-bottom: 0;
    }

    @include gt-phone {
      width: $input-width;
    }
  }

  .form-container__checkbox-agreement {
    label {
      margin : $s-md 0;

      @include gt-phone {
        margin-top: $s-xl;
      }
    }
  }
}
