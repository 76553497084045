@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* ONELOGIN
========================================================================== */
.molecule__onelogin-container {
  /* FORM
  ========================================================================== */
  .onelogin-form {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-phone {
      gap: $s-lg;
    }
  }

  .form-title {
    margin-bottom: $s-s;
  }

  .form-fields {
    display: grid;
    gap: $s-lg;
    border: none;

    @include gt-phone {
      grid-template-columns: 1fr 1fr;
      gap: $s-xl;
    }
  }

  .form-field,
  .disabled-field {
    width: 100%;
  }

  .form-field,
  .form-field-mobile,
  .disabled-field {
    input,
    select {
      margin-bottom: 0;
    }
  }

  .disabled-field {
    label {
      color: $c-base-black;
    }
    input {
      background-color: $c-gray-2;
      color: $c-gray-4;
    }
  }

  .form_container_legal_text {
    position: relative;
    top: $s-xl;
  }

  .form_container_buttons {
    display: flex;
    flex-direction: column;
    margin-top: $s-s;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
      margin-top: 0;
      gap: $s-s;
    }
  }

  .form_button_submit {
    font-weight: 600;
    @include gt-phone {
      width: fit-content;
    }
  }

  .form_button_go_back {
    font-weight: 600;
  }

  .notification {
    top: 80px;
  }
}
