@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$card-text-width: 19.8rem;
$icon-download-size: 1.6rem;
$icon-certificate-size: 1.6rem;


/* RECALL SEARCH DATA
  ========================================================================== */
.molecule__recall-search-data-container {

  .recall-cards-container {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    .card-list {
      display: flex;
      flex-direction: column;
      gap: $s-s;
    }
  }

  .card-no-recall {
    display: flex;
    flex-direction: column;
    gap: $s-zs;
    background-color: $c-gray-1;
    border: 1px solid $c-gray-3;
    padding: $s-s;
    
    @include gt-tablet {
      gap: $s-md;
      padding: $s-md;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      gap: $s-md;

      @include gt-tablet {
        gap: $s-lg;
      }

      .card-button {
        width: 100%;
        @include gt-tablet {
          width: fit-content;
        }
      }
    }

    .contact-button {
      display: flex;
      justify-content: flex-end;
    }

    .card-text-third-variation {
      display: flex;
      flex-direction: column;
      gap: $s-zs;

      h1 {
        font-weight: 500;
        font-size: $text-size-default;
        line-height: $text-line-height-medium;

        @include gt-tablet {
          font-weight: 500;
          font-size: $text-size-xsmall;
          line-height: $text-line-height-xxmedium;
        }
      }
    
      p {
        font-weight: 400;
        color: $c-gray-6;
        font-size: $text-size-tiny;
        line-height: $text-line-height-tiny;

        @include gt-tablet {
        font-size: $text-size-default;
        line-height: $text-line-height-medium;
        }
      }

      a {
        color: $c-gray-6;
      }
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: $s-s;
    background-color: $c-gray-1;
    border: 1px solid $c-gray-3;
    padding: $s-s;
    
    @include gt-tablet {
      padding: $s-md;
    }

    .card-info {
      display: flex;
      flex-direction: column;
      gap: $s-md;

      @include gt-tablet {
        gap: $s-lg;
      }
    }

    .card-text-container {
      display: flex;
      flex-direction: column;
      gap: $s-s;

      @include gt-phone {
        flex-direction: row;
        gap: $s-xl;
      }
    }

    .card-text-first-variation {
      max-width: $card-text-width;
      
      h1 {
          font-size: $text-size-tiny;
          line-height: $text-line-height-default;
          font-weight: 600;
          color: $c-gray-6;
          margin-bottom: $s-zs;
      }

      p {
        font-size: $text-size-default;
        line-height: $text-line-height-medium;
      }
    }

    .card-text {  
      max-width: $card-text-width;
      display: flex;
      flex-direction: column;
      gap: $s-zs;

      h2 {
          font-size: $text-size-tiny;
          line-height: $text-line-height-default;
          color: $c-base-black;
          font-weight: 400;
      }

      p {
        font-size: $text-size-tiny;
        font-weight: 400;
        line-height: $text-line-height-tiny;
        color: $c-gray-6;
      }
    }

    .card-text-third-variation {
      h1 {
        font-weight: 500;
        font-size: $text-size-default;
        line-height: $text-line-height-medium;

        @include gt-tablet {
          font-weight: 500;
          font-size: $text-size-xsmall;
          line-height: $text-line-height-xxmedium;
        }
      }
    
      p {
        font-weight: 600;
        color: $c-gray-6;
        font-size: $text-size-tiny;
        line-height: $text-line-height-default;

        @include gt-phone {
        font-weight: 500;
        font-size: $text-size-default;
        line-height: $text-line-height-medium;
        }
      }
    }

    .markdown-text {
      font-weight: 600;
      color: $c-gray-6;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;

      @include gt-phone {
      font-weight: 500;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
      }

      a {
        color: $c-gray-6;
        text-decoration: underline;
      }
    }

    .container-links {
      display: flex;
      gap: $s-s;
      justify-content: flex-start;
      flex-wrap: wrap;  
      flex-direction: row;

      @include gt-tablet {
      justify-content: flex-end;  
      }
    }

    .card-button {
      @include gt-phone {
        margin-left: auto;
      }
    }

    .card-button-certificate{
      display: flex;
      align-items: center;

      gap: $s-zs;

      border: none;
      text-align: left;

      text-decoration: none;

      cursor: pointer;

      transition: opacity ease $basic-transition;

      color: $c-primary;
      font-weight: 400;
      font-size: $text-size-xtiny;
      line-height: $text-line-height-default;

      @include gt-tablet {
        font-size: $text-size-default;
        line-height: $text-line-height-medium;
      }

      &:hover,
      &:focus {
        opacity: 0.6;
        transition: opacity ease $basic-transition;
      }
    }
    
    .card-link {
      display: flex;
      gap: $s-zs;
      align-items: center;

      &:hover,
      &:focus {
        opacity: 0.6;
        text-decoration: none;
        transition: opacity ease $basic-transition;
      }

      > a {
        color: $c-primary;
        font-weight: 400;
        font-size: $text-size-xtiny;
        line-height: $text-line-height-default;
  
        @include gt-tablet {
          font-size: $text-size-default;
          line-height: $text-line-height-medium;
        }
      } 

      > img {
        width: $icon-download-size;
        height: $icon-download-size;
        font-weight: bold;
      }
    }
  }

}
