@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

$waveWidth: var(--waveWidth);
$waveHeight: var(--waveHeight);

.atom__wave-divider-container {
  --waveWidth: 240px;
  --waveHeight: 64px;
  display: flex;
  z-index: 2;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: calc($waveHeight * -1);
  transform: translateY(-2px);

  /* BACKGROUND
  ========================================================================== */
  &[data-svg-bg-color='white'] {
    color: $c-base-white;
  }

  &[data-svg-bg-color='black'] {
    color: $c-base-black;
  }

  &[data-svg-bg-color='gray'] {
    color: $c-gray-1;
  }

  &[data-svg-bg-color='gray-2'] {
    color: $c-gray-2;
  }

  &[data-svg-bg-color='gray-3'] {
    color: $c-gray-3;
  }

  &[data-svg-bg-color='gray-4'] {
    color: $c-gray-4;
  }

  &[data-svg-bg-color='gray-5'] {
    color: $c-gray-5;
  }

  &[data-svg-bg-color='gray-6'] {
    color: $c-gray-6;
  }

  &[data-svg-bg-color='gray-7'] {
    color: $c-gray-7;
  }

  &[data-svg-bg-color='blue-1'] {
    color: $c-blue-1;
  }

  &[data-svg-bg-color='blue-2'] {
    color: $c-blue-2;
  }

  /* ORIENTATION
  ========================================================================== */

  &[data-orientation='left'] {
    transform: rotateY(0deg) translateY(-2px);
  }

  &[data-orientation='right'] {
    transform: rotateY(180deg) translateY(-2px);
  }

  .wave {
    fill: currentColor;
    margin-left: $waveWidth;

    &--small {
      fill: currentColor;
      display: none;
    }
  }

  .fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: $waveHeight;
    background-color: currentColor;
  }

  @include phone {
    --waveWidth: 75px;
    --waveHeight: 32px;

    .wave {
      display: none;

      &--small {
        display: block;
        margin-left: calc($waveWidth * 2);
        transform: translateX(-2px);
      }
    }

    .fill {
      width: calc(50% + $waveWidth / 2);
    }
  }
}
