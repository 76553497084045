@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

$gap-size: 32px;

.organism__content-media-section-container {
  position: relative;
  padding-top: $gap-size;
  padding-bottom: $gap-size;

  .content-media-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $gap-size;
  }

  .title {
    text-transform: uppercase;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $gap-size;
    gap: $gap-size;

    &__picture {
      width: auto;
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 580px;
      gap: $gap-size;
    }

    &__item {
      display: flex;
      align-items: center;
      width: 100%;
      gap: $gap-size;

      &-image {
        display: inline-flex;
        width: 70px;
        height: 70px;
      }

      &-description {
        flex: 1;
        line-height: $text-line-height-medium;
      }
    }
  }

  @include phone {
    .title {
      text-align: center;
    }

    .content {
      flex-direction: column-reverse;

      &__item {
        flex-direction: column;
        text-align: center;
      }
    }
  }
}
