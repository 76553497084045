@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$container-max-width: 27rem;

 /* SERVICE DROPDOWN CARD CONTAINER
========================================================================== */
.service-dropdown-card-container {
  position: relative;
  
  display: flex;
  flex-direction: column;
  
  padding: $s-md;
  
  border: 1px solid $c-gray-3;
  
  background-color: $c-base-white;
  
  @include gt-phone {
    max-width: $container-max-width;
  }

  /* HEADER
  ========================================================================== */
  .header-icon {
    margin-bottom: $s-s;
  }

  .header-title {
    margin-bottom: $s-md;

    text-transform: uppercase;
  }

  /* CONTENT
  ========================================================================== */
  .content {
    padding-top: $s-s;

    border-top: 1px solid $c-gray-3;
  }
  
  /* SERVICES LIST
  ========================================================================== */  
  .services-list {
    display: grid;
    grid-template-columns: repeat(2,1fr);

    column-gap: $s-s;

    padding-top: $s-s;
  }

  .services-list__anchor {
    display: flex;
    flex-direction: column;

    gap: $s-zs;

    flex-wrap: wrap;
    
    padding-block: $s-zs;

    text-align: center;

    font-size: $text-size-xtiny;
     
    &:hover, &:focus {
      background-color: $c-gray-1;
      text-decoration: none;
    }
    
  }

  .services-list__icon-image {
    max-width: 40px;
    max-height: 40px;   
  }

}
