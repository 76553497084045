@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.input-container {
  position: relative;

  width: 100%;
  height: 4.8rem;
  
  display: flex;
  align-items: center;

  .input {
    padding: 0 $s-s;

    width: 100%;
    height: 100%;

    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
    color: $c-secondary;
    
    border: 1px solid $c-gray-3;
    
    @include gt-phone {
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }
    
    &::placeholder {
      color: currentColor;
    }

    &:disabled {
      background: $c-gray-2;
      color: $c-gray-4;
      border-color: $c-gray-2;
    }

    &:focus {
      border-color: $c-gray-6;
    }
  }
}
