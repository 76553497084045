@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__item-availability-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: calc($s-s + $s-md);

  @include gt-phone {
    gap: $s-zs;
    justify-content: flex-start;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .item-availability-list-item {
    display: flex;
    flex-shrink: 0;
  }
}
