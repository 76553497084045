@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$button-height: 11.8rem;
$max-width-button-contact: 28rem;
$margin-top-button: 3.6rem;
$max-width-simple-modal: 60rem;

.recall-form-container {
  display: flex;
  position: relative;
  flex-direction: column;

  padding: $s-s;
  gap: $s-lg;

  background-color: $c-base-white;

  @include gt-tablet {
    padding: $s-lg;
    gap: $s-xxl;
  }

  /* FORMS CONTAINER
  ========================================================================== */
  .forms-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: $s-s;

    @include gt-phone {
      flex-direction: row;
    }
  }

  .forms-container-grid {
    gap: $s-s;
  }

  .selected-model,
  .selected-year {
    justify-content: flex-start;
    select {
      margin-bottom: 0;
    }
  }

  .selected-year {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: $s-lg;
  }

  .select-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .select-container-grid {
    grid-row: 3;
    grid-column: 1/-1;

    @include gt-phone {
      display: flex;
      grid-row: 1;
      grid-column: 6/12;
      flex-direction: column;
    }
    @include tablet {
      display: flex;
      grid-row: 3;
      grid-column: 1/-1;
      flex-direction: column;
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: $s-s;
  }

  .input-container-grid {
    display: grid;
    grid-row: 1;
    grid-column: 1/-1;
    gap: $s-s;

    @include gt-phone {
      display: flex;
      grid-row: 1;
      grid-column: 1/5;
      flex-direction: column;
      justify-content: center;
    }

    @include tablet {
      display: flex;
      grid-row: 1;
      grid-column: 1/-1;
      flex-direction: column;
      justify-content: center;
    }

    input {
      margin-bottom: $s-s;
    }
  }

  .button-doc-active {
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
    cursor: pointer;
  }

  .modal-activate-button {
    border: none;

    color: $c-base-black;

    font-weight: 400;
    font-size: $text-size-tiny;

    line-height: $text-line-height-default;
    text-decoration: underline;
  }

  .form-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $s-lg;
    color: $c-gray-6;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .form-text-grid {
    display: flex;
    grid-row: 2;

    grid-column: 1/-1;
    align-items: center;
    justify-content: center;
    color: $c-gray-6;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;

    @include gt-phone {
      grid-row: 1;
      grid-column: 5/6;
      height: $button-height;
    }

    @include tablet {
      grid-row: 2;
      grid-column: 1/-1;
    }
  }

  /* RECALL MESSAGE AND REDIRECT CONTACT FORM
  ========================================================================== */
  .recall-message-container {
    display: flex;
    flex-direction: column;
    padding: $s-md;
    gap: $s-lg;
    border: $border-width-small solid $c-gray-3;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;

    @include gt-phone {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: $s-md;
    }
  }

  .content-message-container {
    width: 100%;
  }

  .button-redirect-contact-form {
    width: 100%;
    @include gt-phone {
      max-width: $max-width-button-contact;
    }
  }

  /* CONTENT CONTAINER
  ========================================================================== */
  .content-container {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    .section-info {
      font-size: $text-size-default;
      line-height: $text-line-height-medium;

      @include gt-phone {
        font-weight: 400;
      }
    }

    .service-recall-list {
      display: flex;
      flex-direction: column;
      gap: $s-zs;
    }

    /* LOADER CONTAINER
    ========================================================================== */
    .loader-container {
      display: flex;

      align-items: center;
      justify-content: center;
    }
  }

  /* BUTTON CONTAINER
  ========================================================================== */
  .form-button-container {
    display: flex;
    flex-direction: column;

    @include gt-phone {
      flex-direction: row;
      width: auto;
      margin-top: $margin-top-button;
    }
  }

  .form-button-container-grid {
    display: grid;
    grid-column: 1/-1;

    width: 100%;

    @include gt-phone {
      display: flex;
      grid-row: 1;

      grid-column: 12/-1;
      align-items: center;
      justify-content: center;

      height: $button-height;
    }

    @include tablet {
      grid-row: 4;
      grid-column: 1/-1;
    }
  }

  /* BUTTON CONTAINER
  ========================================================================== */
  .list-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .remove-margin {
    input {
      height: auto;
      margin-bottom: 0;
    }
  }

  /* SIMPLE MODAL
  ========================================================================== */
  .simple-modal {
    width: 100%;
    padding: $s-md;

    img {
      object-fit: cover;
    }

    svg {
      width: $s-md;
    }
    @include gt-phone {
      max-width: $max-width-simple-modal;
      svg {
        width: $s-lg;
      }
    }
  }
}

/* TASA CHASSI MODAL
========================================================================== */
.tasa-chassi-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include phone {
    display: block;
  }
}
