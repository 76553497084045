@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__build-a-car-form-container {
  display: flex;
  position: relative;
  flex-direction: column;

  .info {
    margin-bottom: $s-s;
    color: $c-gray-4;

    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .container-success {
    div {
      padding-top: 0;
    }
  }

  /* FORM
========================================================================== */

  .form {
    display: flex;
    flex-direction: column;

    .section-title {
      margin-top: $s-s;
      margin-bottom: $s-md;

      font-size: $text-size-small;
      line-height: $text-line-height-medium;
    }

    .input-container,
    .input-last-container {
      span {
        bottom: 3rem;
      }
      input,
      div > select,
      label > span {
        background-color: $c-base-white;
      }

      ::placeholder {
        color: $c-base-black; // Chrome, Firefox, Opera, Safari 10.1+
        opacity: 1; // Firefox
      }

      :-ms-input-placeholder {
        color: $c-base-black; // Internet Explorer 10-11
      }

      ::-ms-input-placeholder {
        color: $c-base-black; // Microsoft Edge
      }
    }

    .form__divisor {
      margin-bottom: $s-md;

      border-top-width: 2px;
    }

    .section-title-needed {
      margin-top: 0;
      margin-bottom: $s-md;

      font-size: $text-size-small;
      line-height: $text-line-height-medium;
    }
  }
}
