@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$accessory-form-input-height: 4rem;

.accessories-list-container {
  position: relative;

  /* HEADER
  ========================================================================== */
  .accessory-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: $s-s;
    gap: $s-s;

    @include gt-phone {
      margin-bottom: $s-md;
      gap: $s-md;
    }
  }

  .message-container {
    margin-block: $s-md;
  }

  .message-container__message {
    font-weight: 400;
  }

  /* MODEL SELECT
  ========================================================================== */
  .model-form-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: $s-lg;
    gap: $s-md;

    @include gt-phone {
      display: grid;
    }
  }

  .accessory-model-form {
    grid-column: 1/7;
  }

  /* ACCESSORY SEARCH FORM
  ========================================================================== */
  .accessory-search-form {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: $s-s;

    @include gt-tablet {
      flex-direction: row;
    }
    @include gt-phone {
      grid-column: 7/-1;
      margin-top: $s-s;
    }
  }

  .accessory-search-form__input {
    flex-grow: 1;
    height: $accessory-form-input-height;

    padding-inline: $s-zs;

    border: 1px solid $c-gray-3;
    background-color: $c-base-white;
  }

  /* FILTERS
  ========================================================================== */
  .filters-wrapper {
    display: flex;
    flex-direction: column;

    margin-bottom: $s-xl;
    gap: $s-lg;
  }

  .filters-container {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: $s-s;
  }

  .filters-container__title {
    color: $c-base-black;
    font-weight: 400;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .filter-list {
    display: flex;

    overflow: scroll;
    gap: $s-s;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  /* ACCESSORY LIST
  ========================================================================== */
  .accessory-container {
    margin-bottom: $s-lg;

    border-top: 1px solid $c-gray-3;
    border-bottom: 1px solid $c-gray-3;

    /* LOADER CONTAINER
    ========================================================================== */
    .loader-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-block: $s-lg;

      @include gt-phone {
        padding-block: $s-xxl;
      }
    }

    .result-pagination {
      align-items: flex-end;
      padding-block: $s-md;

      @include gt-phone {
        padding-block: $s-lg;
      }
    }
  }

  .result-pagination__accessory-card-list {
    margin-bottom: $s-lg;
    @include gt-phone {
      margin-bottom: $s-xxl;
    }
  }

  /* PAGINATION
  ========================================================================== */
  .result-pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include gt-phone {
      flex-direction: column;
      gap: $s-md;
    }

    .result-pagination__product {
      color: $c-gray-5;

      span {
        color: $c-base-black;
      }

      @include gt-phone {
        display: block;
      }
    }

    .order {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $s-zs;
      text-align: center;

      @include gt-phone {
        gap: $s-s;
      }
    }

    .result-pagination__text {
      display: flex;
      min-width: max-content;
      color: $c-base-black;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
      text-align: center;
    }

    .result-pagination__selected {
      display: flex;
      align-items: center;

      margin: none;
      border: none;

      background: transparent;
      background-origin: content-box;

      color: $c-gray-5;

      font-size: $text-size-tiny;
      line-height: $text-line-height-default;

      transition: $basic-transition all ease;

      &::after,
      :hover,
      :focus {
        background: transparent;
        -webkit-transition: $basic-transition all ease;
        -o-transition: $basic-transition all ease;
        transition: $basic-transition all ease;
      }

      @include gt-phone {
        width: 100%;
        min-width: 15em;
      }
    }

    span {
      color: $c-gray-5;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
    }

    @include gt-phone {
      flex-direction: row;
    }
  }

  .pagination {
    justify-content: center;
  }

  .section-pagination-mobile {
    display: flex;
    justify-content: center;

    .result-pagination__product {
      color: $c-gray-5;

      span {
        color: $c-base-black;
      }
    }

    @include gt-phone {
      display: none;
    }
  }
}
