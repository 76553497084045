@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$select-horizontal-padding: 1.2rem;
$select-width: 6.6rem;
$select-height: 4rem;

$header-container-max-width: 67rem;

/* TOYOTA CICLE
========================================================================== */
.molecule__toyota-cicle-container {
  // display: flex;
  // flex-direction: column;
  // gap: $s-xl;

  @include gt-phone {
    // gap: $s-xxl;
  }

  /* INFO
  ========================================================================== */
  .info-container {
    display: flex;
    flex-direction: column;

    margin-bottom: $s-xxl;

    max-width: $header-container-max-width;
  }

  .info-container__title {
    margin-bottom: $s-s;
  }

  .info-container__description {
    margin-bottom: $s-s;

    font-weight: 400;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;

    @include gt-phone {
      margin-bottom: $s-zs;
    }
  }

  /* FORM
  ========================================================================== */
  .form {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    @include gt-phone {
      flex-direction: row;
    }
  }

  .form__box-simulation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $s-md;

    @include gt-phone {
      &:not(:last-child) {
        padding-right: $s-lg;

        border-right: 1px solid $c-gray-3;
      }
    }
  }

  .form__box-simulation__header {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: $s-zs;
  }

  .form__box-simulation__header__title {
    display: flex;
    align-items: center;
    gap: $s-zs;

    font-weight: 400;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
    color: $c-gray-4;
  }

  .form__box-simulation__quantity {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: $s-zs;

    label {
      margin-bottom: 0;

      font-weight: 400;
      font-size: $text-size-micro;
      line-height: $text-line-height-default;
      color: $c-base-black;
    }

    select {
      margin-bottom: 0;
      padding: $s-zs $select-horizontal-padding;

      width: $select-width;
      height: $select-height;

      font-weight: 400;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
      color: $c-base-black;
    }
  }

  .emphasis {
    font-weight: 700;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
    color: $c-primary;
  }

  /* IMAGE
  ========================================================================== */
  .image-container {
    display: none;

    @include gt-phone {
      display: flex;
      justify-content: center;

      margin-top: $s-lg;

    }
  }

  .image {
    width: fit-content;

    img {
      margin: 0 auto;
      object-fit: cover;
    }

  }

  /* TOYOTA CICLE
  ========================================================================== */
  .toyota-cicle-container {

    @include gt-tablet {
      grid-column: 1/10;
    }

  }

  .car-upgrade-container {
    grid-column: 10/-1;
  }


  /* TOYOTA CICLE
  ========================================================================== */
  .section-container {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: $grid-gutter;

    @include gt-tablet{
      grid-template-columns: repeat(12,1fr);
    }
  }

  .section-container__toyota-cicle {

    @include gt-tablet {
      grid-column: 1/10;
    }

  }

  .section-container__car-upgrade {
    @include gt-tablet {
      grid-column: 10/-1;
    }
  }

}

/* INPUTS
========================================================================== */
.toyota-cicle-input-container {
  display: flex;
  flex-direction: column;
  gap: $s-zs;

  .simulation-input-label {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    color: $c-primary;
  }

  .simulation-input-container {
    display: flex;
    align-items: center;

    border-bottom: 1px solid $c-gray-3;

    &:hover, &:focus {
      border-bottom: 1px solid $c-base-darkest;
    }

    .simulation-input-container__input {
      width: 100%;

      padding: $s-zs 0;

      font-size: $text-size-small;
      line-height: $text-line-height-medium;

      background-color: transparent;

      border: none;
      outline: none;
    }

    .simulation-input-container__button {
      width: $s-s;
      height: $s-s;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;

      &:hover,&focus {
        opacity: 0.6;
      }
    }
  }

}
