@import 'utils/styles/_forms';

.atom__form-radio-group-container {
  .ff-radio-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include gt-phone {
      justify-content: flex-start;
    }

    &[data-spacing-gap='small'] {
      column-gap: $s-s;
    }

    &[data-spacing-gap='extraSmall'] {
      column-gap: $s-zs;
    }

    &[data-spacing-gap='extraLarge'] {
      column-gap: $s-xl;
    }

    &[data-spacing-gap='standard'] {
      column-gap: $s-lg;
    }
  }
  .ff-radio-column {
    flex-direction: column;
  }
}
