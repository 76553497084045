@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$border-height: 2px;
$separator-height: 1px;
$form-field-padding: 1.2rem;
$select-field-width: 16.9rem;

/* ACCESSORY FORM
========================================================================== */
.molecule__accessory-form-container {
  gap: $s-xl;

  @include gt-phone {
    gap: $s-lg;
  }

  /* CONTAINER ONE
  ========================================================================== */
  .container-one {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    @include gt-phone {
      grid-column: 1/7;
    }
  }

  .container-one__info-container {
    display: flex;
    flex-direction: column;

    margin-bottom: $s-s;
    gap: $s-md;

    @include gt-phone {
      flex-direction: row;
      gap: $s-lg;
    }
  }

  .container-one__info-container__about {
    font-weight: 400;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  .container-one__info-container__extra {
    color: $c-gray-6;
    font-weight: 400;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  /*LINKS LIST CONTAINER
========================================================================== */
  .links-list-container {
    display: grid;

    padding-top: $s-zs;
    gap: $s-s;
  }

  /* CONTAINER TWO
  ========================================================================== */
  .container-two {
    display: grid;
    gap: $s-xl;

    @include gt-phone {
      grid-column: 7/13;
      gap: $s-lg;
    }
  }

  .container-two__info-container {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    @include gt-phone {
      margin-top: $s-xxl;
    }
  }

  .accessory-prices-container {
    display: grid;
    gap: $s-s;
  }

  .info-final-price,
  .info-without-tax {
    display: grid;
    gap: $s-zzz;
  }

  .info-final-price {
    h4 {
      color: $c-gray-6;
      font-weight: 400;
      font-size: $text-size-small;
      line-height: $text-line-height-medium;
    }
    p {
      font-weight: 500;
      font-size: $text-size-medium;
      line-height: $text-line-height-xmedium;
    }
    @include gt-phone {
      p {
        font-size: $text-size-large;
        line-height: $text-line-height-large;
      }
    }
  }

  .info-without-tax {
    h4 {
      color: $c-gray-6;
      font-weight: 400;
      font-size: $text-size-tiny;
      line-height: $text-line-height-tiny;
    }
    p {
      font-weight: 500;
      font-size: $text-size-default;
      line-height: $text-line-height-small;
    }

    @include gt-phone {
      h4 {
        line-height: $text-line-height-small;
      }
    }
  }

  .container-two__info-container__notice {
    color: $c-gray-6;
    font-weight: 400;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .bold-black-text {
    color: $c-base-black;
    font-weight: 500;
  }

  .container-two__info-container__button {
    @include gt-phone {
      margin-top: $s-s;
    }
  }

  /* SEPARATOR
  ========================================================================== */
  .separator {
    display: flex;
    align-items: center;
    color: $c-gray-4;

    font-weight: 400;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
    text-align: center;

    &::before,
    &::after {
      flex: 1;

      border-bottom: $separator-height solid $c-gray-4;
      content: '';
    }

    &:not(:empty)::before {
      margin-right: $s-s;
    }

    &:not(:empty)::after {
      margin-left: $s-s;
    }

    @include gt-phone {
      margin-top: $s-s;
    }
  }

  /* CONTACT FORM
  ========================================================================== */
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: $s-xl;

    @include gt-phone {
      gap: $s-xxl;
    }
  }

  .contact-form__title {
    margin-bottom: $s-lg;
  }

  /* BOX ONE
  ========================================================================== */
  .contact-form__box-one {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    border: none;

    @include gt-phone {
      gap: $s-md;
    }
  }

  /* BOX TWO
  ========================================================================== */
  .contact-form__box-two {
    display: flex;
    flex-direction: column;
    gap: $s-lg;

    border: none;
  }

  .contact-form__box-two__legend {
    margin-bottom: $s-md;

    font-weight: 400;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;

    @include gt-phone {
      margin-bottom: $s-lg;
    }
  }

  /* HORIZONTAL CONTAINER
  ========================================================================== */
  .contact-form__box-one__horizontal-container {
    display: flex;
    flex-direction: column;
    // gap: $s-md;

    width: 100%;

    @include gt-phone {
      flex-direction: row;
      gap: $s-lg;
    }
  }

  .contact-form__box-two__horizontal-container {
    display: flex;
    flex-direction: column;
    // gap: 3.2rem;
    width: 100%;

    @include gt-phone {
      flex-direction: row;
      gap: $s-lg;
    }
  }

  /* CONTACT FORM FIELD
  ========================================================================== */
  .contact-form__field {
    margin-bottom: $s-lg;

    > label {
      color: $c-base-black;
      font-weight: 400;
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
    }

    > input,
    > div > select {
      margin-bottom: 0;
      padding: $form-field-padding;

      background-color: $c-base-white;
      color: $c-base-black;

      font-weight: 400;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;

      &::placeholder {
        color: $c-base-black;
      }
    }

    > div > select {
      @include gt-phone {
        min-width: $select-field-width;
      }
    }
  }

  .contact-form__field_state {
    width: 100%;
  }

  .contact-form__field_city {
    width: 100%;
  }

  .contact-form__field_dealer {
    grid-column: 1/-1;
    width: 100%;
  }

  /* AGREEMENT
  ========================================================================== */
  .contact-form__agreement {
    padding-top: $s-lg;

    border-top: $border-height solid $c-gray-3;

    > label {
      color: $c-gray-4;

      font-weight: 400;
      font-size: $text-size-default;
      line-height: $text-line-height-medium;

      > span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    }

    > span {
      bottom: 1.5rem;
    }
  }

  .contact-form_title_section_dealership {
    margin-bottom: $s-lg;
    font-weight: 400;
    font-size: $text-size-small;
    line-height: $s-md;
  }

  .contact-form_submit_button {
    width: 100%;
  }
}
