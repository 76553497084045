@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.button-list {
  display: flex;
  flex-direction: column;
  gap: $s-xl;
  align-items: flex-start;

  @include gt-tablet {
    flex-direction: row;
    gap: $s-md;
  }

  [data-list-alignment='center'] & {
    align-items: center;

    @include gt-tablet {
      place-content: center;
    }
  }
}
