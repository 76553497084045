@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__tab-car-carousel-container {
  position: relative;

  .slide-container {
    display: grid !important;
    place-items: center;
    z-index: $z-index-ground;
  }

  [class^="slick-slider"] {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    position: relative;
    z-index: $z-index-jump;
    margin-left: 0.4em;
    margin-right: 0.4rem;
    padding-bottom: calc($s-s + $s-xl);

    @include gt-phone {
      padding-bottom: $s-s;
    }
  }

/* DOTS
========================================================================== */
  [class^="slick-dots"] {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    gap: $s-lg;
    transform: translate(-50%, 0);

    @include gt-tablet {
      // bottom: $s-xxl;
      width: $grid-width-desktop;
    }

    li {
      display: unset;
      margin: 0;
      padding: 0;
      width: unset;
      height: unset;

      &[class^="slick-active"] {

        button {
          width: 4rem;
          background-color: $c-base-black;
          opacity: 1;
        }
      }
    }

    button {
      padding: 0;
      width: .8rem;
      height: .8rem;
      line-height: 0;
      color: transparent;
      background-color: $c-base-black;
      border: none;
      border-radius: 6px;
      opacity: .6;
      transition: width ease $basic-transition;
    }
  }

/* ARROWS
========================================================================== */
  [class^="slick-arrow"] {
    position: absolute;
    border: none;
    color: transparent;
    top: 32%;
    height: 1.5rem;
    width: 1.5rem;
    transform: rotate(45deg);
    z-index: $z-index-jump;
    overflow: hidden;
  }

  [class*="slick-prev"] {
    right: 100%;
    border-left: solid 0.15rem $c-base-black;
    border-bottom: solid 0.15rem $c-base-black;
  }

  [class*="slick-next"] {
    left: 100%;
    border-right: solid 0.15rem $c-base-black;
    border-top: solid 0.15rem $c-base-black;
  }

  [class*="slick-disabled"] {
    cursor: default;
    opacity: .2;
  }

/* BACK LINE
========================================================================== */
  .back-line {
    position: absolute;
    top: 33%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: $s-zs;
    width: calc(100% - $s-xxl * 2);
    background-color: $c-gray-2;
    z-index: 0;
  }

}
