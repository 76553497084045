@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

$height-gallery-button-mobile: 28rem;
$height-gallery-button-desktop: 51.2rem;

.organism__gallery-container {
  $width-image-gallery: 269.5px;
  $height-image-gallery: 200px;

  position: relative;

  /* BUTTON
========================================================================== */
  .gallery-button {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: $height-gallery-button-mobile;

    border: none;
    background: $c-base-black;

    @include gt-phone {
      width: 100%;
      height: $height-gallery-button-desktop;
    }

    /* TEXT
========================================================================== */
    .title-sup,
    .title-inf {
      z-index: 3;
      color: $c-base-white;
    }

    .title-sup {
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }

    .title-inf {
      font-weight: 600;
      font-size: $text-size-large;
      line-height: $text-line-height-large;
    }

    .gallery-button__image {
      position: absolute;
      width: 100%;
      height: 100%;

      object-fit: cover;

      &[opacity='true'] {
        opacity: 0.5;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  /* SIZES
  ========================================================================== */
  &[data-size='small'] {
    .gallery-button {
      @include gt-tablet {
        width: $width-image-gallery;
        height: $height-image-gallery;
      }
    }
  }
}
