@import 'utils/styles/_mixins';

.title {
  margin-bottom: $s-lg;

  text-align: center;
}

.organism__tabs-wrapper-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .tabs-wrapper {

    @include gt-tablet {
      display: flex;
      gap: $s-xxl;

      margin-bottom: $s-lg;
    }

  }

  .hidden {
    display: none;
  }

}

.tabs-wrapper-disclaimer{
  display: flex;
  justify-content: center;
  margin-top: $s-lg;
  font-size: $s-sm;
  line-height: $s-s;
  font-weight: 400;
  color: $c-gray-6;
}
