@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$arrow-small-width: 0.8rem;
$arrow-small-height: 1.2rem;
$arrow-medium-width: 1.1rem;
$arrow-medium-height: 1.8rem;
$arrow-large-width: 1.4rem;
$arrow-large-height: 2.4rem;

/* ARROW
========================================================================== */
.atom__icon-arrow-container {

  /* SIZE
  ========================================================================== */
  width: $arrow-small-width;
  height: $arrow-small-height;

  &[data-size='medium'] {
    width: $arrow-medium-width;
    height: $arrow-medium-height;
  }

  &[data-size='large'] {
    width: $arrow-large-width;
    height: $arrow-large-height;
  }

  /* POSITION
  ========================================================================== */
  &[data-arrow-position='down'] {
    transform: rotate(270deg);
  }

  &[data-arrow-position='up'] {
    transform: rotate(90deg);
  }

  &[data-arrow-position='right'] {
    transform: rotate(180deg);
  }
}
