@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$height-image: 7.2rem;
$width-image: 11.7rem;
$min-height-select: 4.8rem;
$top-select: 1rem;
$top-select-large: 1.8rem;

/* MODEL FORM
========================================================================== */
.molecule__model-form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $s-s;

  @include gt-phone {
    flex-direction: row;
  }

  /* CONTAINER IMAGE
  ========================================================================== */
  .container-image {
    transition: all $basic-transition;

    img {
      width: 100%;

      @include gt-phone {
        height: $height-image;
        width: $width-image;

        object-fit: contain;
      }
    }
  }

  /* FORM SELECT MODEL
  ========================================================================== */
  .form__select-model {
    display: flex;
    flex-wrap: wrap;

    padding: 0;

    text-overflow: clip '';

    @include gt-phone {
      align-items: center;
      max-width: max-content;
    }

    select:first-child {
      padding: 0;

      margin-right: $s-zs;

      max-width: 100%;
      width: 100%;

      font-size: $text-size-medium;
      line-height: $text-line-height-xmedium;
      font-weight: 500;

      @include gt-phone {
        margin-right: $s-s;
        min-height: $min-height-select;
        height: auto;
        font-size: $text-size-large;
        line-height: $text-line-height-large;
      }
    }

    select {
      display: flex;
      justify-content: center;

      margin-bottom: 0;

      background-color: transparent;
      border: none;
    }

    option {
      font-size: $text-size-default;
      font-weight: 400;
      line-height: $text-line-height-medium;
    }
  }

  .form__select-model ::after {
    @include gt-phone {
      top: $top-select-large;
    }
  }

  /* FORM SELECT VERSION
  ========================================================================== */
  .form__select-version {
    select {
      display: flex;
      justify-content: center;

      margin-bottom: 0;

      background-color: transparent;
      border: none;
    }

    select:first-child {
      padding: 0;

      margin-right: $s-zs;

      height: auto;
      max-width: 100%;
      width: 100%;

      color: $c-gray-6;
    }
  }

  .form__select-version ::after {
    top: $top-select;

    @include gt-phone {
      top: 0;
    }
  }
}
