@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* DISCLAIMER
========================================================================== */
.molecule__disclaimer-container {
  display: flex;
  flex-direction: column;
  gap: $s-md;

  @include gt-phone {
    grid-column: 2/12;
    flex-direction: row;
    gap: $s-lg;

    [data-full-width="true"] & {
      grid-column: 1/-1;
    }

  }

  /* IMAGES
  ========================================================================== */
  .images-container {
    display: flex;
    gap: $s-zs;

    @include gt-phone {
      flex-direction: column;
      flex: 1 0 auto;
      gap: $s-s;
    }
  }

  /* TEXT
  ========================================================================== */
  .content-container {
    display: flex;
    flex-direction: column;
    gap: $s-s;

    p {
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
      color: $c-gray-6;
    }
  }
}