@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$tooltip-width: 11.6rem;
$tooltip-height: 3.2rem;

$tooltip-large-padding-vertical: 1.2rem;
$tooltip-large-width: 27rem;

$tooltip-border-radius: 4px;
$tooltip-triangle-size: 4px;

$icon-size: 1.6rem;
$icon-small-size: 0.8rem;
$icon-medium-size: 2.4rem;
$icon-large-size: 3.2rem;

$children-container-img-type-max-width: 30rem;

/* TOOLTIP
========================================================================== */

.atom__tooltip-container {
  position: relative;
  border: none;
  outline: none;
  color: inherit;
  text-align: left;
  cursor: pointer;

  &[data-is-type-img='false'] {
    z-index: 999;

    width: $icon-size;
    height: $icon-size;
  }

  /* ICON SIZE
  ========================================================================== */
  .icon {
    width: $icon-size;
    height: $icon-size;
  }

  &[data-size='small'] {
    &[data-is-type-img='false'] {
      width: $icon-small-size;
      height: $icon-small-size;
    }

    .icon {
      width: $icon-small-size;
      height: $icon-small-size;
    }
  }

  &[data-size='medium'] {
    &[data-is-type-img='false'] {
      width: $icon-medium-size;
      height: $icon-medium-size;
    }

    .icon {
      width: $icon-medium-size;
      height: $icon-medium-size;
    }
  }

  &[data-size='large'] {
    &[data-is-type-img='false'] {
      width: $icon-large-size;
      height: $icon-large-size;
    }

    .icon {
      width: $icon-large-size;
      height: $icon-large-size;
    }
  }

  /* TEXT
  ========================================================================== */
  .children-container {
    display: none;
    position: absolute;

    max-width: $tooltip-width;
    min-height: $tooltip-height;

    padding: $s-zs $s-s;

    border-radius: $tooltip-border-radius;
    background-color: $c-gray-5;

    color: $c-base-white;
    font-weight: 400;
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
    text-align: center;
    transition: all ease $basic-transition;

    &[data-is-type-img='true'] {
      max-width: $children-container-img-type-max-width;
      border: 1px solid $c-gray-3;

      background-color: $c-base-white;
    }

    &[data-tooltip-size='small'] {
      max-width: $tooltip-large-width;
      padding: $tooltip-large-padding-vertical $s-s;

      font-size: $text-size-micro;
    }
  }

  /* TEXT DETAIL
  ========================================================================== */
  .text-detail {
    display: none;
    position: absolute;
    border-width: $tooltip-triangle-size;

    border-style: solid;
    border-color: transparent transparent $c-gray-5 transparent;
  }

  &:hover,
  &:focus {
    &[data-is-type-img='false'] {
      opacity: 0.8;
    }
  }

  &:hover .children-container,
  &:focus .children-container,
  &:hover .text-detail,
  &:focus .text-detail {
    display: block;
  }

  .children-container {
    &[data-visible='true'] {
      display: block;
    }
  }

  /* LABEL
  ========================================================================== */
  .label {
    color: $c-base-black;
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;

    text-decoration: underline;
  }
}
