@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$button-max-size: 37rem;

.molecule__email-dream-car-form-container {
  position: relative;

  .container-loader {
    display: flex;
    justify-content: center;
  }

  .container-form {
    display: flex;
    flex-direction: column;
    gap: $s-lg;
  }
  .container-form__input-submit {
    display: flex;
    flex-direction: column;
    gap: $s-s;
  }

  .container-form__description {
    font-size: $text-size-default;
    line-height: $text-size-medium;
  }

  .container-form__input {
    @include gt-phone {
      max-width: $button-max-size;
    }
  }
  .container-form__button {
    @include gt-phone {
      width: fit-content;
    }
  }
}
