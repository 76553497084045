@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.accesory-card-list {
  position: relative;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: $grid-gutter;
  gap: $s-lg;

  .accesory-card-list__item {
    background: $c-base-white;
  }

  @include gt-tablet {
    grid-template-columns: repeat(4, 1fr);
  }
}
