@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* MODAL CONTAINER
========================================================================== */
.molecule__simple-modal-container {
  display: flex;
  position: relative;
  flex-direction: column;
  height: fit-content;
  padding: $s-lg;
  margin-bottom: $s-xl;
  gap: $s-zs;
  background-color: white;
  opacity: 1;
  top: 20%;

  .modal-content {
    display: flex;
    flex-direction: column;
    gap: $s-lg;
  }

  .modal-close {
    position: relative;
    width: 100%;
    direction: rtl;
  }

  .button-close {
    border: none;
    background: none;
    cursor: pointer;
  }
}

.modal-overlay {
  display: flex;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: $s-md;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
}
