@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__toyota-cicle-cars-container {  
  position: relative;

  /* TABS
  ========================================================================== */  
  .hidden {
    display: none;
  }
  .display {
    display: block;
  }

  /* CAR PAYMENTS
  ========================================================================== */  
  .payment-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;    

    margin-top: $s-xxl;

    @include gt-tablet {
      flex-direction: row;
      gap: $s-lg;
    }
  }

  /* CARDS
  ========================================================================== */
  .cards-list-container {
    margin-top: $s-xl;

    @include gt-tablet {
      grid-template-columns: repeat(4,1fr);

      margin-top: $s-xxl;
    }
  }

}
