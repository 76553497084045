@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__recall-container {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: $s-xl;

  @include gt-phone {
    gap: $s-xxl;
  }
}
