@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
======================================================================== */
$banner-height: 90.7rem;
$banner-height-mobile: 69.7rem;
$container-content-max-width: 77.2rem;
$logo-width-desktop: 34.5rem;
$logo-width-mobile: 20.2rem;

.molecule__banner-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: $banner-height-mobile;

  @include gt-phone {
    height: $banner-height;
  }

  .container-image-banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .container-content {
    display: flex;
    z-index: 2;
    position: absolute;
    bottom: 0;
    flex-direction: column;
    align-items: center;
    max-width: $container-content-max-width;
    margin: $s-xxl $s-md;
    gap: $s-md;
    color: $c-base-white;
    text-align: center;
  }

  .container-content-text {
    font-size: $text-size-tiny;
    line-height: $text-line-height-small;
  }

  .container-content-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $s-s;
  }

  .container-content-header-title {
    font-size: $text-size-medium;
    line-height: $text-line-height-medium;
    font-weight: 500;

    @include gt-phone {
      font-size: $text-size-xlarge;
      line-height: $text-line-height-xlarge;
    }
  }

  .container-content-header-logo {
    width: $logo-width-mobile;
    pointer-events: none;

    @include gt-phone {
      width: $logo-width-desktop;
    }
  }

  .gradient {
    display: block;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &[data-linear-gradient-type='none'] {
    .gradient {
      display: none;
    }
  }

  &[data-linear-gradient-type='linear-dark'] {
    .gradient {
      display: block;
      background: linear-gradient(rgba(0, 0, 0, 0.1) 0%, #000000 75.03%);
    }
  }

  &[data-linear-gradient-type='linear-light'] {
    .gradient {
      display: block;
      background: linear-gradient( rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.5) 75.03%);

    }
  }
}
