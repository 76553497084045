@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

$container-gap: $s-md;
$services-gap: $s-s;

.organism__find-dealers-container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: $container-gap;

  @include gt-tablet {
    gap: $s-lg;
  }

  .browser-location-link-container {
    margin-top: -2rem;
    padding-left: 0.5rem;
    font-size: 1.4rem;
  }

  .browser-location-link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  /* FORM FOR FILTERS
  ========================================================================== */
  .filter-form {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    @include gt-tablet {
      flex-direction: row;
      gap: $s-lg;
    }
  }

  .select-input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
  }

  .container-filter-cep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .filter-cep {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
  }

  .form-input-form {
    input {
      margin-bottom: 0;
    }
  }

  .error-message {
    display: flex;
    position: absolute;
    bottom: -$s-lg;
    flex-wrap: wrap;
    width: 100%;
    color: $c-primary;
    font-size: $text-size-xtiny;

    @include gt-phone {
      bottom: -$s-s;
      width: 100vw;
    }
  }

  .button-search-icon {
    width: $s-xl;
    height: $s-xl;

    border: none;
  }

  .button-link-style {
    display: flex;

    border: none;
    border-bottom: 1px solid $c-base-black;

    opacity: 1;
    transition: opacity ease $basic-transition;

    &:hover,
    &:focus {
      opacity: 0.6;
      transition: border-bottom ease $basic-transition;
    }
  }

  .link {
    align-self: flex-start;
  }

  .hidden {
    display: none;
  }

  /* SERVICES APPLIED AS FILTERS
  ========================================================================== */
  .active-services {
    display: flex;
    flex-direction: column;
    gap: $services-gap;

    .active-services__label {
      margin-top: calc($s-xxl - $container-gap);

      font-size: $text-size-default;
      line-height: $text-line-height-medium;

      @include gt-tablet {
        margin-top: 0;
      }
    }

    .pill-container {
      display: flex;
      flex-direction: row;

      overflow: scroll;
      gap: $s-s;

      &::-webkit-scrollbar {
        display: none;
      }

      @include gt-tablet {
        flex-wrap: wrap;
      }

      & > button {
        flex-shrink: 0;
      }
    }
  }

  .clear-filters-button {
    align-self: flex-start;
    margin: 0;
    margin-top: calc($s-md - $services-gap);
    margin-bottom: calc($s-xxl - $container-gap);
    padding: 0;

    border: none;
    background: none;

    color: $c-gray-4;
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
    text-decoration-line: underline;

    @include gt-tablet {
      margin-top: calc($s-lg - $services-gap);
      margin-bottom: calc($s-lg - $container-gap);
    }

    .clear-filters-button__icon {
      width: 1.1rem;
      height: 1.1rem;
      margin-left: 1rem;

      & > path {
        stroke: $c-base-black;
      }
    }
  }
}
